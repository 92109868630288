#pageArrivals .newArrival {
    margin-top: 2em;
}

#pageArrivals .arrivalFindItem .createdContainer {
    position: absolute;
    right: 1em;
    top: 0;
    font-size: 0.8em;
}
#pageArrivals .arrivalFindItem .createdContainer .value {
    margin-left: 0.5em;
}

#pageArrivals .arrivalFindItem .status {
    position: absolute;
    right: 1em;
    top: 1.3em;
    webkit-box-shadow: 0 0 1px 1px #FFF;
           box-shadow: 0 0 1px 1px #FFF;
}

#pageArrivals .arrivalFindItem .related {
    position: absolute;
    right: 1em;
    top: 1.3em;

    color: #0099CC;
    background: #e3f2fd;
    webkit-box-shadow: 0 0 1px 1px #FFF;
           box-shadow: 0 0 1px 1px #FFF;

    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
}
#pageArrivals .arrivalFindItem .related .title {
    font-size: 0.8em;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
#pageArrivals .arrivalFindItem .related .type {
    font-size: 0.9em;
}
#pageArrivals .arrivalFindItem .related .value {
    font-size: 0.8em;
    font-weight: 700;
    margin-left: 0.2em;
}
#pageArrivals .arrivalFindItem .typeContainer .type {
    font-weight: 700;
    font-size: 1.1em;
}
#pageArrivals .arrivalFindItem .typeContainer .title {
    margin-left: 0.5em;
}
#pageArrivals .arrivalFindItem .typeContainer .value {
    margin-left: 0.2em;
    font-weight: 700;
    font-size: 0.9em;
}
#pageArrivals .arrivalFindItem .detailTable {
    margin-left: 2em;
}
#pageArrivals .arrivalFindItem .detailTable td {
    font-size: 0.8em;
}
#pageArrivals .arrivalFindItem .detailTable td.title {
    padding-right: 2em;
}
#pageArrivals .arrivalFindItem .detailTable td svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

#pageArrivals .fullColumn {
    overflow: visible;
}
