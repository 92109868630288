#pageWorkHours .WeekDay {
	margin: 0 0 0.5em;
}

#pageWorkHours .WeekDay.loading { 
	opacity: 0.5;
	pointer-events: none;
}

/* When sending partial week, these are the days that are NOT selected */
#pageWorkHours .WeekDay.unselected .header {
	background: #f9f9f9;
	opacity: 0.6;
}

#pageWorkHours .WeekDay .header {
	margin: 0;
	background: #fff;
	box-shadow: 0 3px 10px rgba(0,0,0,0.1);

	border-left: 3px solid #ccc;
	-webkit-transition: border 0.4s ease;
	   -moz-transition: border 0.4s ease;
	    -ms-transition: border 0.4s ease;
	     -o-transition: border 0.4s ease;
	        transition: border 0.4s ease;

	padding-left: 40px;

	position: relative;
}

/*
#pageWorkHours .WeekDay.locked .header { 
	background: #f5f5f5;
	box-shadow: 0 3px 10px rgba(0,0,0,0.2);
}
*/

#pageWorkHours .WeekDay.absenceAllDay .header { 
	border-left-color: #FF8800;
}

#pageWorkHours .WeekDay.detailsOpen .header { border-left-color: #33b5e5; }

#pageWorkHours .WeekDay .header .indicator {
	position: absolute;
	top: 50%;
	left: 10px;
	width: 20px;
	height: 20px;
	margin: -10px 0 0;

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;
}

#pageWorkHours .WeekDay.detailsOpen .header .indicator {
	-webkit-transform: rotate(90deg);
	   -moz-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
	     -o-transform: rotate(90deg);
	        transform: rotate(90deg);
}

#pageWorkHours .WeekDay .header > .date, 
#pageWorkHours .WeekDay .header > .status, 
#pageWorkHours .WeekDay .header > .overview,
#pageWorkHours .WeekDay .header > .actions { float: left; padding: 0.5em 0;} 

#pageWorkHours .WeekDay .header > .date {		width: 100px; } 
#pageWorkHours .WeekDay .header > .status{ 	width: 150px; }
#pageWorkHours .WeekDay .header > .overview {	width: calc( 100% - 300px ); }
#pageWorkHours .WeekDay .header > .actions { 	width: 50px; }

#pageWorkHours .WeekDay .header:after {
	content: '';
	display: block;
	clear: both;
}

#pageWorkHours .WeekDay .header > .date span {
	font-size: 16px;
	line-height: 1.5em;
	display: block;
}
#pageWorkHours .WeekDay .header > .date span.date {
	font-size: 80%;
	color: #999;
}

#pageWorkHours .WeekDay .header > .status .apStatusBox { margin-top: 4px; }

#pageWorkHours .WeekDay .header > .overview {
	text-align: center;
	padding-left: 1em;
	padding-right: 1em;
}

#pageWorkHours .WeekDay .header > .overview .block {
	display: inline-block;
	margin: 0;
	width: 25%;
	line-height: 40px;
	text-align: left;
	position: relative;
}
#pageWorkHours .WeekDay .header > .overview .block.none { opacity: 0.3; }

#pageWorkHours .WeekDay .header > .overview .block svg {
	width: 16px;
	height: 16px;
	margin: -3px 5px 0 0;
}

#pageWorkHours .WeekDay .header > .overview .block .highlight {
	position: absolute;
	top: 5px;
	left: -5px;
	width: 10px;
	height: 10px;
	background: #ccc;
	-webkit-border-radius: 99px;
			border-radius: 99px;

}
#pageWorkHours .WeekDay .header > .overview .block .highlight.overtime { background: #FF8800; }
#pageWorkHours .WeekDay .header > .overview .block .highlight.weeklyrest { background: #00bcd4; }
#pageWorkHours .WeekDay .header > .overview .block .highlight.overtime.weeklyrest { 
	background: #00bcd4;
	background: -moz-linear-gradient(left, #00bcd4 0%, #ff8800 100%);
	background: -webkit-linear-gradient(left, #00bcd4 0%,#ff8800 100%);
	background: linear-gradient(to right, #00bcd4 0%,#ff8800 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00bcd4', endColorstr='#ff8800',GradientType=1 ); 
}


#pageWorkHours .WeekDay .header > .overview .block .flexitimeBadge {
	display: inline-block;
	margin: 0 0 0 0.5em;
	background: #eee;
	color: #999;
	border-radius: 999px;
	padding: 0.2em 0.5em;
	font-size: 12px;
	line-height: 1.2em;
}
#pageWorkHours .WeekDay .header > .overview .block .flexitimeBadge.negative { color: #ff4444; background: #ffcdd2; }
#pageWorkHours .WeekDay .header > .overview .block .flexitimeBadge.positive { color: #007E33; background: #c8e6c9; }

#pageWorkHours .WeekDay .details {
	background: #fff;
	margin: 0.2em 0 1em 1em;
	box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

#pageWorkHours .WeekDay .details table {
	width: 100%;
}

#pageWorkHours .WeekDay .details table tr {
	border-bottom: 1px solid #ccc;
}

#pageWorkHours .WeekDay .details table tr:hover {
	background: #f9f9f9;
}

#pageWorkHours .WeekDay .details table td {
	padding: 5px 0;
}
#pageWorkHours .WeekDay .details table td.clip {
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 1.2em;
	padding-right: 0.5em;
}

#pageWorkHours .WeekDay .details table td.icon { width: 40px; text-align: center; position: relative; cursor: pointer; }
#pageWorkHours .WeekDay .details table td.desc { cursor: pointer; }
#pageWorkHours .WeekDay .details table td.project { cursor: pointer; }
#pageWorkHours .WeekDay .details table td.hours { text-align: right; cursor: pointer; }
#pageWorkHours .WeekDay .details table td.actions { width: 50px; text-align: right; }

#pageWorkHours .WeekDay .details table svg {
	width: 16px;
	height: 16px;
}

#pageWorkHours .WeekDay .details table small {
	display: block;
	font-size: 80%;
	color: #999;

	overflow: hidden;
	text-overflow: ellipsis;
}

#pageWorkHours .WeekDay .details table tr.noRows { border: 0; }
#pageWorkHours .WeekDay .details table tr.noRows td { text-align: center; color: #999; background: #f9f9f9; }
#pageWorkHours .WeekDay .details table tr.noRows td svg { margin: -3px 5px 0 0; }


#pageWorkHours .WeekDay .details .highlight {
	display: inline-block;
	background: #f9f9f9;
	padding: 0 5px;
	font-size: 13px;
	line-height: 1.2em;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin: 0 2px 2px 0;
} 

#pageWorkHours .WeekDay .details .highlight.overtime { background: #fff3e0; color: #FF8800; border-color: #FF8800; }
#pageWorkHours .WeekDay .details .highlight.holiday { background: #fff3e0; color: #FF8800; border-color: #FF8800; }
#pageWorkHours .WeekDay .details .highlight.weeklyrest { background: #e0f7fa; color: #00838f; border-color: #00838f; }

#pageWorkHours .WeekDay .details tr.entryRow td.icon .statusBadge {
	position: absolute;
	top: 7px;
	left: 3px;
	color: #fff;
	background: #ccc;
	width: 16px;
	height: 16px;

	box-shadow: 2px 2px 1px rgba(0,0,0,0.5);

	-webkit-border-radius: 999px;
			border-radius: 999px;
}
#pageWorkHours .WeekDay .details tr.entryRow td.icon .statusBadge svg {
	display: block;
	width: 10px;
	height: 10px;
	margin: 3px auto;
}

#pageWorkHours .WeekDay .details tr.entryRow.status-rejected { background: #ffebee; }

#pageWorkHours .WeekDay .details tr.entryRow.status-submitted td.icon .statusBadge { background: #33b5e5; }
#pageWorkHours .WeekDay .details tr.entryRow.status-rejected td.icon .statusBadge { background: #ff4444; }
#pageWorkHours .WeekDay .details tr.entryRow.status-approved td.icon .statusBadge { background: #4caf50; }

#pageWorkHours .WeekDay .details tr.entryRow.misentry td { text-decoration: line-through; }
#pageWorkHours .WeekDay .details tr.entryRow.misentry.status-approved { display: none; }


@media all and (max-width: 900px) {

	#pageWorkHours .WeekDay .header {
		padding-left: 30px;
	}

	#pageWorkHours .WeekDay .header .indicator {
		left: 7px;
		width: 16px;
		height: 16px;
	}

	#pageWorkHours .WeekDay .header > .date span {
		font-size: 14px;
		line-height: 28px;
	}
	#pageWorkHours .WeekDay .header > .date span.date {
		font-size: 12px;
		line-height: 1em;
	}

	#pageWorkHours .WeekDay .header > .status .apStatusBox {
		margin-top: 8px;
	}

	#pageWorkHours .WeekDay .header > .overview .block {
		font-size: 14px;
		width: 50%;
		line-height: 20px;
	}

}

@media all and (max-width: 700px) {

	#pageWorkHours .WeekDay .header > .date {		width: 80px; } 
	#pageWorkHours .WeekDay .header > .status{ 	width: 120px; }
	#pageWorkHours .WeekDay .header > .overview {	width: calc( 100% - 250px ); }
	#pageWorkHours .WeekDay .header > .actions { 	width: 50px; }

}


@media all and (max-width: 600px) {

	#pageWorkHours .WeekDay .header > .overview .block {
		width: 25%;
		line-height: 40px;
		text-align: center;
	}

	#pageWorkHours .WeekDay .header > .overview .block span { display: none; }

}

@media all and (max-width: 500px) {

	#pageWorkHours .WeekDay .header > .date {		width: calc( 100% - 172px); } 
	#pageWorkHours .WeekDay .header > .status { 	width: 120px; padding-right: 10px; }
	#pageWorkHours .WeekDay .header > .overview {	display: none; }
	#pageWorkHours .WeekDay .header > .actions { 	width: 42px; }

	#pageWorkHours .WeekDay .header > .date span {
		font-size: 14px;
		line-height: 16px;
	}

	#pageWorkHours .WeekDay .header > .status .apStatusBox {
		margin-top: 0;
	}
}

@media all and (max-width: 360px) {

	#pageWorkHours .WeekDay .header > .date {		width: calc( 100% - 132px); } 
	#pageWorkHours .WeekDay .header > .status { 	width: 80px; padding-right: 10px; }
	#pageWorkHours .WeekDay .header > .overview {	display: none; }
	#pageWorkHours .WeekDay .header > .actions { 	width: 42px; }

	#pageWorkHours .WeekDay .header > .status .apStatusBox { font-size: 10px; }
	#pageWorkHours .WeekDay .header > .status .apStatusBox span { display: none; }

}


