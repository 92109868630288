#productionTable {
    position: relative;
}

#productionTable .productionTable {
    font-size: 0.8em;
    background-color: #fff;
}


#productionTable .productionTable {
    font-size: 14px;
    line-height: 1.5em;
}

#productionTable .productionTable tr th {
    border-bottom: 1px solid #ccc;
}

#productionTable .productionTable tr td {
    border-bottom: 1px solid #ccc;
}

#productionTable .productionTable tr.selected {
    background: #e1f5fe;
    border-left: 3px solid #03a9f4;
}

#productionTable .productionTable tr.totals td {
    white-space: nowrap;
}

#productionTable .productionTable th {
    font-size: 14px;
    vertical-align: bottom;
}

#productionTable .productionTable td {
    padding: 0.5em 0.2em;
}

#productionTable .productionTable th .apOptionBar .option {
    width: 50%;
}

#productionTable .productionTable td.select { width: 20px; }
#productionTable .productionTable td.icon { width: 20px; }
#productionTable .productionTable td.name { width: auto; }
#productionTable .productionTable td.fixed { width: 200px; min-width: 150px; max-width: 200px; }

#productionTable .productionTable th.fixed,
#productionTable .productionTable td.fixed {
    text-align: center;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}


#productionTable .productionTable input.componentNameInput {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0 30px 0 0;

    border: 0;

    font-size: 14px;
    line-height: 30px;
}

#productionTable .productionTable input.componentNameInput.input-error {
    border-bottom: 3px solid #ff4444;
    color: #ff4444;
}


#productionTable .productionTable td.fixed input.componentRowInput {
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0 5px 0 5px;

    border: 1px solid #ccc;
    border-bottom-width: 3px;

    font-size: 14px;
    line-height: 30px;
}

#productionTable .productionTable td.fixed input.fixed-price-input {
    padding: 0 30px 0 5px;
}

#productionTable .productionTable td.fixed input.componentRowInput.priceError {
    color: #ff4444;
    border-bottom-color: #ff4444;
}

#productionTable .productionTable td.fixed input.componentRowInput.calculatableAvailable {
    border-bottom-color: #2BBBAD;
}

#productionTable .productionTable td.fixed input.componentRowInput:focus {
    outline: 0;
    border-color: #33b5e5;
}

#productionTable .productionTable td.fixed .unit {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 25px;
    line-height: 30px;
    margin-top: -15px;
    text-align: left;
    font-size: 12px;
    color: #aaa;

    overflow: hidden;
    text-overflow: ellipsis;
}

#productionTable .productionTable td.fixed .calculatedCount {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    padding: 0.5em;
    border-top: 3px solid #2BBBAD;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    opacity: 0;
    pointer-events: none;

    -webkit-transform: translateY(-10px);
       -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
         -o-transform: translateY(-10px);
            transform: translateY(-10px);

    -webkit-transition: opacity 0.4s ease, transform 0.4s ease;
       -moz-transition: opacity 0.4s ease, transform 0.4s ease;
        -ms-transition: opacity 0.4s ease, transform 0.4s ease;
         -o-transition: opacity 0.4s ease, transform 0.4s ease;
            transition: opacity 0.4s ease, transform 0.4s ease;
}
#productionTable .productionTable td.fixed input.componentRowInput:focus + .calculatedCount {

    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
        -ms-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);

    opacity: 1;
    pointer-events: auto;
}

#productionTable .productionTable td.fixed .calculatedCount:after {
    bottom: 100%;
    left: 50px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(43, 187, 173, 0);
    border-bottom-color: #2BBBAD;
    border-width: 10px;
    margin-left: -10px;
}

#productionTable .productionTable td.fixed .calculatedCount svg {
    width: 16px;
    height: 16px;
    margin: -3px 5px 0 0;
}

#productionTable .productionTable td.fixed .calculatedCount small {
    color: #999;
}

#productionTable .productionTable td svg {
    width: 20px;
    height: 20px;
}

#productionTable .productionTable td.componentCode {
    font-weight: 700;
}

#productionTable .productionTable td.componentName {
    white-space: nowrap;
}

#productionTable .productionTable td.componentCount {

}

#productionTable .productionTable td.componentPrice {
    padding-left: 1em;
    text-align: right;
}

#productionTable .productionTable td.totalText {
    font-weight: 700;
    text-align: right;
    padding-right: 1em;
}

#productionTable .productionTable td.totalValue {
    font-weight: 700;
    text-align: right;
    padding-left: 1em;
}


/*
 *
 *
 *
 *
 * */

#productionTable thead.table-head {
    background: rgba(0,0,0,0.03);
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

#productionTable thead.table-head th {
    padding: 0 0.5em;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* border-bottom: 1px solid rgba(0,0,0,0.05); */

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

#productionTable td.fixed.c-type {
    width:     30px;
    min-width: 30px;
    max-width: 30px;
}

#productionTable td.fixed.actions {
    width:     46px;
    min-width: 46px;
    max-width: 46px;
    height: 48px;
}

#productionTable td.fixed.c-price {
    width:     100px;
    min-width: 100px;
    max-width: 100px;
}

#productionTable td.fixed.c-balance {
    width:     80px;
    min-width: 80px;
    max-width: 80px;
}

#productionTable td.fixed.c-count {
    width:     90px;
    min-width: 90px;
    max-width: 90px;
}

#productionTable td.fixed.c-unit {
    width:     50px;
    min-width: 50px;
    max-width: 50px;
}

#productionTable td.fixed.c-target {
    width:     160px;
    min-width: 160px;
    max-width: 160px;
}

#productionTable td.fixed.c-entry {
    width:     50px;
    min-width: 50px;
    max-width: 50px;
}

#productionTable td.fixed.c-final-destination {
    width:     50px;
    min-width: 50px;
    max-width: 50px;
}

#productionTable td.fixed.vat {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

#productionTable td.c-name {
    position: relative;
    /* border-right: 1px solid rgba(0,0,0,0.05); */
}

#productionTable td.c-name .name {
    position: absolute;
    top: 2px;
}

#productionTable td.c-name small {
    color: #777;
    position: absolute;
    padding-left: 10px;
    top: 2em;
}


#productionTable .productionTable {
    border: 1px solid rgba(0,0,0,0.1)
}

#productionTable td.fixed .input-menu.input-error,
#productionTable td.fixed input.componentRowInput.input-error {
    color: #ff4444;
    border-bottom-color: #ff4444;
}

#productionTable .noSelect {
    height: 80px;
    text-align: center;
    font-weight: 700;
    color: #777;
}

#productionTable .noWordWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}


#productionTable .balanceTooltip .location {
    padding-right: 10px;
}


#productionTable td.c-balance .location-number {
    position: absolute;
    top: 0;
    font-size: 10px;
    left: 10px;
    white-space: nowrap;
}
#productionTable td.c-balance .reserve {
    position: absolute;
    bottom: 0;
    font-size: 10px;
    right: 10px;
    white-space: nowrap;
}

#productionTable td.fixed.c-price small.price {
    position: absolute;
    bottom: 0;
    font-size: 10px;
    right: 0px;
    white-space: nowrap;
    color: #AAA;
}

#productionTable td.fixed.total {
    max-width: 100px;
}

#productionTable td.fixed.dispatch-ref {
    width: 150px;
}

#productionTable td.fixed.netvisor-vat {
    max-width: 100px;
}

#productionTable .netvisor-vat-input {
    width: 100px;
}

#productionTable td.fixed .input-menu {
    width: 100%;
    margin: 0;
    padding: 0 30px 0 2px;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    line-height: 30px;
}

#productionTable td.fixed .input-menu.disabled {
    background: #eee; color: #999;
    color: #999;
}

#productionTable input:disabled {
    background: #eee; color: #999;
    color: #999;
}

#productionTable td.fixed .input-menu-button {
    position: absolute;
    top: 0;
    height: 100%;
    right: 2px;
}

#productionTable td.fixed .no-select {
    color: #AAA;
}


#productionTable td.fixed .target-container {
    position: relative;
}

#productionTable td.fixed .target-name {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    position: absolute;
    top: 0px;
    line-height: normal;
    width: 100%;
}

#productionTable td.fixed .target-code {
    font-size: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    position: absolute;
    bottom: 0px;
    line-height: normal;
    width: 100%;
    margin-left: 10px;
    white-space: nowrap;
}

#productionTable td.fixed .target-order {
    font-size: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    position: absolute;
    top: 0px;
    line-height: normal;
    width: 100%;
    margin-left: 10px;
}

#productionTable td.fixed .count-helper-top {
    position: absolute;
    top: 0;
    font-size: 8px;
    left: 10px;
    white-space: nowrap;
    line-height: normal;
}

#productionTable td.fixed .count-helper-bottom {
    position: absolute;
    bottom: 2px;
    font-size: 8px;
    left: 10px;
    white-space: nowrap;
    line-height: normal;
}

#productionTable td.fixed .count-container {
    position: relative;
}


#productionTable .topHoverBar {
    background-color: #fff;
}
#productionTable .topHoverBar {
    border-bottom: 3px solid #fff;

    -webkit-transition: border-bottom 0.4s ease;
       -moz-transition: border-bottom 0.4s ease;
        -ms-transition: border-bottom 0.4s ease;
         -o-transition: border-bottom 0.4s ease;
            transition: border-bottom 0.4s ease;
}
#productionTable .ApStickyBar.sticky .topHoverBar {
    border-bottom-color: #33b5e5;


}

#productionTable .hideSelectList {
    cursor: pointer;
    background-color: #f7f7f7;
    color: #888;
    text-align: center;
    font-weight: 700;
    padding: 10px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
}


#productionTable .apSelect .results .item .sComponent {
    line-height: 1.2em;
    position: relative;
}

#productionTable .apSelect .results .item .sComponent .name {
    padding-right: 0.5em;
}

#productionTable .apSelect .results .item .sComponent .value {
    font-weight: 700;
}

#productionTable .apSelect .results .item .sComponent .balanceLocations {
    position: absolute;
    right: 1em;
    top: 0;
    font-size: 12px;
}

#productionTable .apSelect .results .item .sComponent .balance {
    position: absolute;
    right: 1em;
    top: 14px;
    font-size: 14px;
}

#productionTable .apSelect .results .item .sComponent .balanceFree {
    position: absolute;
    right: 1em;
    top: 26px;
    font-size: 10px;
}

#productionTable .apSelect .results .item .sComponent .sIdentifier {
    font-size: 0.8em;
    padding-left: 2em;
    padding-top: 4px;
    padding-bottom: 4px;
}

#productionTable .updatePoPriceButton {
    cursor: pointer;
    position: absolute;
    left: 4px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#productionTable table.apInputStack .apAddon.only-current-sypplier {
    height: 46px;
}

#confirmPoPriceModal .text {
    padding-right: 0.5em;
}

#confirmPoPriceModal .value {
    font-weight: 700;
    text-align: right;
}


#productionTable .productionTable tr td.groupTd {
    min-width: 24px;
    max-width: 24px;
        width: 24px;
}

#productionTable .productionTable tr td.selectTd {
    min-width: 24px;
    max-width: 24px;
        width: 24px;
    padding: 0;
    text-align: center;
}
#productionTable .productionTable tr td.selectTd svg {
    width: 18px;
    height: 18px;
}

#productionTable .productionTable tr.inGroup td.groupTd {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background-color: #fafafa;
}
#productionTable .productionTable tr.inGroup:not(.groupLast) td.groupTd {
    border-bottom: 0;
}

#productionTable .productionTable tr.inGroup  td.c-type {
    box-shadow: inset 10px 0px 6px -6px rgba(204,204,204,0.4);
}
#productionTable .productionTable tr.inGroup  td.selectTd {
    box-shadow: inset -10px 0px 6px -6px rgba(204,204,204,0.4);
}

/* #productionTable .productionTable tr.groupRow { */
    /* background-color: #fafafa; */
/* } */


#productionTable .productionTable tr.groupRow td.groupTd {
    -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease;
}

#productionTable .productionTable > tbody >  tr.groupRow.open td.groupTd {
    border-bottom: 0;
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

#productionTable .productionTable > tbody > tr:not(.inGroup) {
    background-color: #fafafa;
}


#productionTable .productionTable .vat-input {
    width: 100%;
    margin: 0;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    text-align: right;
    height: 34px;
    background-color: #fff;
}
#productionTable .productionTable .vat-input:disabled {
    background: #eee;
    color: #999;
}


#productionTable .productionTable .vat-input.validator-error { border-bottom-color: #ff4444; background: #ffcdd2; }

#productionTable .productionTable .groupVatContainer input {
    padding: 0;
    text-align: center;
}


#productionTable .productionTable .nameTd .apInputSmallBlock input {
    text-align: left;
    padding: 0 0 0 6px;
}

#productionTable .orderEntryTooltip .infoTable .header {
    font-weight: 700;

}
#productionTable .orderEntryTooltip .infoTable .infoLabel {
    text-align: left;
}
#productionTable .orderEntryTooltip .infoTable .valueText {
    text-align: left;
}

#productionTable td.fixed.c-unit input {
    padding: 0;
    padding-left: 2px;
    text-align: left;

}

#productionTable .icon-button {
    cursor: pointer;
}
