#user-organization-chart .node {
    padding: 8px;
    border-radius: 8px;
    display: inline-block;
    border: 2px solid var(--clr-blue-main);
}
#user-organization-chart .node small {
    display: block;
    line-height: 1.5em;
    white-space: pre;
}
#user-organization-chart .node .user-name {
    font-size: 95%;
    font-weight: bold;
}
#user-organization-chart .node hr {
    margin: 0.3em 0;
}
#user-organization-chart .node svg {
    width: 1.5em;
    height: 1.5em;
}
#user-organization-chart .node .copy-button {
    color: var(--clr-blue-main);
}

@media print {
    #user-organization-chart .print-button-container {
        display: none;
        visibility: hidden;
    }
}