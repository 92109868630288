#TaskQueue .apList > .item .columns .column {
    overflow: visible;
}

.taskqueue-project-name {
    margin: 0;
}

.taskqueue-assignment-name {
    max-width: 200px;
}

#TaskQueue .project-name small {
    display: block;
}

.apList > .item {
    overflow-x: clip;
}

#TaskQueue .apList > .item .dragHandle {
    z-index: 99;
}

@media screen and (max-width: 1200px) {
    #TaskQueue .item .columns {
        display: flex;
        left: 250px;
        right:initial;
    }
    #TaskQueue .item {
        overflow: clip;
    }
    #TaskQueue .item .dragHandle {
        z-index: 99;
    }
}