#arrivalReceiverDataSelect  .receiver-indent {
    margin-left: 20px;
}

#arrivalReceiverDataSelect .col-container {
    width: 100%;
}

#arrivalReceiverDataSelect .col-left {
    width: 48%;
    display: inline-block;
    margin-right: 2%;
}
#arrivalReceiverDataSelect .col-right {
    width: 48%;
    display: inline-block;
    margin-left: 2%;
}
