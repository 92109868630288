
.timetrackingUserBrowser {
	padding: 0 50px;
	position: relative;
	min-height: 73px;
}

.timetrackingUserBrowser .controls {
	position: absolute;
	z-index: 3;
	top: 0;
	bottom: 0;
	width: 50px;
	background: #fff;
}

.timetrackingUserBrowser .controls.left {
	left: 0;
	border-right: 1px solid #ccc;
}

.timetrackingUserBrowser .controls.right {
	right: 0;
	border-left: 1px solid #ccc;
}

.timetrackingUserBrowser .controls .filter {
	width: 200px;
	padding: 0 50px 0 1em;
}

.timetrackingUserBrowser .controls .arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 100%;
	cursor: pointer;
}
.timetrackingUserBrowser .controls .arrow.next { left: auto; right: 0; }

.timetrackingUserBrowser .controls .arrow svg {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.timetrackingUserBrowser .controls .arrow:hover svg { fill: #33b5e5; }
.timetrackingUserBrowser .controls .arrow.disabled { cursor: not-allowed; }
.timetrackingUserBrowser .controls .arrow.disabled svg { opacity: 0.2; }

.timetrackingUserBrowser .users {
	padding: 0.5em 0.5em 0;
	display: flex;
	overflow-x: auto;
}
.timetrackingUserBrowser .users:after {
	content: '';
	display: block;
	clear: both;
}

.timetrackingUserBrowser .users .user {
	float: left;
	min-width: 220px;
	
	
	margin: 0 0.5em 0.5em 0;
	background: #eee;
	border: 1px solid #ccc;
	border-left: 3px solid #ccc;

	padding: 0.5em 1em 0.5em 50px;

	line-height: 1.2em;

	position: relative;
	cursor: pointer;
}

.timetrackingUserBrowser .users .user:hover {
	background: #f9f9f9;
}

.timetrackingUserBrowser .users .user.selected {
	border-left-color: #33b5e5;
	background: #fff;
}

.timetrackingUserBrowser .users .user.selected strong { color: #33b5e5; }

.timetrackingUserBrowser .users .user .ApUserImage {
	position: absolute;
	top: 50%;
	left: 9px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}
