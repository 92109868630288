#pageCompanySettings .iconHeader {
	text-align: center;
}

#pageCompanySettings .iconHeader svg {
	width: 20px;
	height: 20px;
}

#pageCompanySettings .nameCell,
#pageCompanySettings .booleanCell,
#pageCompanySettings .currencyCell,
#pageCompanySettings .iconCell {
	font-size: 16px;
	line-height: 1.2em;
	padding: 0.5em;
}

#pageCompanySettings .nameCell small {
	font-size: 14px;
	color: #999;
}

#pageCompanySettings .booleanCell {
	text-align: left;
}
#pageCompanySettings .booleanCell.onlyIcons { text-align: center; }
#pageCompanySettings .booleanCell.true svg { fill: #4caf50; }
#pageCompanySettings .booleanCell.false svg { fill: #ff4444; }

#pageCompanySettings .currencyCell {
	text-align: right;
}

#pageCompanySettings .currencyCell .unit {
	font-size: 14px;
	color: #aaa;
}
#pageCompanySettings .iconCell {
	text-align: center;
}

#pageCompanySettings .iconCell svg {
	width: 16px;
	height: 16px;
}

#pageCompanySettings .saveBar {
	padding: 1em;
	background: #fff;
	border-top: 3px solid #fff;
}

#pageCompanySettings .ApStickyBar.sticky .saveBar {
	border-top-color: #33b5e5;
}

#pageCompanySettings .ApRadioSwitchBlock {
	margin-top: 0.5em;
}

#pageCompanySettings .integrated-software {
    width: 100%;
    margin: 0;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    text-align: left;
    height: 34px;
    background-color: #fff;
}

#edit-news-modal .apModalBody {
	overflow: visible;
}