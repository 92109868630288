.poRelatedEdit .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}

.poRelatedEdit .footer {
    text-align: right;
}
.poRelatedEdit  .footer .apButton {
    margin: 0 0 0 1em;
}
