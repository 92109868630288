#navTop .blocks > .block.languages .button .flag {
  display: inline-block;
  width: 50px;
  height: 30px;
  margin-bottom: 1px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#navTop .blocks > .block.languages .topModal {
  /*width: 250px;*/
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#navTop .blocks > .block.languages .topModal button {
  margin: 1%;
  background: transparent;
  border: 1px solid #eee;
  border-radius: 10px;
  padding-top: 10px;
}

#navTop .blocks > .block.languages .topModal button:focus,
#navTop .blocks > .block.languages .topModal button:hover {
  outline: none;
  border-color: #ccc;
}

#navTop .blocks > .block.languages .topModal button.selected {
  border-color: #33b5e5;
  color: #33b5e5;
}
#navTop .blocks > .block.languages .topModal button .flag {
  display: flex;
  width: 50px;
  height: 30px;
  vertical-align: middle;
  background-position: center;
  background-size: cover;
  text-align: center;
}

#navTop .blocks > .block.languages .topModal button .text {
  font-size: 14px;
}

@media all and (max-width: 499px) {
  #navTop .blocks > .block.languages .button .flag {
    width: 30px;
    height: 20px;
  }
}
