#confirmPo .saveButtonContainer {
    float: right;
}

#confirmPo .fileInputContainer {
    margin-bottom: 2em;
}
#confirmPo .apModalWrapper .apModal .apModalFooter,
#confirmPo .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}
