.saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0.5em;
    border-top: 3px solid #fff;
    text-align: right;
    -webkit-transition: border-top 0.4s ease;
    transition: border-top 0.4s ease;
}