#NewUserHeader {
	padding: 0.5em;
}


#NewUserFooter {
	padding: 0.5em;
}

#NewUserBody {
	height: 500px;
}

#NewUserBody > .padding {
	padding-top: 0;
}


#NewUserFooter .apButton {
	width: 40%;
	margin: 0;
}

#NewUserFooter .apButton.next {
	margin-left: 20%;
}

#NewUserFooter .apErrorMsg {
	margin: 0.5em 0 0;
}

#NewUser .stepType .option:hover {
    background: #FFF;
    border-bottom-color: #33b5e5;
}
#NewUser .stepType .option {
    position: relative;
    padding: 1em;
    margin: 1em 0;

    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    background: #f9f9f9;
    cursor: pointer;
}

#NewUser .stepType .option.selected {
    outline: 1px solid #33b5e5;
    border-color: #33b5e5;
    background: #fff;


}

#NewUser .stepType .option .iconContainer {

    position: absolute;
    left: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#NewUser .stepType .option:hover .iconContainer svg { color: #333; }
#NewUser .stepType .option .iconContainer svg {
    height: 30px;
    width: auto;
}

#NewUser .stepType .option .textContainer {
    margin-left: 60px;
    border-left: 1px solid #ccc;
    padding-left: 20px;
}

#NewUser .stepType .option:hover .textContainer .header { color: #0099CC; }
#NewUser .stepType .option .textContainer .header {
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

#NewUser .stepType .option .textContainer .text {
    padding-left: 1em;
}

#NewUser #newUser_contract_end + div {
    right: 0;
}

@media (min-height: 1000px) {
    #NewUser .apModalWrapper .apModal .apModalBody {
        overflow: visible;
    }
}