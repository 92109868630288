
#ProjectMassList .fileSelectContainer {
    margin-bottom: 2em;
}

#ProjectMassList table.componentsTable {
    width: 100%;
}

#ProjectMassList table.componentsTable th {
    /* padding-bottom: 0.5em; */
    /* padding-right: 0.2em; */

    padding: 0.5em 0.2em;

}

#ProjectMassList table.componentsTable th .headerSelected svg {
    width: 12px;
    height: 12px;
}

#ProjectMassList table.componentsTable th .headerSelected .headerSelectedText {
    margin-left:  4px;
}

#ProjectMassList table.componentsTable th .input-menu {
    width: 100%;
    margin: 0;
    padding: 0 30px 0 2px;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    line-height: 30px;
}

#ProjectMassList table.componentsTable th .input-menu-button {
    position: absolute;
    top: 0;
    height: 100%;
    right: 2px;
}

#ProjectMassList table.componentsTable td {
    padding-left:  0.2em;
    padding-right: 0.2em;
}

#ProjectMassList table.componentsTable td.otherTd input {
    text-align: right;
    padding-right: 40px;
}

#ProjectMassList table.componentsTable .relatedComponent input {
    padding-right: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#ProjectMassList table.componentsTable .rowNumber {
}

#ProjectMassList table.componentsTable .relatedComponent {
    min-width: 300px;
}

#ProjectMassList table.componentsTable .fixed {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}

#ProjectMassList table.componentsTable .status {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

#ProjectMassList table.componentsTable td.relatedComponentMenu {
    text-align: center;
}
#ProjectMassList table.componentsTable td.relatedComponentMenu .apDropdown .apDropdownMenu {
    width: 250px;
}

#ProjectMassList .apModalWrapper .apModal .apModalFooter {
    max-height: none;
    overflow: visible;
}

#ProjectMassList .footer .apDropdown .apDropdownMenu {
    width: 300px;
}

#ProjectMassList .footer  {
    text-align: right;
}

#ProjectMassList .footer .right,
#ProjectMassList .footer .left,
#ProjectMassList .footer .footerInfo {
    display: inline-block;
}

#ProjectMassList .footer .left {
    float: left;
}

#ProjectMassList .footer .right button {
    margin-left: 2px;
}

#ProjectMassList .footer .footerInfo {
    margin-right: 1em;
    float: left;
}

#ProjectMassList .footer .footerInfo .footerInfoTitle {
    padding-right: 0.2em;
}
#ProjectMassList .footer .footerInfo .footerInfoValue {
    font-weight: 700;
    padding-right: 1em;
}

#ProjectMassList .footer .footerInfo .footerInfoValue.orange {
    color: #e65100;
}

#ProjectMassList .footer .footerInfo .footerInfoValue.red {
    color: #CC0000;
}

#ProjectMassList .footer .footerInfo .footerInfoValue.blue {
    color: #0099CC;
}

#ProjectMassList .footer .footerMenu {
    padding: 0.5em;
}
#OfferCalculation #ProjectMassList .apModalWrapper .apModal .apModalBody {
    overflow: auto;
}

