
/* #StorageSettingRowEdit .apModalWrapper .apModal .apModalBody { */
    /* overflow: visible; */
/* } */

#OfferTemplateEditFooter .apButton.save {
    float: right;
}

#OfferTemplateEdit .keepPreviousContainer {
    margin-top: 1em;
}

#OfferTemplateEdit .previousVersionContainer {
    margin-top: 1em;
}

#OfferTemplateEdit .previousVersionContainer .previousVersionTable {
    width: 100%;
}

#OfferTemplateEdit .previousVersionContainer .previousVersionTable th ,
#OfferTemplateEdit .previousVersionContainer .previousVersionTable td {
    border-bottom: 1px solid #ccc;
    padding: 0.5em 0.2em;
}

#OfferTemplateEdit .previousVersionContainer .formHeader svg {
    margin-right: 0.5em;
    width: 18px;
    height: 18px;
    -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease;
}

#OfferTemplateEdit .previousVersionContainer .formHeader.open svg {
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

