#sent-po-offer .apDropdown.open .apDropdownMenu {
    white-space: normal;
}

#sent-po-offer .apModalBody.narrow {
    overflow: visible;
}

#po-offer .apInputBlock svg.validationIcon {
    z-index: 11;
}

#sent-po-offer #poModalDataTable .input-menu {
    width: 100%;
    margin: 0;
    padding: 0 30px 0 2px;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    line-height: 30px;
    height: 32px;
}

#sent-po-offer #poModalDataTable .headerLabel {
    max-width: 100%;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
}

#sent-po-offer #poModalDataTable .input-menu-button {
    position: absolute;
    top: 0;
    height: 100%;
    right: 2px;
}

#sent-po-offer #poModalDataTable .componentsTable .apDropdown.open .apDropdownMenu {
    overflow: auto;
}

#sent-po-offer #poModalDataTable .apDropdown .action {
    padding: 0;
    padding-left: 36px;
}

#sent-po-offer #poModalDataTable .apReactTable .ReactTable .rt-td.otherCell {
    position: relative;
    padding: 0;
}

#sent-po-offer #poModalDataTable .otherCell .other  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 0.5em;
}

#sent-po-offer #poModalDataTable .otherCell .other.noUpdate {
    /* background-color: #eee; */
    color: #ddd;
}

#sent-po-offer #poModalDataTable .rt-resizable-header:last-child,
#sent-po-offer #poModalDataTable .rt-resizable-header-content {
    overflow: visible;

}

#sent-po-offer .ApModalWizard .apModalWrapper .apModal .apModalBody {
    overflow: auto
}