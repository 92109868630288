#newOrder .apModalWrapper .apModal .apModalBody {
    min-height: 500px;
}

#newOrder .supplierInfo {
    margin-left: 1em;
}
#newOrder .supplierInfo .mainTitle {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}
#newOrder .supplierInfo .title {
    font-weight: 700;
    margin-left: 1em;
}
#newOrder .supplierInfo .value {
    margin-left: 2em;
}

#newOrder .receiverFields {

    margin-left: 1em;
}

#newOrder .indent {
    margin-left: 70px;
}


#newOrder .col-container {
    width: 100%;
}

#newOrder .col-left {
    width: 48%;
    display: inline-block;
    margin-right: 2%;
}
#newOrder .col-right {
    width: 48%;
    display: inline-block;
    margin-left: 2%;
}

#newOrder .addressTitleContainer {
    position: relative;
}

#newOrder .addressTitleContainer .addressTitle {
    margin-left: 50px;
}

#newOrder .addressTitleContainer .addressMenu {
    position: absolute;
    left: 0;
    top: 0;
}

#newOrder .addressTitleContainer .addressMenu .addressRow {
    font-size: 0.6em;
    line-height: 1.2em;
}

#newOrder .addressTitleContainer .addressMenu .apDropdown.open .apDropdownMenu { z-index: 101; }


#newOrder .stepType .option:hover {
    background: #FFF;
    border-bottom-color: #33b5e5;
}
#newOrder .stepType .option {
    position: relative;
    padding: 1em;
    margin: 1em 0;

    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    background: #f9f9f9;
    cursor: pointer;
}

#newOrder .stepType .option.selected {
    outline: 1px solid #33b5e5;
    border-color: #33b5e5;
    background: #fff;


}

#newOrder .stepType .option .iconContainer {

    position: absolute;
    left: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#newOrder .stepType .option:hover .iconContainer svg { color: #333; }
#newOrder .stepType .option .iconContainer svg {
    height: 30px;
    width: auto;
}

#newOrder .stepType .option .textContainer {
    margin-left: 60px;
    border-left: 1px solid #ccc;
    padding-left: 20px;
}

#newOrder .stepType .option:hover .textContainer .header { color: #0099CC; }
#newOrder .stepType .option .textContainer .header {
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

#newOrder .stepType .option .textContainer .text {
    padding-left: 1em;
}


