
.storageSummaryBox {
    margin-bottom: 1em;
    padding: 1em;
    background: #e1f5fe;
    border: 1px solid #81d4fa;
}

.storageSummaryBox .noNumber {
    opacity: 0.7;
}

.storageSummaryBox svg.SvgIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.storageSummaryBox .edit {
    color: #0277bd;
    cursor: pointer;
}

.storageSummaryAuditContainer {
    position: relative;
    min-width: 1000px;
}

.storageSummaryAuditContainer .summaryBlock {
    margin-right: 36%;
}

.storageSummaryAuditContainer .auditBlock {
    width: 35%;
    position: absolute;
    right: 0;
    top: 0;
    max-height: 100%;
    min-height: 100%;
    overflow: auto;
    border: 1px solid lightgray;

    background: #f9f9f9;
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 10px rgba(0,0,0,0.3);

}

@media all and (max-width: 699px) {
    .storageSummaryBox .apForm .apFormColumn {
        width: 46%;
        float: left;
    }
}

