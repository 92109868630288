.codeSelect {
    position: relative;
}

.codeSelect .results .item .codeOption {
    position: relative;
    padding-top: 1em;
}

.codeSelect .results .item .name {
    position: absolute;
    top: 0;
    left: 1em;
    color: #AAA;
    font-size: 0.8em;
}

.codeSelect .results .item .code {
    padding-left: 1em;
}

.codeSelect label:hover {
    overflow: visible;
}

.codeSelect label .apTooltipper {
    vertical-align: middle;
}

.codeSelect label .apSwitchWrapper  {
    margin-right: 10px;
}

.codeSelect .nextFree .title {
    position: absolute;
    right: 4px;
    top: 0px;
    font-size: 0.8em;
    color: #aaa;
}
.codeSelect .nextFree .code {
    position: absolute;
    right: 4px;
    top: 14px;
    font-size: 0.8em;
    font-weight: 700;
    color: #aaa;
}
