#pagePo {
    margin-bottom: 16em;
}

#pagePo .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#pagePo .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }


#pagePo .saveBar .left {
    float: left;
    display: inline-block;
    text-align: left;
}
#pagePo .saveBar .right {
    display: inline-block;
    text-align: right;
}

#pagePo .latestCommentContainer .latestCommentTitle {
    font-size: 0.8em;
    font-weight: 700;
    color: #33b5e5;
}

#pagePo .latestCommentContainer .latestCommentBox {
    padding: 1em;
    position: relative;
}

#pagePo .latestCommentContainer .historyButton {
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}



#pagePo .fieldEdit .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}

#pagePo .commentFilesContainer {
    position: relative;
    min-height: 120px;
}

#pagePo .commentFilesContainer .commentContainer {
    position: absolute;
    width: 64%;
    top: 0;
    bottom: 0;
}
#pagePo .commentFilesContainer .commentContainer .apInputBlock  { height: 100%; }
#pagePo .commentFilesContainer .commentContainer .apInputBlock textarea {
    position: absolute;
    top: 0;
    bottom: 0;
}

#pagePo .commentFilesContainer .commentContainer .internalCommentSave:hover { background-color: #eeeeee; }
#pagePo .commentFilesContainer .commentContainer .internalCommentSave {
    z-index: 2;
    position: absolute;
    bottom: 3px;
    right: 0;
    padding: 4px;
    border: 1px solid #ccc;
    border-bottom: 0;
    background-color: #fff;
    cursor: pointer;
}

.edit-po-navigation-buttons {
    padding-bottom: 50px;
}

.next-button {
    float: right;
}

.previous-button {
    float: left;
}

#pagePo .commentFilesContainer .filesContainer { margin-left: 65%; }

.due-red {
    color: red !important;
}

@media all and (max-width: 700px) {
    #pagePo .commentFilesContainer .commentContainer {
        width: 100%;
    }
}