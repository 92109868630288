
.trackingChart {
    position: relative;
}

/* Style the lines by removing the fill and applying a stroke */
.trackingChart .line {
    fill: none;
    stroke: #000;
    stroke-width: 2;
}
.trackingChart .line.strong { stroke-width: 4; }

.trackingChart .line100 {
    fill: none;
    stroke: #b71c1c;
    stroke-width: 1;
    stroke-dasharray: 4;

}

.trackingChart .lineToday {
    fill: none;
    stroke:  #1a237e;
    stroke-width: 1;

}

.trackingChartTooltip {
    position: fixed;

    background: #000;
    color: #fff;
    line-height: 1.2em;
    white-space: nowrap;
    padding: 0.5em;
    z-index: 1000;
}

.trackingChartTooltip table td.title {
    padding-right: 2em;
    font-size: 0.8em;
}

.trackingChartTooltip table td.value {
    text-align: right;
    font-size: 0.8em;
    font-weight: 700;
    min-width: 100px;
}
