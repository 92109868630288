
#addMultipleModal .apModalBody {
    min-height: 60vh;
}

#addMultipleModal .checkboxContainer {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}
#addMultipleModal .nameContainer {
    margin-left: 26px;
}


#projectManagement #addMultipleModal .checkboxContainer {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}
#projectManagement #addMultipleModal .nameContainer {
    margin-left: 26px;

}
