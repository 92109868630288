#pageDeliveryEdit #componentInstanceTable .apTooltipper {
    width: 100%;
}
#pageDeliveryEdit .approvedStatus {
    display: inline-block;
    margin-right: 7px;
}


#poEvents .event.removed .header {
    background: #ffebee !important;
    text-decoration: line-through;
}
#poEvents .event.removed .content {
    text-decoration: line-through;
    opacity: 0.5;
}

#poEvents .event .header svg.unsavedIcon {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    width: 24px;
    height: 24px;
    fill: #ff9800;
}

#poEvents {
    padding: 1em 1em 1em 0;
    background: #f9f9f9;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

#poEvents .timeline {
    position: absolute;
    top: 2em;
    bottom: 2em;
    left: 25px;
    width: 2px;
    background: #ccc;
}

#poEvents .event,
#poEvents .message {
    margin: 0 0 1em 50px;
    position: relative;
}

#poEvents .event:last-of-type,
#poEvents .message:last-of-type {
    margin-bottom: 0;
}

#poEvents .message {
    line-height: 50px;
}

#poEvents .event {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

#poEvents .event svg.eventIcon,
#poEvents .message svg.eventIcon {
    position: absolute;
    top: 9px;
    left: -25px;
    width: 32px;
    height: 32px;
    padding: 8px;
    background: #999;
    fill: #fff;
    border-radius: 999px;

    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

#poEvents .event.accepted svg.eventIcon {
    background: #2BBBAD;
}

#poEvents .event.further_approver svg.eventIcon {
    background: #4285F4;
}

#poEvents .event.rejected svg.eventIcon {
    background: #aa66cc;
}

#poEvents .event.removed svg.eventIcon {
    opacity: 0.5;
}

#poEvents .event .header {
    background: #fff;
    border-left: 3px solid #999;
    padding: 0.5em;
    padding-left: 50px;
    line-height: 1.2em;
    position: relative;
    cursor: pointer;

    -webkit-transition: background 0.4s ease;
    -moz-transition: background 0.4s ease;
    -ms-transition: background 0.4s ease;
    -o-transition: background 0.4s ease;
    transition: background 0.4s ease;
}

#poEvents .event .header:hover {
    background: #f9f9f9;
}

#poEvents .event.call .header {
    border-left-color: #2BBBAD;
}

#poEvents .event.email .header {
    border-left-color: #4285F4;
}

#poEvents .event.visit .header {
    border-left-color: #aa66cc;
}

#poEvents .event.call.detailsOpen .header {
    background: #e0f2f1;
}

#poEvents .event.email.detailsOpen .header {
    background: #e3f2fd;
}

#poEvents .event.visit.detailsOpen .header {
    background: #f3e5f5;
}

 #poEvents .event .header .user {
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

#poEvents .event .content {
    border-top: 1px solid #eee;
    background: #fff;
    padding: 1em;
}

#poEvents .event .content p strong {
    font-family: 'Roboto', Arial, sans-serif;
    color: #0099CC;
    font-size: 14px;
}

#poEvents .event .content p small {
    font-size: 100%;
    color: #aaa;
}

#poEvents .event .apDropdown {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
}
/*

#poEvents .event.filtered {
    pointer-events: none;
    height: 10px;
    background: #eee;
    box-shadow: none;
}

#poEvents .event.filtered.removed {
    display: none;
}

#poEvents .event.filtered svg.eventIcon {
    top: -3px;
    width: 16px;
    height: 16px;
    padding: 4px;
    background: #ccc;
}

#poEvents .event.filtered .header,
#poEvents .event.filtered .content,
#poEvents .event.filtered .apDropdown {
    display: none;
} */
