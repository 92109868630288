
#UserEditBody .supervisors {
	list-style: none;
	padding: 0;
	margin: 0 0 0.5em;
	border: 1px solid #ccc;
	background: #eee;
	max-height: 200px;
	overflow: auto;
}

#UserEditBody .supervisors .noSupervisor {
	text-align: center;
	padding: 1em;
}

#UserEditBody .newUserItem { 
	padding-left: 25px;
	position: relative;
}

#UserEditBody .newUserItem svg {
	position: absolute;
	top: 50%;
	left: 0;
	width: 16px;
	height: 16px;
	margin-top: -10px;
	pointer-events: none;
}

#UserEditBody .newUserItem .userNotActiveMsg {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	font-size: 14px;
	color: #ff4444;
}

#UserEditBody .supervisors .supervisor {
	margin: 0.5em;
	background: #fff;
	border-left: 3px solid #ccc;
	position: relative;
}

#UserEditBody .supervisors .supervisor:hover { border-left-color: #aaa; }

#UserEditBody .supervisors .supervisor.disabled {
	border-left-color: #ff4444;
	color: #999;
}

#UserEditBody .supervisors .supervisor.primary {
	border-left-color: #0099CC;
	color: #0099CC;
}

#UserEditBody .supervisors .supervisor svg {
	position: absolute;
	top: 50%;
	left: 10px;
	width: 16px;
	height: 16px;
	margin-top: -10px;
	pointer-events: none;
}

#UserEditBody .supervisors .supervisor .person {
	padding: 0.5em;
	padding-left: 35px;
	margin-right: 50px;
	line-height: 1.2em;
	cursor: pointer;
}

#UserEditBody .supervisors .supervisor small {
	display: block;
}

#UserEditBody .supervisors .supervisor svg.remove {
	left: auto;
	right: 10px;
	pointer-events: auto;
	padding: 6px;
	width: 32px;
	height: 32px;
	margin-top: -16px;
	border: 1px solid #ccc;
	fill: #666;
	cursor: pointer;
	border-radius: 5px;
	cursor: pointer;
}
#UserEditBody .supervisors .supervisor svg.remove:hover {
	background: #eee;
}

#UserEditBody .supervisors .supervisor .userNotActiveMsg {
	position: absolute;
	top: 50%;
	right: 50px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	font-size: 14px;
	color: #ff4444;
	padding: 0.5em;
	border-left: 1px solid #ff4444;
	background: rgba(255,255,255,0.8);
}

