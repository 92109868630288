#AssignmentPage .navigation-btns-container {
  display: flex;
  justify-content: space-between;
}

#AssignmentPage .pageColumn {
  padding: 24px;
  background-color: #f3f3f3;
  border: #ddd 1px solid;
  font-size: 1.1em;
}

#AssignmentPage .pageColumn .summaryTitle {
  font-weight: bold;
  text-align: right;
  padding-bottom: 8px;
}

#AssignmentPage .pageColumn .summaryContent {
  text-align: left;
  padding-bottom: 8px;
}

#AssignmentPage .pageColumn .assignmentContent {
  padding-top: 16px;
  padding-left: 16px;
}

#AssignmentPage .assignment-creator {
  padding-bottom: 3px;
}
#AssignmentPage .assignment-creator span {
  font-weight: bold;
}

#AssignmentPage .removeFileBtn:hover {
  color: #ff4444;
}

#AssignmentPage .buttons-container {
  display: flex;
  justify-content: flex-end;
}

#AssignmentPage .buttons-container > div {
  margin: 1.5px;
}

#AssignmentPage .buttons-container > div button {
  padding-left: 1rem;
  padding-right: 1rem;
}

#AssignmentPage .saveBar {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 0.5em 0;
  border-top: 3px solid #fff;
  text-align: right;
  -webkit-transition: border-top 0.4s ease;
  transition: border-top 0.4s ease;
}

#AssignmentPage .ApStickyBar.sticky .saveBar {
  border-top-color: #33b5e5;
}


#AssignmentPage .pageColumn .assignmentHeader {
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 1.1em;
}

#AssignmentPage .componentTable {
  min-width: auto;
}

#AssignmentPage .pageColumn .commentContainer {
  font-size: 0.8em;
  background-color: #fff;
  padding: 8px 14px 8px 14px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 3px solid #ccc;
}

.user .image {
  width: 40px;
  height: 40px;
  background: #f9f9f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 2px solid #ccc;
  border-radius: 999px;
}

#AssignmentPage .pageColumn .newCommentArea {
  padding-top: 16px;
}

#AssignmentPage .pageColumn .newCommentArea .commentSave {
  padding-right: 4px;
  text-align: right;
}

#AssignmentPage .controlPanel {
  margin: 15px;
  padding: 24px;
  background-color: #f3f3f3;
  border: #ddd 1px solid;
  font-size: 1em;
}

#AssignmentPage .assignment-image,
#AssignmentPage .assignment-file {
  display: block;
  max-height: 400px;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
}

#AssignmentPage .assignment-file {
  width: 100%;
  height: 400px;
}

#AssignmentPage #componentInstanceTable .c-name .apTooltipper {
  position: static;
}
#AssignmentPage .pointer .apTooltipper {
  position: relative;
}
#AssignmentPage .saveNew .apTooltipper {
  position: relative;
}

@media all and (max-width: 1000px) {
  #AssignmentPage .apColumn.w25 {
    width: 50%;
  }
}

@media all and (max-width: 500px) {
  #AssignmentPage .buttons-container {
    flex-direction: column;
  }
  #AssignmentPage .dropdown-container {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 700px) {
  #AssignmentPage #componentInstanceTable thead.table-head th {
    padding: 0 0.3em;
  }
  #AssignmentPage #componentInstanceTable .componentTable tr td.selectTd,
  #AssignmentPage #componentInstanceTable .componentTable tr td.groupTd,
  #AssignmentPage #componentInstanceTable td.fixed.c-count,
  #AssignmentPage #componentInstanceTable .componentTable td.fixed {
    width: unset;
    min-width: unset;
    max-width: unset;
  }
  #AssignmentPage #componentInstanceTable td.c-name {
    width: 90%;
  }
  #AssignmentPage #componentInstanceTable .noWordWrap {
    text-decoration: underline rgba(0, 0, 0, 0.3);
  }
  #AssignmentPage #componentInstanceTable td.saveNew .apTooltipper.block {
    width: 60px;
  }
  #AssignmentPage #componentInstanceTable input.componentRowInput {
    padding: 0 10px 0;
  }
}

@media all and (min-width: 800px) {
  #AssignmentPage .description-container {
    padding-right: 0.5em;
  }
  #AssignmentPage .files-container {
    padding-left: 0.5em;
  }
}
@media all and (max-width: 700px) {
  #AssignmentPage #componentInstanceTable input.componentRowInput {
    text-align: left;
  }
}