.addressModal .addressRow {
    font-size: 0.6em;
    line-height: 1.2em;
}
.addressModal .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}
.addressModal .bodyContainer {
    position: relative;
}
.addressModal .optionsMenu {
    position: absolute;
    top: 1em;
    left: 1em;
}
.addressModal .fields {
    margin-left: 50px;
}
