#UserTable .userCount {
	
	margin: 0 0 0.5em;
	position: relative;
}

#UserTable .userCount .apInfo {
	display: block;
	position: absolute;
	left: 0;
	top: 50%;
	width: calc( 100% - 200px );
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#UserTable .userCount:after {
	content: '';
	clear: both;
	display: block;
}

#UserTable .userCount .apButton {
	float: right;
	margin: 0;
}

#UserTable .salaryMultiplier {
	border-top: 3px solid #ccc;
	background: #eee;
	position: relative;
	margin: 0 0 1em;
	padding: 0.5em;

	line-height: 1.2em;
}

#UserTable .salaryMultiplier:after {
	bottom: 100%;
	right: 220px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(204, 204, 204, 0);
	border-bottom-color: #ccc;
	border-width: 10px;
	margin-left: -10px;
}

#UserTable .salaryMultiplier .apInputBlock {
	margin: 0;
}

#UserTable .searchOption {
	position: relative;
	padding-left: 35px;

	line-height: 1.2em;
}

#UserTable .searchOption svg.typeIcon {
	position: absolute;
	top: 50%;
	left: 0;
	width: 24px;
	height: 24px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#UserTable .searchOption .usertable {
	position: absolute;
	top: 50%;
	right: 1em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#UserTable .searchOption .usertable svg { fill: #aaa; }
