
.DayEntries {
	margin: 0 0 2em;
}

.DayEntries .header {
	font-size: 18px;
	font-family: 'Roboto', Arial, sans-serif;
	padding: 1em 1em 0.5em;
}


.DayEntries table.entries {
	width: 100%;
}

.DayEntries table.entries tr {
	background: #fff;
}

.DayEntries table.entries tr td {
	padding: 5px;
	font-size: 15px;
	line-height: 1.2em;
	border-bottom: 1px solid #eee;
}

.DayEntries table.entries tr td span,
.DayEntries table.entries tr td small {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.DayEntries table.entries tr td small {
	font-size: 13px;
	color: #999;
}

.DayEntries table.entries tr.entry td.select { border-left: 3px solid #eee; }
.DayEntries table.entries tr.entry:hover td.select { border-left-color: #ccc; }

.DayEntries table.entries tr.entry.status-3 td.select { border-left-color: #ff4444; }
.DayEntries table.entries tr.entry.status-4 td.select { border-left-color: #4caf50; }

.DayEntries table.entries tr.entry.selected td { color: #0099CC; }
.DayEntries table.entries tr.entry.selected td.select { border-left-color: #33b5e5; }

.DayEntries table.entries tr.entry.misentry td { text-decoration: line-through; }
.DayEntries table.entries tr.entry.misentry td.status { text-decoration: none; }


.DayEntries table.entries tr td.select 	{ width: 40px; text-align: center; }
.DayEntries table.entries tr td.icon 	{ width: 40px; text-align: center; }
.DayEntries table.entries tr td.user 	{ max-width: 100px; }
.DayEntries table.entries tr td.project { max-width: 100px; }
.DayEntries table.entries tr td.description { max-width: 200px; }
.DayEntries table.entries tr td.amount 	{ max-width: 200px; text-align: right; }
.DayEntries table.entries tr td.status 	{ width: 120px; cursor: pointer;}
.DayEntries table.entries tr td.comment { width: 40px; text-align: center; cursor: pointer; position: relative; }

.DayEntries table.entries tr td.icon svg,
.DayEntries table.entries tr td.comment svg {
	width: 20px;
	height: 20px;
}
.DayEntries table.entries tr td.comment.missing svg { fill: #ccc; }

.DayEntries .statusBox {
	background: #eee;
	color: #666;
	font-size: 13px;
	line-height: 30px;
	text-align: center;
}

.DayEntries .statusBox.approved {
	background: #c8e6c9;
	color: #007E33;
}
.DayEntries .statusBox.rejected {
	background: #ffcdd2;
	color: #CC0000;
}

.DayEntries .statusBox svg {
	width: 16px;
	height: 16px;
	margin: -3px 5px 0 0;
}

.DayEntries .dayTotals {
	width: 100%;
	margin: 0.2em 0 1em;
	background: #eee;
	border-left: 3px solid #ccc;

	font-size: 14px;
	padding: 0.5em;
}

.DayEntries .dayTotals .salaryExtra {
	display: inline-block;
	border: 1px solid #ccc;
	padding: 0 0.5em;
	margin: 0 0 0 0.5em;
}
