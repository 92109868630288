#receiverDataSelect  .receiver-indent {
    margin-left: 20px;
}

#receiverDataSelect .col-container {
    width: 100%;
}

#receiverDataSelect .col-left {
    width: 48%;
    display: inline-block;
    margin-right: 2%;
}
#receiverDataSelect .col-right {
    width: 48%;
    display: inline-block;
    margin-left: 2%;
}
