#user-timetracking-report .date-selection {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 750px) {
  #user-timetracking-report .date-selection {
    flex-direction: column;
  }
}
