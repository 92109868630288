
.apProgressbar {
	position: relative;
	margin: 0;
}

.apProgressbar .errorLimit,
.apProgressbar .warningLimit {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	border-right: 2px solid rgba(0,0,0,0.3);
}
.apProgressbar .warningLimit { border-right-color: rgba(0,0,0,0.1); }

.apProgressbar .guides {
	position: absolute;
	top: -0.5em;
	width: 100%;
	height: calc( 100% + 10px );
}

.apProgressbar .innerBar {
	width: 100%;
	height: 10px;
	background: #eee;
	position: relative;

	box-shadow: inset 0 2px 5px rgba(0,0,0,0.2);
	overflow: hidden;
}
.apProgressbar .innerBar .bar {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	background: #00C851;
	box-shadow: inset 0 2px 5px rgba(0,0,0,0.2);

	-webkit-transition: opacity 0.4s ease, background 0.4s ease, width 0.4s ease;
	   -moz-transition: opacity 0.4s ease, background 0.4s ease, width 0.4s ease;
	    -ms-transition: opacity 0.4s ease, background 0.4s ease, width 0.4s ease;
	     -o-transition: opacity 0.4s ease, background 0.4s ease, width 0.4s ease;
	        transition: opacity 0.4s ease, background 0.4s ease, width 0.4s ease;
}

.apProgressbar.loading .innerBar .bar { opacity: 0.2; }

.apProgressbar.color-error .innerBar .bar { background: #ff4444; }
.apProgressbar.color-warning .innerBar .bar { background: #ffbb33; }


.apProgressbar .apTooltip .tooltipLine {
	text-align: left;
}

.apProgressbar .apTooltip .tooltipLine .color,
.apProgressbar .apTooltip .tooltipLine .name,
.apProgressbar .apTooltip .tooltipLine .value { 
	line-height: 20px; 
	vertical-align: middle;
	margin: 0 5px 0 0;
}

.apProgressbar .apTooltip .tooltipLine .color {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin: 0 5px 0 0;
}

.apProgressbar .apTooltip .tooltipLine .name {

}

.apProgressbar .apTooltip .tooltipLine .value {
	font-family: 'Roboto', Arial, sans-serif;
}
