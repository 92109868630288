.ApRoomSelect{
    background-color: blue;
}

#Chat > div.roomSelect > div > div.apModalWrapper.show > div > div.apModalBody.apInputModal{
    overflow: visible;
}
#ChatGroupEdit > div > div > div.apModalBody.apInputModal {
    overflow: visible;
}



#Chat .roomSelect {
    background-color: #eeeeee;
    min-width: 240px;
    width: 25%;
}

#Chat .roomSelect.chatSideNavMinified {
    background-color: #eeeeee;
    min-width: 50px;
    max-width: 50px;
}
#Chat>div.chatArea{
    
    border-left: 2px solid darkgrey;
}


#Chat > div.roomSelect > div > div.rce-container-clist.chat-list > div {
    border-radius: 5px;
    margin-bottom: 2px;
 
}

#Chat > div.chatArea > div > div.rce-container-mlist.messageList > div > div > div > div > div.rce-mbox-text.right {
    white-space: pre-wrap;
}

#Chat > div.roomSelect > div > div.rce-container-clist.chat-list{
    max-height: 79vh;
    min-height: 79vh;
    overflow: auto;
}
#Chat > div.chatArea > div.apDropdown{
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
}
#Chat > div.chatArea > div.rce-container-citem > div > div.rce-citem-body > div.rce-citem-body--top > div.rce-citem-body--top-time{
    visibility: hidden;
}
#Chat>div.chatArea .rce-mbox-text {
    padding-bottom: 13px;
}

.chatSideNavMinified {
    position: relative;
    width: 50px;
    bottom: auto;
}

.LeftList>.bottom {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ccc;
}

.LeftList>.bottom {
    border-bottom: none;
    border-top: 1px solid #ccc;
    text-align: right;
    position: relative;
}

.LeftList>.bottom .toggleButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background: #ddd;
    border-left: 1px solid #ccc;
    cursor: pointer;
}

.LeftList>.bottom .toggleButton:hover {
    background: #f9f9f9;
}

.LeftList>.bottom .toggleButton svg {
    display: block;
    width: 20px;
    height: 20px;
    margin: 15px auto;

    -webkit-transition: transform 0.4s ease;
    -moz-transition: transform 0.4s ease;
    -ms-transition: transform 0.4s ease;
    -o-transition: transform 0.4s ease;
    transition: transform 0.4s ease;
}

.chatSideNavMinified >.bottom .toggleButton {
    border-left: 0;
    border-bottom: 1px solid #ccc;
}

.chatSideNavMinified >.bottom .toggleButton svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
