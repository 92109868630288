#workhourSettings .iconHeader {
	text-align: center;
}

#workhourSettings .iconHeader svg {
	width: 20px;
	height: 20px;
}

#workhourSettings .nameCell,
#workhourSettings .booleanCell,
#workhourSettings .currencyCell,
#workhourSettings .iconCell {
	font-size: 16px;
	line-height: 1.2em;
	padding: 0.5em;
}

#workhourSettings .nameCell small {
	font-size: 14px;
	color: #999;
}

#workhourSettings .booleanCell {
	text-align: left;
}
#workhourSettings .booleanCell.onlyIcons { text-align: center; }
#workhourSettings .booleanCell.true svg { fill: #4caf50; }
#workhourSettings .booleanCell.false svg { fill: #ff4444; }

#workhourSettings .currencyCell {
	text-align: right;
}

#workhourSettings .currencyCell .unit {
	font-size: 14px;
	color: #aaa;
}

#workhourSettings .iconCell {
	text-align: center;
}

#workhourSettings .iconCell svg {
	width: 16px;
	height: 16px;
}


#workhourSettings .saveBar {
	border-top: 3px solid #ccc;
	padding: 1em;
	background: #fff;
}

#workhourSettings .confirmationUsers strong {
	display: block;
}

#workhourSettings .confirmationUsers .userBlock {
	display: inline-block;
	background: #eee;
	border: 1px solid #ccc;
	border-bottom: 3px solid #ff4444;
	margin: 0 0.5em 0.5em 0;
	padding: 0 0.5em;
	font-size: 14px;
}

#workhourSettings .confirmationUsers .userBlock.active { 
	background: #fff;
	border-bottom-color: #4caf50;
}

#workhourSettings span.missingData { color: #f00; }