
#pageTimetrackingSettings .apItemList {
	height: 400px;
}

#pageTimetrackingSettings .apItemList.hour_types .name { padding-right: 150px; }
#pageTimetrackingSettings .apItemList.vehicles .name { padding-right: 160px; }
#pageTimetrackingSettings .apItemList.allowances .name { padding-right: 120px; }

#pageTimetrackingSettings .apItemList .item.static { background: #f9f9f9; }

#pageTimetrackingSettings .apItemList .info svg.off { opacity: 0.2; }
#pageTimetrackingSettings .apItemList .info span.tag {
	margin-right: 1em;
	font-size: 14px;
}

#pageTimetrackingSettings .salaryCategories .apAddon .apBadge { 
	position: absolute; 
	top: 5px;
	left: 5px;
}

#pageTimetrackingSettings .apModalFooter .applyButton { float: right; }


#pageTimetrackingSettings .apModalBody .apOptionBar {
	margin: 0 0 1em;
}
#pageTimetrackingSettings .apModalBody .apOptionBar .option svg {
	display: block;
	margin: 0.5em auto 0;
}

#pageTimetrackingSettings .rules .rule {
	margin: 0 0 0.5em;
	padding: 10px 10px 0;
	background: #f9f9f9;
	border-left: 3px solid #ccc;
	position: relative;
}

#pageTimetrackingSettings .rules .rule .condition {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 170px;
	bottom: 10px;
	overflow: hidden;
	font-size: 14px;

}

#pageTimetrackingSettings .rules .rule .items {
	margin-left: 190px;
}

#pageTimetrackingSettings .rules .rule .items .apAddon.type.new { background: #c8e6c9; }
#pageTimetrackingSettings .rules .rule .items .apAddon.type.replace { background: #bbdefb; }

#pageTimetrackingSettings .notificationRow {
    position: relative;

    border: 1px solid #eee;
    border-left: 5px solid #eee;
    padding: 1em;
    margin: 0 0 1em;
    box-shadow: 0 5px 10px rgba(0,0,0,0.05);
    -webkit-transition: border-color 0.4s ease;
    transition: border-color 0.4s ease;
}

#pageTimetrackingSettings .apFormGroup {
	transition-property: border-color, height;
}

#social-expenses-form {
	margin-top: 30px;
	line-height: 50px;
}

#social-expenses-form .form-block {
	margin-left: 20px;
	margin-top: 30px;
}

#social-expenses-form .form-block-section {
	margin-top: 20px;
	margin-left: 20px;
}

#social-expenses-form h3 {
	text-decoration: underline;
	margin-top: 20px;
}

#social-expenses-form .social-expenses-input {
	display: flex;
	flex-direction: row;
	max-width: 600px;
}

#social-expenses-form .social-expenses-input-unit {
	margin-left: 5px;
	margin-top: 10px;
	font-weight: bold;
}

#social-expenses-form .social-expenses-input label {
	flex-wrap: wrap;
	flex-basis: 100%;
}

#other-expenses, #social-expenses-form .custom-row-groups {
	display: flex;
	flex-direction: row;
}

#social-expenses-form .delete-group-button {
	display: flex;
	align-items: flex-end;
	padding-bottom: 1em;
}

#default-ages {
	display: flex;
	flex-direction: row;
}

#tyel-18-67 {
	display: flex;
	flex-direction: row;
}

#unemployment-insurance {
	display: flex;
	flex-direction: row;
}

#temporary-employer {
	display: flex;
	flex-direction: row;
}

#social-expenses-form .form-column {
	display: flex;
	flex-direction: column;
	margin-right: 25px;
}

#social-expenses-form .push {
	flex-grow: 1;
}

#pageTimetrackingSettings .remove-btn:hover {
	color: var(--clr-error-main);
}