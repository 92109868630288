.depthInputModal { 
    position: relative;
}

.depthInputModal .apSimpleButton {
    width: 50px;
    height: 42px;
    text-align: center;
}
.depthInputModal .depthInput {
    font-weight: normal;
    display: inline-block;
    position: absolute;
    z-index: 12;
    right: 0;
    background: #fff;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
    top: calc( 100% + 5px );
    border-top: 3px solid #33b5e5;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
    padding: 1em;
    width: 400px;
}

.depthInputModal .depthInput.open {
    opacity: 1;
    pointer-events: auto;
}

.depthInputModal .depthInput:after {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: #33b5e5;
    border-width: 10px;
    margin-left: -10px;
}

