#pageTimetrackingOverview .scroller {
    width: fit-content;
    max-width: 100%;
    overflow-x: scroll;
    max-height: 50vh;
    background: #eee;
    box-shadow: inset 0 5px 20px rgba(0,0,0,0.2);
    padding: 0em;
    margin: 0 0 1em;
}
#pageTimetrackingOverview .scroller .entryTable tbody {
    
    overflow-y: auto;
    max-height: 50vh;
    background: #e6e6e6;
    box-shadow: inset 0 5px 20px rgba(0,0,0,0.2);
    padding: 0em;
    margin: 0 0 1em;
}

#pageTimetrackingOverview .entryTable {
    font-size: 14px;
    -webkit-transition: opacity 0.6s ease;
       -moz-transition: opacity 0.6s ease;
        -ms-transition: opacity 0.6s ease;
         -o-transition: opacity 0.6s ease;
            transition: opacity 0.6s ease;
}

#pageTimetrackingOverview .entryTable.loading {
    opacity: 0.2;
    pointer-events: none;
}

#pageTimetrackingOverview .entryTable th.date {
    background: #ddd;
    line-height: 1.2em;
}

#pageTimetrackingOverview .entryTable th,
#pageTimetrackingOverview .entryTable td {
    padding: 0.5em 0.2em;
    border: 1px solid #eee;
}

#pageTimetrackingOverview .entryTable td.date:hover {
    background: #f9f9f9;
}

#pageTimetrackingOverview .entryTable th.user,
#pageTimetrackingOverview .entryTable td.user{
    min-width: 200px;
    max-width: 200px;
    text-align: left;
    background: #ddd;
    padding: 0.5em;
}

#pageTimetrackingOverview .entryTable th.totals,
#pageTimetrackingOverview .entryTable td.totals{
    min-width: 114px;
    max-width: 114px;
    text-align: left;
    background: #ddd;
    padding: 0.5em;
}

#pageTimetrackingOverview .entryTable th.date,
#pageTimetrackingOverview .entryTable td.date {
    max-width: 50px;
    min-width: 50px;
    text-align: center;
}
#pageTimetrackingOverview .entryTable td.date svg {
    width: 12px;
    height: 12px;
}
#pageTimetrackingOverview .entryTable td.date svg.lockedDay {
    fill: rgba(0,0,0,0.2);
}

#pageTimetrackingOverview .entryTable td.date.selected { background: #90caf9; }
#pageTimetrackingOverview .entryTable td.date.selected:hover { background: #bbdefb; }

#pageTimetrackingOverview .entryTable .apTooltip {
    text-align: left;
}

#pageTimetrackingOverview .guides {
    display: flex;
    flex-direction: row;
	font-size: 14px;
    padding: 0 2em 0 0;
    overflow-x: visible;
}

#pageTimetrackingOverview .apBadge.green.confirmed {
    background: transparent;
    color: black;
    /* box shadow instead of border to keep element the same size as others like it */
    box-shadow: inset 0px 0px 0px 2px #388e3c;
}
#pageTimetrackingOverview .apBadge.green.confirmed .SvgIcon {
    color: #388e3c;
}

#pageTimetrackingOverview .entryTable th.date{
    position: sticky;
    top: 0;
    z-index: 5;
    border-top: none;
}

#pageTimetrackingOverview .entryTable td.user{
    position: sticky;
    left: 0;
    z-index: 4;
    border-left: none;
}
#pageTimetrackingOverview .entryTable th.totals{
    position: sticky;
    top: 0;
    right: 0;
    z-index: 6;
    border-right: none;
    border-top: none;
}
#pageTimetrackingOverview .entryTable td.totals{
    position: sticky;
    right: 0;
    z-index: 4;
    border-right: none;
}
#pageTimetrackingOverview .entryTable th.user{
    position: sticky;
    top: 0;
    left:0;
    z-index: 6;
    border-top: none;
    border-left: none;
}
@media screen and (max-width: 500px) {
  #pageTimetrackingOverview .entryTable td.totals{
    position: static;
    right: auto;
    z-index: 4;
    border-right: none;
}
#pageTimetrackingOverview .entryTable th.totals{
    position: static;
    top: auto;
    right: auto;
    z-index: 6;
    border-right: none;
    border-top: none;
}
}

#pageTimetrackingOverview .apAddon{
    text-align: center;
}

#pageTimetrackingOverview .overview-content{
    display: flex;
    flex-direction: row;
}

@media all and (max-width: 900px) {
    #pageTimetrackingOverview .overview-content{
        flex-direction: column;
    }
}
