
.apTimeline {
	position: relative;
	margin: 1em 0;
	min-width: 1100px;
}

.apTimeline .loadingMask { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.5);
	z-index: 999; 
}

.apTimeline .react-calendar-timeline {
	margin: 0 0 0.5em;
}

.apTimeline .react-calendar-timeline .rct-sidebar,
.apTimeline .react-calendar-timeline .rct-sidebar-header {
	border-right: 1px solid #ccc;
}

.apTimeline .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
	padding: 0;
	border-bottom: 1px solid #eee;
}

.apTimeline .react-calendar-timeline .rct-horizontal-lines .firstchild,
.apTimeline .react-calendar-timeline .rct-horizontal-lines .lastchild { position: relative; }

.apTimeline .react-calendar-timeline .rct-horizontal-lines .firstchild:before ,
.apTimeline .react-calendar-timeline .rct-horizontal-lines .lastchild:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 20px;
	background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
	opacity: 0.2;
	pointer-events: none;
}
.apTimeline .react-calendar-timeline .rct-horizontal-lines .lastchild:before { height: 5px; }

.apTimeline .react-calendar-timeline .rct-horizontal-lines .lastchild:before {
	top: auto;
	bottom: 0;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

.apTimeline .react-calendar-timeline .rct-sidebar-header {
	background: transparent;
}
.apTimeline .react-calendar-timeline .rct-header .rct-label-group {
	background: #0099CC;
}

.apTimeline .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd,
.apTimeline .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
	background: transparent;
}

.apTimeline .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.apTimeline .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
	border-color: #eee;
}

.apTimeline .react-calendar-timeline .rct-vertical-lines .rct-vl {
	border-color: #eee;
}

.apTimeline .react-calendar-timeline .rct-header .rct-label {
	background: #f0f0f0;
}

.apTimeline .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, 
.apTimeline .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
	background-color: rgba(0,0,0,0.1);
}
.apTimeline .react-calendar-timeline .rct-vertical-lines .rct-vl.outofbounds {
	background-color: rgba(0,0,0,0.3);
	border-color: rgba(0,0,0,0.2);
}
.apTimeline .react-calendar-timeline .rct-items .rct-item { background: #0099CC; border: 1px solid rgba(0,0,0,0.2); }
.apTimeline .react-calendar-timeline .rct-items .rct-item.status-warning { background: #FF8800; }
.apTimeline .react-calendar-timeline .rct-items .rct-item.status-error { background: #ff4444; }

.apTimeline .react-calendar-timeline .rct-items .rct-item .itemContent {
	padding: 0 1em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	-webkit-transition: opacity 0.6s ease;
	   -moz-transition: opacity 0.6s ease;
	    -ms-transition: opacity 0.6s ease;
	     -o-transition: opacity 0.6s ease;
	        transition: opacity 0.6s ease;
}

.apTimeline .react-calendar-timeline .rct-items .rct-item svg.moveIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	margin: -10px 0 0 -10px;
	opacity: 0;
	fill: #00695c;

	-webkit-transition: opacity 0.6s ease;
	   -moz-transition: opacity 0.6s ease;
	    -ms-transition: opacity 0.6s ease;
	     -o-transition: opacity 0.6s ease;
	        transition: opacity 0.6s ease;
}

.apTimeline .react-calendar-timeline .rct-items .rct-item .resizeHandle {
	background: #00695c;
	opacity: 0;

	-webkit-transition: opacity 0.6s ease;
	   -moz-transition: opacity 0.6s ease;
	    -ms-transition: opacity 0.6s ease;
	     -o-transition: opacity 0.6s ease;
	        transition: opacity 0.6s ease;
}

.apTimeline .react-calendar-timeline .rct-items .rct-item .resizeHandle:after {
	content: '||';
	position: absolute;
	top: 50%;
	left: 50%;
	color: #2BBBAD;
	-webkit-transform: translate(-50%,-50%);
	   -moz-transform: translate(-50%,-50%);
	    -ms-transform: translate(-50%,-50%);
	     -o-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
}

.apTimeline .react-calendar-timeline .rct-items .rct-item.selected {
	background: #2BBBAD !important;
	border-color: #00695c !important;
}

.apTimeline .react-calendar-timeline .rct-items .rct-item.selected .itemContent { opacity: 0.5; }
.apTimeline .react-calendar-timeline .rct-items .rct-item.selected svg.moveIcon { opacity: 1; }
.apTimeline .react-calendar-timeline .rct-items .rct-item.selected .resizeHandle { opacity: 1; }

.apTimeline.selectMode .react-calendar-timeline .rct-items .rct-item { opacity: 0.5; }
.apTimeline.selectMode .react-calendar-timeline .rct-items .rct-item.selected,
.apTimeline.selectMode .react-calendar-timeline .rct-items .rct-item.selectedSubitem { opacity: 1; }
.apTimeline .react-calendar-timeline .TodayMarker {
	background: #f00 !important;
}

.apTimeline .react-calendar-timeline .rct-infolabel {
	position: fixed;
	left: auto;
	top: auto;
	right: 1em;
	bottom: 1em;
	background: rgba(0,0,0,0.8);
	color: #ccc;
	padding: 0.5em;
	font-size: 14px;
	border-radius: 5px;
	z-index: 999;
}

.apTimeline .react-calendar-timeline .side-row {
	position: relative;
	margin: 0;
	font-size: 14px;
	border-left: 3px solid #ccc;
}

/*
.apTimeline .react-calendar-timeline .side-row.resources {
	background: #4B515D;
	color: #fff;
	padding: 0 0.5em;
	border-left-color: #4B515D;
}

.apTimeline .react-calendar-timeline .rct-horizontal-lines .resources {
	background: rgba(0,0,0,0.2);
}

.apTimeline .react-calendar-timeline .rct-items .rct-item.resource {
	text-align: center;
}

.apTimeline .react-calendar-timeline .rct-items .rct-item.resource .bar {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0;
	background: #333;
}
.apTimeline .react-calendar-timeline .rct-items .rct-item.resource .bar.current { background: #0099CC; }
*/

.apTimeline .react-calendar-timeline .rct-sidebar .rct-sidebar-row .side-row.haveChilds {
	padding-right: 25px;
}

.apTimeline .react-calendar-timeline .rct-sidebar .rct-sidebar-row .side-row.haveChilds.open {
	border-left-color: #33b5e5;
}

.apTimeline .react-calendar-timeline .rct-sidebar .rct-sidebar-row .side-row svg.arrow {
	position: absolute;
	top: 50%;
	right: 5px;
	width: 16px;
	height: 16px;
	margin-top: -8px;

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;
}

.apTimeline .react-calendar-timeline .rct-sidebar .rct-sidebar-row .side-row.open svg.arrow {
	-webkit-transform: rotate(-90deg);
	   -moz-transform: rotate(-90deg);
	    -ms-transform: rotate(-90deg);
	     -o-transform: rotate(-90deg);
	        transform: rotate(-90deg);
}

.apTimeline .react-calendar-timeline .rct-sidebar .rct-sidebar-row .side-row .title {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}