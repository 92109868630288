
#storageMassImport .fileSelectContainer {
    margin-bottom: 0.5em;
}

#storageMassImport .filterContainer {
    margin-bottom: 1em;
}

#storageMassImport table.componentsTable {
    width: 100%;
    background-color: #fff;
}

#storageMassImport table.componentsTable th {
    /* padding-bottom: 0.5em; */
    /* padding-right: 0.2em; */

    padding: 0.5em 0.2em;

}

#storageMassImport table.componentsTable th .headerSelected svg {
    width: 12px;
    height: 12px;
}

#storageMassImport table.componentsTable th .headerSelected .headerSelectedText {
    margin-left:  4px;
}

#storageMassImport table.componentsTable th .input-menu {
    width: 100%;
    margin: 0;
    padding: 0 30px 0 2px;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    line-height: 30px;
}

#storageMassImport table.componentsTable th .input-menu-button {
    position: absolute;
    top: 0;
    height: 100%;
    right: 2px;
}

#storageMassImport table.componentsTable td {
    padding-left:  0.2em;
    padding-right: 0.2em;
}

#storageMassImport table.componentsTable td.otherTd input {
    text-align: right;
    padding-right: 40px;
}

#storageMassImport table.componentsTable .relatedComponent input {
    padding-right: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#storageMassImport table.componentsTable .rowNumber {
}

#storageMassImport table.componentsTable .relatedComponent {
    min-width: 300px;
}

#storageMassImport table.componentsTable .fixed {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}

#storageMassImport table.componentsTable .status {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

#storageMassImport table.componentsTable td.relatedComponentMenu {
    text-align: center;
}
#storageMassImport table.componentsTable td.relatedComponentMenu .apDropdown .apDropdownMenu {
    width: 250px;
}

.massImportCustomFooter .apDropdown .apDropdownMenu { width: 300px; }
.massImportCustomFooter .apButton { margin: 0; }

.massImportCustomFooter {
    padding: 1em;
    margin: 1em 0 0;
    border-top: 2px solid #eee;
    background: #fff;
    position: relative;
}

.ApStickyBar.sticky .massImportCustomFooter { border-top-color: #33b5e5; }

.massImportCustomFooter .apButtonGroup {
    position: absolute;
    top: 50%;
    right: 1em;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.massImportCustomFooter .apButtonGroup .stat { margin: 0 1em; }
.massImportCustomFooter .apButtonGroup .stat svg { margin: -3px 0 0 5px; }

.massImportCustomFooter .apButtonGroup .divider {
    display: inline-block;
    width: 1px;
    height: 30px;
    background: #ccc;
    margin: 0 1em;
    vertical-align: middle;
}

.massImportCustomFooter .stat {
    display: inline-block;
    margin: 0 2em 0 0;
    position: relative;
}

.massImportCustomFooter .stat.empty {
    font-size: 14px;
    color: #999;
}

.massImportCustomFooter .stat svg {
    width: 20px;
    height: 20px;
    margin: -3px 5px 0 0;
    vertical-align: middle;
}
.massImportCustomFooter .stat.empty svg { fill: #ccc; }
.massImportCustomFooter .stat.success svg { fill: #4caf50; }
.massImportCustomFooter .stat.warning svg { fill: #FF8800; }
.massImportCustomFooter .stat.error svg { fill: #ff4444; }
.massImportCustomFooter .stat.info svg { fill: #0099CC; }
.massImportCustomFooter .stat small {color: #999; }

.massImportCustomFooter .stat .apBadge {
    position: absolute;
    top: -5px;
    left: -5px;
    pointer-events: none;
}

#storageMassImport .input-menu {
    width: 100%;
    margin: 0;
    padding: 0 30px 0 2px;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    line-height: 30px;
    height: 32px;
}

#storageMassImport .input-menu-button {
    position: absolute;
    top: 0;
    height: 100%;
    right: 2px;
}

#storageMassImport .headerLabel {
    max-width: 100%;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
}

#storageMassImport .apDropdown .action {
    padding: 0;
    padding-left: 36px;

}
#storageMassImport .componentsTable .apDropdown.open .apDropdownMenu {
    max-height: 50vh;
    overflow: auto;
}

#storageMassImport .rt-resizable-header-content {
    overflow: visible;

}

#storageMassImport .apReactTable .ReactTable .rt-td.otherCell {
    position: relative;
    padding: 0;
}
#storageMassImport .otherCell .other  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 0.5em;
}
#storageMassImport .otherCell .other.noUpdate {
    /* background-color: #eee; */
    color: #ddd;
}

#storageMassImport .apModalWrapper .apModal.linkModal .apModalBody {
    overflow: visible;
}

#storageMassImport .linkModal .option {
    position: relative;

    padding: 1em;
    margin: 1em 0;

    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    background: #f9f9f9;
    cursor: pointer;

}
#storageMassImport .linkModal .option:hover {
    background: #FFF;
    border-bottom-color: #33b5e5;
}

#storageMassImport .linkModal .option.selected {
    background: #fff;
}

#storageMassImport .linkModal .option .iconContainer {
    position: absolute;
    left: 1em;
    top: 50%;
}
#storageMassImport .linkModal .option .iconContainer svg {
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}
#storageMassImport .linkModal .optionText {
    margin-left: 60px;
    border-left: 1px solid #ccc;
    padding-left: 20px;
}
#storageMassImport .linkModal .option .optionText.noLink,
#storageMassImport .linkModal .option .optionText.removeContainer,
#storageMassImport .linkModal .option .optionText.newUserContainer {
    font-weight: 700;
    font-size: 1.2em;
    color: #ccc;

}
#storageMassImport .linkModal .option.selected .optionText.noLink,
#storageMassImport .linkModal .option.selected .optionText.removeContainer,
#storageMassImport .linkModal .option.selected .optionText.newUserContainer {
    color: #33b5e5;
}

#storageMassImport.linkModal .selectContainer .apInputBlock {
    margin: 0;
}



#storageMassImport .apReactTable .ReactTable .noComponent {
    background: #ffe0b2;
    color: #e65100;
}

#storageMassImport .apReactTable .ReactTable .change {
    background: #e3f2fd;
    color: #0099CC;
}

#storageMassImport .apReactTable .ReactTable .noCol {

    color: #aaa;
    background: #ddd;
}
#storageMassImport .apReactTable .ReactTable .noChange {

    color: #aaa;
}

#storageMassImport .apReactTable .ReactTable .invalid {
    background: #ffcdd2;
    color: #CC0000;

}
#storageMassImport .apReactTable .ReactTable .newComponent {
    background: #dcedc8;
    color: #007E33;
}

#storageMassImport .apReactTable .ReactTable .toNull {
    background: #ffe0b2;
    color: #e65100;
}

#storageMassImport .apReactTable .ReactTable .rowNumber {
    text-align: right;
    font-weight: 700;
}

#storageMassImport .apReactTable .ReactTable .linkTd svg {
    height: 18px;
    width: 18px;
    color: #CC0000;
}

#storageMassImport .apReactTable .ReactTable .noThisType {
    color: #bbb;
    background: #eee;
}


#storageMassImport .orange {
    color: #e65100;
}
