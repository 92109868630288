

.apReactTable .ReactTable .customLoader {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.8);

	pointer-events: none;
	opacity: 0;

	-webkit-transition: opacity 0.4s ease;
	   -moz-transition: opacity 0.4s ease;
	    -ms-transition: opacity 0.4s ease;
	     -o-transition: opacity 0.4s ease;
	        transition: opacity 0.4s ease;
}

.apReactTable .ReactTable .rt-th.overflowable,
.apReactTable .ReactTable .rt-td.overflowable {
	overflow: visible;
}
.apReactTable .ReactTable .rt-th.overflowableOnHover:hover,
.apReactTable .ReactTable .rt-td.overflowableOnHover:hover {
	overflow: visible;
}

.apReactTable .ReactTable .rt-th .optionSwitch {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 55px 0 0.5em;
	margin: 0;
	font-size: 14px;
}

.apReactTable .ReactTable .rt-th .optionSwitch .apSwitchWrapper {
	position: absolute;
	top: 50%;
	right: 0.5em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}


.apReactTable .ReactTable .customLoader.show {
	pointer-events: auto;
	opacity: 1;
}


.apReactTable .ReactTable .customNoRows {
	position: absolute;
	z-index: 9;
	top: 80px;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
}
.apReactTable.filtersShowing .ReactTable .customNoRows { top: 120px; }

.apReactTable .ReactTable .customNoRows .message {
	position: absolute;
	top: 50%;
	left: 5%;
	width: 90%;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

/* This will clip the overflowing content after resizing 
columns manually, but it will also clip possible 
dropdown actions from the last rows! */
.apReactTable.clipContent { overflow: hidden; }

.apReactTable,
.apReactTable .ReactTable,
.apReactTable .ReactTable .rt-table {
    width: max-content;
    min-width: 100%;
    background-color: white;
}

.apReactTable .ReactTable .rt-table {
	overflow: visible;
}

/* Allows dropdowns (etc. absolute elements) overflow outside cell and table  */
/* .apReactTable .ReactTable .rt-table, */
.apReactTable .ReactTable .rt-tbody,
.apReactTable .ReactTable .rt-th.multiselect,
.apReactTable .ReactTable .rt-th.rowAction,
.apReactTable .ReactTable .rt-td.rowAction {
	overflow: visible;
}


/* Modify default resizer between header columns */
.apReactTable .ReactTable .rt-th .rt-resizer { 
	width: 20px;
	right: -10px;
	text-align: center;
	color: #999;
}
.apReactTable .ReactTable .rt-th .rt-resizer:hover:after { content: '||'; }
.apReactTable .ReactTable .rt-th .rt-resizer:hover {
	background: rgba(250,250,250,0.8);
} 


/* Removes weird blue outline from header cells */
.apReactTable .ReactTable .rt-th:focus {
	outline: none
}

.apReactTable .ReactTable .rt-th {
	padding: 0 0.5em;
	height: 40px;
	line-height: 40px;
	text-align: left;
}

.apReactTable .ReactTable .rt-thead.-header {
	background-color: #0099cc;
	color: #fff;
	font-weight: bold;
}

.apReactTable .ReactTable .rt-td {
	padding: 5px 0.5em;
	line-height: 1.5em;
}

.apReactTable .ReactTable .rt-th.align-left,
.apReactTable .ReactTable .rt-td.align-left {   text-align: left; }
.apReactTable .ReactTable .rt-th.align-center,
.apReactTable .ReactTable .rt-td.align-center { text-align: center; }
.apReactTable .ReactTable .rt-th.align-right,
.apReactTable .ReactTable .rt-td.align-right {  text-align: right; }

.apReactTable .ReactTable .rt-td.clickable { cursor: pointer; }
.apReactTable .ReactTable .rt-td.clickable:hover { color: #aaa; }

.apReactTable .ReactTable .rt-tbody .rt-tr-group {
	border: none;
}

.apReactTable .ReactTable .rt-tbody .rt-td {
	border: none;
}

.apReactTable .rt-tr-group:nth-child(2n) {
	background-color: hsl(194, 100%, 95%);
}

.apReactTable .rt-tr-group:hover {
	background-color: hsl(194, 100%, 93%);
	color: #333;
}

.apReactTable .ReactTable .rt-tr.-padRow {
	background: transparent;
}

.apReactTable .ReactTable .rt-thead.-filters {
	background-color: #5e5e5e;
	color: #ccc; 
	position: relative;
}

.apReactTable .ReactTable .rt-thead.-filters:after {
	bottom: 100%;
	right: 15px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 187, 51, 0);
	border-bottom-color: #666;
	border-width: 10px;
	margin-left: -10px;
}

.apReactTable .ReactTable .rt-thead.-filters .rt-th {
	padding: 5px 0.5em;
	height: auto;
	line-height: 1.2em;
}

.apReactTable .ReactTable .rt-thead.-filters .rt-th.rowAction { padding: 0;}

.apReactTable .ReactTable .rt-thead.-filters .rt-th input.customFilter,
.apReactTable .ReactTable .rt-thead.-filters .rt-th select.customFilter {
	width: 100%;
	background: #ededed;
	color: #000;
	height: 32px;
	border: none;
	border-bottom: 2px solid #999;
	outline: none;
}
.apReactTable .ReactTable .rt-thead.-filters .rt-th input.customFilter:focus,
.apReactTable .ReactTable .rt-thead.-filters .rt-th select.customFilter:focus { background: #f7f7f7; border-bottom-color: #ccc; }

.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.range .ApRangeSlider {
	padding: 0;
	margin: 0.2em 1em;
}
.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.range .rangeValue {
	text-align: center;
	color: #fff;
	font-size: 14px;
	line-height: 1em;
	pointer-events: none;
}

.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.toggle {
	width: 100%;
	height: 100%;
	background: #555;
	border-bottom: 2px solid #999;
	color: #aaa;
	cursor: pointer;
	position: relative;
}
.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.toggle.true { border-bottom-color: #007E33; background: #4caf50; color: #fff; }
.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.toggle.false { border-bottom-color: #CC0000; background: #ff4444; color: #fff; }

.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.toggle svg {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
}

.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.apSelect .labels label {
    font-weight: normal;
}


.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.apSelect input:focus { background: #eee; border-bottom-color: #ccc; }
.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.apSelect input {
    background: #ddd;
    border-radius: 0;
    border-bottom: 2px solid #999;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 45px;
    cursor: pointer;

}
.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.apSelect .apInputBlock {
    margin: 0;
}

.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.apSelect  .results {
    color: #444444;
}

.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.apSelect  .clear {
    position: absolute;
    top: 50%;
    right: 30px;
    cursor: pointer;
    opacity: 0.9;
    color: #444444;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}
.apReactTable .ReactTable .rt-thead.-filters .rt-th .customFilter.apSelect  .arrow {
    /* color: red; */
    color: #444444;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}




.apReactTable .ReactTable .rt-th.multiselect,
.apReactTable .ReactTable .rt-td.multiselect,
.apReactTable .ReactTable .rt-th.rowAction,
.apReactTable .ReactTable .rt-td.rowAction { padding: 0; }

.apReactTable .ReactTable .rt-th.multiselect > div, 
.apReactTable .ReactTable .rt-th.rowAction > div { height: 100%; }

.apReactTable .ReactTable .rt-th.multiselect .inputWrapper,
.apReactTable .ReactTable .rt-td.multiselect .inputWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border-left: 5px solid #eee;
}



.apReactTable .ReactTable .rt-th .dropPanelWrapper {
	width: 100%;
	height: 100%;
	position: relative;
	text-align: left;
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper .dropPanel {
	position: absolute;
	z-index: 11;
	top: 100%;
	left: 0;
	width: 300px;
	height: auto;
	background: #fff;

	border-top: 3px solid #33b5e5;
	box-shadow: 0 3px 10px rgba(0,0,0,0.1);

	opacity: 0;
	pointer-events: none;
	-webkit-transform: translateY(-10px);
	   -moz-transform: translateY(-10px);
	    -ms-transform: translateY(-10px);
	     -o-transform: translateY(-10px);
	        transform: translateY(-10px);

	-webkit-transition: opacity 0.4s ease, transform 0.4s ease;
	   -moz-transition: opacity 0.4s ease, transform 0.4s ease;
	    -ms-transition: opacity 0.4s ease, transform 0.4s ease;
	     -o-transition: opacity 0.4s ease, transform 0.4s ease;
	        transition: opacity 0.4s ease, transform 0.4s ease;
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper.open .dropPanel {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0);
	   -moz-transform: translateY(0);
	    -ms-transform: translateY(0);
	     -o-transform: translateY(0);
	        transform: translateY(0);
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper .dropPanel:after {
	bottom: 100%;
	left: 35px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(51, 181, 229, 0);
	border-bottom-color: #33b5e5;
	border-width: 10px;
	margin-left: -10px;
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper .dropPanel .scroller {
	max-height: 250px;
	overflow: auto;
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper.open .apSimpleButton {
    box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
    background: rgba(0,0,0,0.05);
}


.apReactTable .ReactTable .rt-th .dropPanelWrapper.customColumns .dropPanel { left: auto; right: 0; }
.apReactTable .ReactTable .rt-th .dropPanelWrapper.customColumns .dropPanel:after { left: auto; right: 15px; }

.apReactTable .ReactTable .rt-th .dropPanelWrapper .dropPanel .info {
	border-bottom: 1px solid #eee;
	padding: 0 1em;
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper.multiselect .dropPanel .action {
    padding: 0.5em 0.5em 0.5em 36px;
    position: relative;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper.multiselect .dropPanel .action:hover { background: #f9f9f9; }

.apReactTable .ReactTable .rt-th .dropPanelWrapper.multiselect .dropPanel .action.disabled {
    color: #ccc;
    cursor: not-allowed;
}
.apReactTable .ReactTable .rt-th .dropPanelWrapper.multiselect .dropPanel .action.disabled:hover { background: none; }

.apReactTable .ReactTable .rt-th .dropPanelWrapper.multiselect .dropPanel .action svg {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 16px;
    height: 16px;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper.multiselect .dropPanel .divider {
	border-bottom: 1px solid #eee;
	height: 0.5em;
	margin-bottom: 0.5em;
}

.apReactTable .ReactTable .rt-th .dropPanelWrapper.customColumns .dropPanel .customColumnItem {
	padding: 0 1em;
	font-size: 14px;
	line-height: 35px;
    position: relative;
}
.apReactTable .ReactTable .rt-th .dropPanelWrapper.customColumns .dropPanel .customColumnItem.groupHeader {
	color: #999;
	padding-top: 0.5em;
	font-size: 80%;
	line-height: 1.5em;
}
.apReactTable .ReactTable .rt-th .dropPanelWrapper.customColumns .dropPanel .customColumnItem.indent {
	padding-left: 2em;
}



/* Match default dropdown styles to row width */
.apReactTable .ReactTable .rt-td.rowAction .apDropdown,
.apReactTable .ReactTable .rt-td.rowAction .apDropdown .apDropdownButton {
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
}
.apReactTable .ReactTable .rt-td.rowAction .apDropdown .apDropdownMenu:after { right: 15px; }
.apReactTable .ReactTable .rt-td.rowAction .apDropdown .apDropdownButton svg {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
}
/* Match default dropdown hover/open styles to rest of the table */
.apReactTable .ReactTable .rt-td.rowAction .apDropdown.open .apDropdownButton,
.apReactTable .ReactTable .rt-td.rowAction .apDropdown .apDropdownButton:hover { background: rgba(0,0,0,0.025); }
.apReactTable .ReactTable .rt-td.rowAction .apDropdown.open .apDropdownButton svg,
.apReactTable .ReactTable .rt-td.rowAction .apDropdown .apDropdownButton:hover svg { fill: #33b5e5; }



.apReactTable .ReactTable .rt-th.multiselect .inputWrapper { border-left-color: #e0f2f1; }

.apReactTable .ReactTable .rt-th.multiselect .inputWrapper:hover,
.apReactTable .ReactTable .rt-td.multiselect .inputWrapper:hover { border-left-color: #ccc; }

.apReactTable .ReactTable .rt-tr.selected {
	background: #e1f5fe;
	color: #03a9f4;
}

.apReactTable .ReactTable .rt-tr.selected .rt-td.multiselect .inputWrapper { border-left-color: #03a9f4; }

.apReactTable .ReactTable .rt-th.multiselect .inputWrapper input,
.apReactTable .ReactTable .rt-td.multiselect .inputWrapper input,
.apReactTable .ReactTable .rt-th.multiselect .multiselectFiltered input {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0;
	padding: 0;
	pointer-events: none;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.apReactTable .ReactTable .rt-th.multiselect .inputWrapper .apBadge {
	position: absolute;
	top: 5px;
	right: 5px;
}

.apReactTable .ReactTable .customPagination {
	width: 100%;
	height: 50px;
	position: relative;
	border-top: 1px solid rgba(0,0,0,0.05);
	box-shadow: 0 -2px 15px 0 rgba(0,0,0,0.15)
}

.apReactTable .ReactTable .customPagination .settings,
.apReactTable .ReactTable .customPagination .prev,
.apReactTable .ReactTable .customPagination .next {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50px;
	border-right: 1px solid rgba(0,0,0,0.05);
}
.apReactTable .ReactTable .customPagination .prev { left: 50px; z-index: 9; }
.apReactTable .ReactTable .customPagination .next { left: auto; border-right: none; right: 0; border-left: 1px solid rgba(0,0,0,0.05); z-index: 8; }

.apReactTable .ReactTable .customPagination .pages,
.apReactTable .ReactTable .customPagination .mobilePages {
	text-align: center;
	padding: 15px 70px 8px 120px;
}

.apReactTable .ReactTable .customPagination .mobilePages {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	display: none;
}

.apReactTable .ReactTable .customPagination .pages .page {
	display: inline-block;
	position: relative;
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.apReactTable .ReactTable .customPagination .pages .page .ball {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	max-width: 12px;
	max-height: 12px;
	border-radius: 999px;
	background: #ccc;

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;

	-webkit-transform: translate(-50%, -50%) scale(0.6);
	   -moz-transform: translate(-50%, -50%) scale(0.6);
	    -ms-transform: translate(-50%, -50%) scale(0.6);
	     -o-transform: translate(-50%, -50%) scale(0.6);
	        transform: translate(-50%, -50%) scale(0.6);
}

.apReactTable .ReactTable .customPagination .pages .page:hover .ball,
.apReactTable .ReactTable .customPagination .pages .page.current .ball { 
	-webkit-transform: translate(-50%, -50%) scale(1);
	   -moz-transform: translate(-50%, -50%) scale(1);
	    -ms-transform: translate(-50%, -50%) scale(1);
	     -o-transform: translate(-50%, -50%) scale(1);
	        transform: translate(-50%, -50%) scale(1);
}

.apReactTable .ReactTable .customPagination .pages .page.current .ball { background: #33b5e5; }

.apReactTable .ReactTable .customPagination .pages .page .text { 
	position: absolute;
	bottom: calc( 100% + 5px );
	left: 50%;

	padding: 0 1em;
	line-height: 1.5em;
	color: #fff;
	background: #000;

	opacity: 0;
	pointer-events: none;

	-webkit-transform: translate(-50%, 10px);
	   -moz-transform: translate(-50%, 10px);
	    -ms-transform: translate(-50%, 10px);
	     -o-transform: translate(-50%, 10px);
	        transform: translate(-50%, 10px);

	-webkit-transition: transform 0.4s ease, opacity 0.4s ease;
	   -moz-transition: transform 0.4s ease, opacity 0.4s ease;
	    -ms-transition: transform 0.4s ease, opacity 0.4s ease;
	     -o-transition: transform 0.4s ease, opacity 0.4s ease;
	        transition: transform 0.4s ease, opacity 0.4s ease;
}
.apReactTable .ReactTable .customPagination .pages .page:hover .text { 
	opacity: 1;

	-webkit-transform: translate(-50%, 0);
	   -moz-transform: translate(-50%, 0);
	    -ms-transform: translate(-50%, 0);
	     -o-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
}

.apReactTable .ReactTable .customPagination .pages .page:hover .text:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-top-color: #000;
	border-width: 5px;
	margin-left: -5px;
}

.apReactTable .ReactTable .customPagination .pages .pageSelector select {
	background: #fff;
	border: 1px solid #ccc;
	margin: 0;
	padding: 0;
	border-radius: none;
}

.apReactTable .ReactTable .customPagination .settings .paginationSettings {
	position: absolute;
	bottom: 100%;
	left: 0;
	width: 70vw;
	max-width: 320px;
	background: #fff;
	border-top: 3px solid #33b5e5;

	box-shadow: 0 5px 20px rgba(0,0,0,0.2);

	pointer-events: none;
	opacity: 0;

	-webkit-transform: translateY(-20px);
	   -moz-transform: translateY(-20px);
	    -ms-transform: translateY(-20px);
	     -o-transform: translateY(-20px);
	        transform: translateY(-20px);

	-webkit-transition: transform 0.4s ease, opacity 0.4s ease;
	   -moz-transition: transform 0.4s ease, opacity 0.4s ease;
	    -ms-transition: transform 0.4s ease, opacity 0.4s ease;
	     -o-transition: transform 0.4s ease, opacity 0.4s ease;
	        transition: transform 0.4s ease, opacity 0.4s ease;
}

.apReactTable .ReactTable .customPagination .settings.open .paginationSettings { 
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0);
	   -moz-transform: translateY(0);
	    -ms-transform: translateY(0);
	     -o-transform: translateY(0);
	        transform: translateY(0);
}

.apReactTable .ReactTable .customPagination .pageSelectorInput {
    text-align: right;
    width: 100px;
    line-height: 0.8em;

}


.apReactTable .ReactTable .customPagination .settings .paginationSettings:after {
	top: 100%;
	left: 25px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255,255,255, 0);
	border-top-color: #fff;
	border-width: 10px;
	margin-left: -10px;
}

@media all and (max-width: 800px) {

	.apReactTable .ReactTable .customPagination .pages {
		padding: 12px 70px 6px 120px;
	}

	.apReactTable .ReactTable .customPagination .pages .page {
		width: 15px;
		height: 15px;
		cursor: pointer;
	}
	.apReactTable .ReactTable .customPagination .pages .page .ball {
		max-width: 8px;
		max-height: 8px;
	}

}

@media all and (max-width: 600px) {

	.apReactTable .ReactTable .customPagination .pages { display: none; }
	.apReactTable .ReactTable .customPagination .mobilePages { display: block; }

}



.apReactTable .ReactTable .rt-thead.-filters .rt-th.selectProjectType .customFilter.apSelect input {
	background-color: white;
}
#selectProjectType .apSelect{
	display:inline-block;
	margin-right: 1em;
}
