#pageProjectsForm .pageBackButton {
    margin-bottom: 0.5em;
}

#pageProjects .itemsCell {
	white-space: normal;
}

#pageProjects .itemsCell .item {
	display: inline-block;
	border: 1px solid #ccc;
	margin: 0 0.2em 0.2em 0;
	padding: 0 0.5em;
	font-size: 14px;
}

#pageProjects .itemsCell .item svg {
	width: 16px;
	height: 16px;
	margin: -2px 5px 0 0;
}

#pageProjects .iconHeader {
	text-align: center;
}

#pageProjects .iconHeader svg {
	width: 20px;
	height: 20px;
}

#pageProjects .customerCell small { color: #999; }
#pageProjects .customerCell small svg {
	width: 16px;
	height: 16px;
	margin-right: 2px;
}

#pageProjects .continuousCell svg {
	width: 20px;
	height: 20px;
	fill: #4caf50;
}

#pageProjects .listHidden {
    display: none;
}

#projectManagement #projectPayment .apSelect .results {
	position: absolute;
}