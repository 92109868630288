.ProjectInfoBar {
    border-bottom: 1px solid #ccc;
    min-width: 1100px;
}

.ProjectInfoBar .red { color: #b71c1c }
.ProjectInfoBar .green { color: #4caf50 }

.ProjectInfoBar .column {
    display: inline-block;
    vertical-align: top;
    margin-right: 3em;

}
.ProjectInfoBar .column .title {
    text-align: right;
    padding-right: 0.5em;
    padding-left: 0.5em;
    color: #0099CC;
    font-weight: 700;
    font-size: 0.8em;
}
.ProjectInfoBar .column .value {
    /* outline: 1px solid green; */
    /* font-weight: 700; */
    font-size: 0.8em;
}
