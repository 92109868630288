.block.timer .topModal .stopwatch {
	position: relative;
}

.block.timer .topModal .stopwatch .chart {
	width: 100%;
	position: relative;
}

.block.timer .topModal .stopwatch .chart svg {
	display: block;
	width: 80%;
	margin: 0 auto;
}

.block.timer .topModal .stopwatch .chart .numbers {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.block.timer.playing .topModal .stopwatch .chart .numbers .number,
.block.timer.playing .topModal .stopwatch .chart .numbers .dots { color: #007E33; }
.block.timer.paused .topModal .stopwatch .chart .numbers .number,
.block.timer.paused .topModal .stopwatch .chart .numbers .dots { color: #FF8800; }

.block.timer .topModal .stopwatch .chart .numbers .info {
	font-size: 14px;
	height: 40px;
	line-height: 40px;
}

.block.timer .topModal .stopwatch .chart .numbers .number {
	display: inline-block;
	margin: 0 5px;
}
.block.timer .topModal .stopwatch .chart .numbers .number strong,
.block.timer .topModal .stopwatch .chart .numbers .number small {
	display: block;
	line-height: 1em;
}

.block.timer .topModal .stopwatch .chart .numbers .number strong {
	font-family: 'Roboto-Mono', Arial, sans-serif;
	font-size: 26px;
}

.block.timer .topModal .stopwatch .chart .numbers .dots {
	font-size: 16px;
	vertical-align: top;
}

.block.timer .topModal .stopwatch.playing .chart .numbers .dots {
	-webkit-animation: blinkStopwatchDots 1s infinite;
  	   -moz-animation: blinkStopwatchDots 1s infinite;
  	   	 -o-animation: blinkStopwatchDots 1s infinite;
  	  	-ms-animation: blinkStopwatchDots 1s infinite;
  	  	    animation: blinkStopwatchDots 1s infinite;
}


@keyframes blinkStopwatchDots {
	0% { opacity: 1; }
	50% { opacity: 1; }
	51% { opacity: 0; }
	100% { opacity: 0; }
}

@-moz-keyframes blinkStopwatchDots {
	0% { opacity: 1; }
	50% { opacity: 1; }
	51% { opacity: 0; }
	100% { opacity: 0; }
}

@-webkit-keyframes blinkStopwatchDots {
	0% { opacity: 1; }
	50% { opacity: 1; }
	51% { opacity: 0; }
	100% { opacity: 0; }
}

@-o-keyframes blinkStopwatchDots {
	0% { opacity: 1; }
	50% { opacity: 1; }
	51% { opacity: 0; }
	100% { opacity: 0; }
}

@-ms-keyframes blinkStopwatchDots {
	0% { opacity: 1; }
	50% { opacity: 1; }
	51% { opacity: 0; }
	100% { opacity: 0; }
}
