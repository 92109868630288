
#HoursApproval {
	position: relative;
}

#HoursApproval .filterBar {
	width: 100%;
	height: 50px;
	background: #f9f9f9;
	border-bottom: 3px solid #ccc;
	position: relative;
	z-index: 1;

	-webkit-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
	   -moz-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
	    -ms-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
	     -o-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
	        transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
}

#HoursApproval .ApStickyBar.sticky .filterBar {
	border-bottom-color: #33b5e5;
	box-shadow: 0 3px 10px rgba(0,0,0,0.2);
}

#HoursApproval .filterBar:after {
	content: '';
	display: block;
	clear: both;
}

#HoursApproval .filterBar .filter {
	float: left;
	width: auto;
	height: 100%;
	border-right: 1px solid #ccc;
	position: relative;
}

#HoursApproval .filterBar .filter.title {
	line-height: 50px;
	font-size: 14px;
	padding: 0 1em;
}

#HoursApproval .filterBar .filter.title .apBadge{
	position: absolute;
	top: 5px;
	left: 5px;
	background: #FF8800;
}

#HoursApproval .filterBar .filter .button {
	width: 200px;
	padding: 0 1em 0 36px;
	line-height: 50px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#HoursApproval .filterBar .filter .button svg {
	position: absolute;
	top: 50%;
	left: 10px;
	width: 16px;
	height: 16px;
	margin-top: -8px;
}

#HoursApproval .filterBar .filter .box {
	position: absolute;
	z-index: 5;
	top: calc( 100% + 10px );
	left: 0;
	width: 400px;
	height: auto;
	background: #fff;
	border-top: 3px solid #33b5e5;
	box-shadow: 0 3px 10px rgba(0,0,0,0.1);

	pointer-events: none;
	opacity: 0;
	-webkit-transform: translateY(10px);
	   -moz-transform: translateY(10px);
	    -ms-transform: translateY(10px);
	     -o-transform: translateY(10px);
	        transform: translateY(10px);

	-webkit-transition: opacity 0.4s ease, transform 0.4s ease;
	   -moz-transition: opacity 0.4s ease, transform 0.4s ease;
	    -ms-transition: opacity 0.4s ease, transform 0.4s ease;
	     -o-transition: opacity 0.4s ease, transform 0.4s ease;
	        transition: opacity 0.4s ease, transform 0.4s ease;
}

#HoursApproval .filterBar .filter.open .box {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translateY(0);
	   -moz-transform: translateY(0);
	    -ms-transform: translateY(0);
	     -o-transform: translateY(0);
	        transform: translateY(0);
}

#HoursApproval .filterBar .filter .box:after {
	bottom: 100%;
	left: 18px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #33b5e5;
	border-width: 10px;
	margin-left: -10px;
}

#HoursApproval .filterBar .filter .box .scroller {
	max-height: 50vh;
	overflow: auto;
}

#HoursApproval .filterBar .filter .box .item {
	min-height: 50px;
	border-bottom: 1px solid #eee;
	position: relative;
	padding: 0.5em;
	font-size: 16px;
	line-height: 1.2em;
}
#HoursApproval .filterBar .filter .box .item.selected { color: #33b5e5; }

#HoursApproval .filterBar .filter .box .item.user { padding-left: 50px }

#HoursApproval .filterBar .filter .box .item .image {
	position: absolute;
	top: 50%;
	left: 0;
	width: 40px;
	height: 40px;
	margin-top: -20px;
	border-radius: 999px;
	background-color: #f9f9f9;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border: 2px solid #ccc;
}

#HoursApproval .filterBar .filter .box .item.selected .image { border-color: #33b5e5; }

#HoursApproval .filterBar .filter .box .item .name small {
	font-size: 14px;
	line-height: 1em;
	color: #999;
}

#HoursApproval .filterBar .filter .box .item .count {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#HoursApproval .filterBar .apOptionBar {
	margin: 6px;
}

#HoursApproval .filterBar .selectedCount,
#HoursApproval .filterBar .apDropdown {
	position: absolute;
	top: 50%;
	right: 10px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#HoursApproval .filterBar .selectedCount { 
	right: 70px; 
	text-align: right;
	line-height: 1.2em;
}

#HoursApproval .guide {
	position: absolute;
	top: 160px;
	right: 0;
	text-align: center;
	padding: 0.5em 1em;
	font-size: 14px;
	line-height: 1.2em;
	background: #33b5e5;
	color: #fff;
}

#HoursApproval .guide:after {
	top: 100%;
	right: 90px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(51, 181, 229, 0);
	border-top-color: #33b5e5;
	border-width: 10px;
	margin-left: -10px;
}

#HoursApproval .loader {
	margin: 2em 0;
}

#HoursApproval .noRowsMessage {
	text-align: center;
	padding: 1em;
}
#HoursApproval .noRowsMessage svg {
	width: 32px;
	height: 32px;
}

#HoursApproval .link {
	text-decoration: underline;
	color: #999;
	cursor: pointer;
}

#HoursApproval .hiddenRowsMessage {
	text-align: center;
	margin: 1em 0;
	padding: 0.5em 1em;
	border-radius: 10px;
	border: 1px solid #ccc;
}
#HoursApproval .hiddenRowsMessage svg {
	margin: -3px 5px 0 0;
}


#HoursApproval .stats {
	margin: 0 0 1em;
	border-bottom: 1px solid #ccc;
}


#HoursApproval .stats .stat {
	margin: 0 0 0.5em;
}

#HoursApproval .stats .stat.waiting  { color: #666; }
#HoursApproval .stats .stat.approved { color: #007E33; }
#HoursApproval .stats .stat.rejected { color: #CC0000; }

#HoursApproval .stats .stat svg {
	width: 20px;
	height: 20px;
	margin: -3px 5px 0 0;
}

