
#PageUserWorkPermits .permit {
	width: 100%;
	overflow: auto;
	border: 1px solid #ccc;
	background: #fff;
	padding: 0.5em;
}

#PageUserWorkPermits .permit table {
	width: auto;
	min-width: 25%;
	max-width: 100%;
	background: #fff;
	font-size: 14px;
}

#PageUserWorkPermits .permit table th,
#PageUserWorkPermits .permit table td {
	text-align: center;
	border: 1px solid #eee;
	padding: 0em;
}

/* #PageUserWorkPermits .permit table thead th.permit,
#PageUserWorkPermits .permit table tbody td.permit {
	background: #fff;
	text-align: center;
	min-width: 100px;
	padding: 0 1em;
} */

/* #PageUserWorkPermits .permit table thead th.user,
#PageUserWorkPermits .permit table tbody td.user {
	text-align: center;
	min-width: 100px;
} */

#PageUserWorkPermits .infoBlocks div {
	display: inline-block;
}

#PageUserWorkPermits .infoBlock div {
	padding: 12px;
}


#PageUserWorkPermits .guides {
	margin: 0 0 1em;
}
#PageUserWorkPermits .guides .guide {
	display: inline-block;
	font-size: 14px;
	padding: 0 2em 0 0;
}
#PageUserWorkPermits .guides .guide svg {
	width: 20px;
	height: 20px;
	margin: -3px 3px 0;
}

#PageUserWorkPermits .permits {
	width: 100%;
	overflow: auto;
	border: 1px solid #ccc;
	background: #eee;
	padding: 0.5em;
}

#PageUserWorkPermits .permits.loading {
	opacity: 0.5;
	pointer-events: none;
}

#PageUserWorkPermits .permits table {
	display: block;
	overflow-x: scroll;
	overflow-y: scroll;
	width: auto;
	min-width: 100%;
	max-height: 60vh;
	min-height: 100%;
	background: #fff;
	font-size: 14px;
}

#PageUserWorkPermits .permits table th,
#PageUserWorkPermits .permits table td {
	border: 1px solid #eee;
	padding: 0.2em;
}

#PageUserWorkPermits .permitTable th.permit{
	position: sticky;
	top: 0;
	z-index: 6;
	background: #fff;
}

#PageUserWorkPermits .permitTable th.user{
	position: sticky;
	left: 0;
	z-index: 5;
	text-align: center;
	background: #fff;
}

#PageUserWorkPermits .permitTable td.user{
	z-index: 1;
}

#PageUserWorkPermits .permits table thead th.permit,
#PageUserWorkPermits .permits table tbody td.permit {
	text-align: left;
	min-width: 150px;
	padding: 0 1em;
}

#PageUserWorkPermits .permits table thead th.user,
#PageUserWorkPermits .permits table tbody td.user {
	text-align: center;
	min-width: 50px;
}

#PageUserWorkPermits .permits table tbody td.user .guide:hover {
	cursor: pointer;
	background: #dedede;
}

#PageUserWorkPermits .permits table thead th.user .name {
	font-size: 14px;
	line-height: 1.2em;
	padding: 0 0.5em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

#PageUserWorkPermits .permits table thead th.permit .onlyActiveSwitch {
	cursor: pointer;
	position: relative;
	padding: 0.5em 0.5em 0.5em 50px;
}

#PageUserWorkPermits .permits table thead th.permit .onlyActiveSwitch .apSwitchWrapper {
	position: absolute;
	top: 50%;
	left: 5px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#PageUserWorkPermits .permits table tbody tr.level0 {
	/*background: #f9f9f9;*/
	background: #fff;
	font-weight: bold;
}

#userPermitModal .apModalWrapper .apModal .apModalBody {
    max-height: none;
    overflow: visible;
}

#userPermitModal .filesTable {
	width: 100%;
}

#userPermitModal .filesTable tr {
    border-bottom: 1px solid #ccc;
	height: 42px;
}

#userPermitModal .filesTable tr:hover {
	background: #dedede;
}

#userPermitModal .filesTable td.smallButton {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}
#PageUserWorkPermits .ReactTable .rt-td{
	overflow: visible !important;
	
}

#userPermitModal .filesTable .apSimpleButton {
    width: 50px;
    height: 42px;
    text-align: center;
}
.permitImage{
	width: 400px;
	height: 200px;
	object-fit: contain;
}
@media only screen and (max-width: 1000px) {
  .apModal.medium {
    overflow-y: auto;
    overflow-x: auto;
	}
}
@media only screen and (max-height: 750px) {
  .apModal.medium {
    overflow-y: auto;
    overflow-x: auto;
	}
}

#PageUserWorkPermits .apReactTable .rt-tr-group .stickyColumn  {
  position: sticky !important;
  left: 70px;
  top: 0;
  z-index: 9;
  background-color: hsl(194, 100%, 93%);
}
#PageUserWorkPermits .apReactTable .rt-tr-group:nth-child(2n) .stickyColumn {
  position: sticky !important;
  left: 70px;
  top: 0;
  z-index: 9;
  background-color: hsl(194, 100%, 89%);
}
#PageUserWorkPermits .apReactTable .rt-tr-group .multiselect  {
  position: sticky !important;
  left: 0px;
  top: 0px;
  z-index: 9;
  background-color: hsl(194, 100%, 93%);
}
#PageUserWorkPermits .apReactTable .rt-tr-group:nth-child(2n) .multiselect  {
  position: sticky !important;
  left: 0px;
  top: 0px;
  z-index: 9;
  background-color: hsl(194, 100%, 89%);
}


#PageUserWorkPermits .apReactTable .ReactTable .rt-thead.-header .stickyColumn {
	position: sticky !important;
 	left: 70px;
 	top: 0;
	background-color: #0099cc;
	color: #fff;
	font-weight: bold;
	z-index: 9;
}
#PageUserWorkPermits .apReactTable .ReactTable .rt-thead.-header .multiselect {
	position: sticky !important;
 	left: 0px;
 	top: 0px;
	background-color: #0099cc;
	color: #fff;
	font-weight: bold;
	z-index: 9;
}
#PageUserWorkPermits .apReactTable .ReactTable .rt-thead.-filters .stickyColumn {
	background-color: #5e5e5e;
	color: #ccc; 
	left:70px;
	z-index: 9;
	position: sticky !important;
}
#PageUserWorkPermits .apReactTable .ReactTable .rt-thead.-filters .multiselect {
	background-color: #5e5e5e;
	color: #ccc; 
	left:0px;
	z-index: 9;
	position: sticky !important;
}

#PageUserWorkPermits .apReactTable .ReactTable .rt-th.multiselect {
	background-color: #f5f5f5;
	left:0px;
	z-index: 10;
	position: sticky !important;
}





/* #PageUserWorkPermits .permits.onlyActive table thead th.user.notActive,
#PageUserWorkPermits .permits.onlyActive table tbody td.user.notActive,
#PageUserWorkPermits .permits.onlyActive table thead th.user.notLoggable,
#PageUserWorkPermits .permits.onlyActive table tbody td.user.notLoggable { display: none; } 

#PageUserWorkPermits .permits table thead th.user.notActive,
#PageUserWorkPermits .permits table tbody td.user.notActive { background: #ffcdd2; }

#PageUserWorkPermits .permits table thead th.user.notLoggable,
#PageUserWorkPermits .permits table tbody td.user.notLoggable { background: #ffe0b2; }

#PageUserWorkPermits .permits table thead tr.level0 th.user.notActive,
#PageUserWorkPermits .permits table tbody tr.level0 td.user.notActive { background: #f2b5b5; }

#PageUserWorkPermits .permits table thead tr.level0 th.user.notLoggable,
#PageUserWorkPermits .permits table tbody tr.level0 td.user.notLoggable { background: #ffcc80; }


#PageUserWorkPermits .permits table thead th.user .image { margin: 0.5em auto; }
#PageUserWorkPermits .permits table thead th.user.notActive .image { border-color: #CC0000; }
#PageUserWorkPermits .permits table thead th.user.notLoggable .image { border-color: #e65100; } */