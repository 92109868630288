#WorkHourSettingsRowEditBody .globalAllowanceLink {
	margin: 1em 0 0;
	background: #eee;
	padding: 1em;
	position: relative;

	border-radius: 10px;
}

#WorkHourSettingsRowEditBody .globalAllowanceLink .apButton {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#WorkHourSettingsRowEditFooter .apButton.save {
	float: right;
}