.alert {
    width: 100%;
    opacity: 1;
    min-width: 0px;
    transition: width 1s ease-in-out, opacity 1s, text-indent 3s;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: break-word;
    
}

.alert-leave-active {
    width: 0%;
    text-indent: -9999px;  
    opacity: 0.01;
}
