.ApListTreeContainer {
	margin: 0;
}

.ApListTreeContainer ul.apListTree {
	list-style: none;
	padding: 0;
	margin: 0.5em 0 1em;
	overflow: hidden;

	-webkit-transition: height 0.4s ease, margin 0.4s ease;
	   -moz-transition: height 0.4s ease, margin 0.4s ease;
	    -ms-transition: height 0.4s ease, margin 0.4s ease;
	     -o-transition: height 0.4s ease, margin 0.4s ease;
	        transition: height 0.4s ease, margin 0.4s ease;
}

.ApListTreeContainer ul.apListTree ul.apListTree {
	padding-left: 2em;
}

.ApListTreeContainer ul.apListTree > li.closed ul.apListTree {
	margin: 0;
}

.ApListTreeContainer ul.apListTree > li {
	
}

.ApListTreeContainer ul.apListTree > li .apListTreeItem {
	background: #fff;
	border-bottom: 1px solid #ccc;
	margin: 0;
	padding: 0.5em 1em 0.5em 30px;
	position: relative;
}

.ApListTreeContainer ul.apListTree > li .apListTreeItem svg.childIndicator {
	position: absolute;
	top: 50%;
	left: 7px;
	width: 16px;
	height: 16px;
	cursor: pointer;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;
}

.ApListTreeContainer ul.apListTree > li.open > .apListTreeItem svg.childIndicator {
	-webkit-transform: translateY(-50%) rotate(90deg);
	   -moz-transform: translateY(-50%) rotate(90deg);
	    -ms-transform: translateY(-50%) rotate(90deg);
	     -o-transform: translateY(-50%) rotate(90deg);
	        transform: translateY(-50%) rotate(90deg);
}