#navTop .blocks > .block.paused > .button { background: #FF8800; }
#navTop .blocks > .block.paused > .button:hover { background: #ffbb33; }

#navTop .blocks > .block.playing > .button { background: #007E33; }
#navTop .blocks > .block.playing > .button:hover { background: #00C851; }

#navTop .blocks > .block.timer .topModal .bg {
	background: #ccc;
	background: -moz-linear-gradient(top, #ccc 0%, #eee 100%);
	background: -webkit-linear-gradient(top, #ccc 0%,#eee 100%);
	background: linear-gradient(to bottom, #ccc 0%,#eee 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccc', endColorstr='#eee',GradientType=0 );
	padding-bottom: 0.5em;
	position: relative;
}

#timer-modal {
	min-height: 580px !important;
	/* max-height: 700px; */
	
}

#navTop .blocks > .block.timer .test {
	display: inline-block;
	width: 40%;
	height: 100px;
	margin: 10px;
	background: #ccc;
	border: 1px solid #aaa;
}

#navTop .blocks > .block.timer .topModal .bg .corner-buttons-bottom {
	position: relative;
	top: -100%;
	left: 0;
	width: 100%;
	text-align: center;

}
#navTop .blocks > .block.timer .topModal .bg .corner-buttons-bottom button.cornerButton {
	transform: translate(0, -175%);
}

#navTop .blocks > .block.timer .topModal .bg button.cornerButton {
	position: absolute;
	z-index: 20;
	top: 0.5em;
	left: 0.5em;
	border: 0;
	border-radius: 999px;
}

#navTop .blocks > .block.timer .topModal .bg button.cornerButton.right {
	left: auto;
	right: 0.5em;
}

#navTop .blocks > .block.timer .topModal .controls {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: space-between;
}

#navTop .blocks > .block.timer .topModal .controls button {
	display: inline-block;
	width: 31%;
	height: 90px;
	line-height: 14px;
	margin: 0 1%;
	padding: 10px 20px;
}

#navTop .blocks > .block.timer .topModal .controls button span {
	font-size: 14px;
}

.modal-columns {
	display: flex;
	justify-content: space-evenly;
}

.modal-left-column {
	width: 50%;
}

.modal-right-column {
	width: 50%;
}

.timer-project {
	margin: 10px;
}

@media all and (max-width: 499px) {
	#navTop .blocks > .block.timer #timer-modal.topModal .bg {
		overflow: auto;
		max-height: 100vh;
	}
}
@media all and (max-height: 650px) {
	#navTop .blocks > .block.timer #timer-modal.topModal .bg {
		padding-bottom: 3rem;
		overflow: auto;
		max-height: 100vh;
	}
}