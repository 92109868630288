#Permits .guides {
	margin: 0 0.5em 1em;
}
#Permits .guides .guide {
	display: inline-block;
	font-size: 14px;
	padding: 0 2em 0 0;
}
#Permits .guides .guide svg {
	width: 20px;
	height: 20px;
	margin: -3px 3px 0;
}

#Permits .list .listItem {
	border-bottom: 1px solid #eee;
	padding: 0.5em;
	line-height: 1.2em;
	color: inherit;
	cursor: pointer;
}

#Permits .list .listItem:hover {
	text-decoration: none;
	background: #f9f9f9;
}

#Permits .list .listItem .listRow {
	display: flex;
	align-items: center;
}

#Permits .list .listItem svg.icon {
	position: absolute;
	top: 50%;
	right: 1em;
}

.layout-left-margin {
	margin-left: 1em;
}