
#EntryEditHeader {
	position: relative;
	height: 100px;
}

#EntryEditBody .entryStatus {
	padding: 0.5em;
	position: relative;
}

#EntryEditBody .entryStatus.status-submitted { color: #0099CC; border-color: #33b5e5; background: #e3f2fd; }
#EntryEditBody .entryStatus.status-rejected { color: #CC0000; border-color: #ff4444; background: #ffcdd2; }
#EntryEditBody .entryStatus.status-approved { color: #007E33; border-color: #4caf50; background: #dcedc8; }

#EntryEditBody .entryStatus .comment {
	background: rgba(255,255,255,0.5);
	margin-top: 0.2em;
	padding: 0.5em;
	border-left: 3px solid #ff4444;
}

#EntryEditBody .expenseRow {
	border-bottom: 1px solid #eee;
	margin: 0 0 0.5em;
	padding: 0 0 0.5em;
}

#EntryEditBody .expenseRow .apInputBlock {
	margin: 0;
}

#EntryEditBody .expenseRow .currencyRate {
	padding: 0.5em;
	background: #eee;
	border-top: 3px solid #0099CC;
	position: relative;
}

#EntryEditBody .expenseRow .currencyRate:after {
	bottom: 100%;
	right: 75px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(225, 245, 254, 0);
	border-bottom-color: #0099CC;
	border-width: 8px;
	margin-left: -8px;
}

#EntryEditBody .expenseRow .removeButton {
	float: right;
	width: 32px;
	height: 32px;
	margin: 5px -5px 0 0;
	border: 1px solid #ccc;
	border-radius: 5px;
}

#EntryEditBody .expenseRow .removeButton:hover {
	background: rgba(0,0,0,0.1);
}
#EntryEditBody .expenseRow .removeButton.disabled {
	pointer-events: none;
	opacity: 0.5;
}

#EntryEditBody .expenseRow .removeButton svg.SvgIcon {
	display: block;
	width: 20px;
	height: 20px;
	margin: 6px auto 0;
}

#EntryEditBody .totalCosts {
	float: right;
	padding: 0.5em;
}

#EntryEditBody .projectOption {
	position: relative;
}

#EntryEditBody .projectOption .works {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#EntryEditBody .projectOption .works svg {
	width: 16px;
	height: 16px;
}


#EntryEditBody .projectInfo {
	font-size: 14px;
}

#EntryEditBody .projectInfo .level {
	display: inline-block;
}
#EntryEditBody .projectInfo .level + .level:before {
	display: inline-block;
	padding: 0 0.2em;
	content: '/';
	color: #ccc;
}

#EntryEditBody .projectInfo .empty {
	color: #999;
}



#EntryEditFooter {
	text-align: right;
}

#EntryEditFooter .validateMsg {
	width: 70%;
	float: left;
	text-align: left;
	line-height: 1.2em;
	padding-left: 35px;
	position: relative;
}

#EntryEditFooter .validateMsg svg {
	position: absolute;
	top: -3px;
	left: 0;
}

#EntryEditFooter .apButton {
	margin: 0 0 0 1em;
}
