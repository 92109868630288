
.orderTooltip svg {
    width: 14px !important;
    height: 14px !important;
    margin-right: 4px !important;
}

.orderTooltip table tr {
    border-bottom: 1px solid #ccc;
}
.orderTooltip table td {
    padding: 0.5em 0.2em;
}

.orderTooltip .infoTable {
    width: 100%;
    margin-bottom: 1em;
}

.orderTooltip .infoTable .header {
    font-weight: 700;
    padding: 0.5em 0;
}

.orderTooltip .infoTable .infoLabel {
    text-align: left;
    padding-right: 1em;
}

.orderTooltip .infoTable .valueText {
    text-align: left;
}

.orderTooltip .infoTable .valueNumber {
    text-align: right;
    font-weight: 700;
}

.orderTooltip .noCalc {
    text-align: left;
    padding-bottom: 1em;
    font-weight: 700;
}

