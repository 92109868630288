

#pageSuppliers .searchOption {
	position: relative;
	padding-left: 35px;

	line-height: 1.2em;
}

#pageSuppliers .searchOption svg.typeIcon {
	position: absolute;
	top: 50%;
	left: 0;
	width: 24px;
	height: 24px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageSuppliers .searchOption .company {
	position: absolute;
	top: 50%;
	right: 1em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageSuppliers .searchOption .company svg { fill: #aaa; }

#pageSuppliers .empty { color: #aaa; }


#pageSuppliers .apReactTable .ReactTable .rt-tr.notActive {
	background: #ffebee;
	color: #ff4444;
}
#pageSuppliers .apReactTable .ReactTable .rt-tr.notActive.selected {
	background: #e1f5fe;
}


#pageSuppliers .iconCell {
	text-align: center;
}

#pageSuppliers .iconCell svg {
	margin: 15px 0 0;
}

#pageSuppliers .iconCell.verified svg { fill: #4caf50; }
#pageSuppliers .iconCell.verified.overYear svg { fill: #FF8800; }
#pageSuppliers .iconCell.verified.over5Years svg { fill: #ff4444; }
#pageSuppliers .iconCell.unverified svg { fill: #aaa; }

.addGroup	{overflow:visible;}

.addGroup .apModalBody { overflow:unset !important; }

.addGroup-narrow { max-width:600px; }
