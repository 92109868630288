#app > .mainWrapper {
  margin-top: 50px;
}

#navTop {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #0099cc;

  font-family: "Roboto-Light", Arial, sans-serif;
  font-size: 18px;
  line-height: 1.5em;
  color: #fff;
}

#navTop .company {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  overflow: hidden;
}

#navTop .company .logoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  padding: 10px;
}
#navTop .company.logoOnly .logoBg {
  width: 300px;
}

#navTop .company .logoBg .logo {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#navTop .company .text {
  position: absolute;
  top: 50%;
  left: 90px;
  width: 200px;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

#navTop .company .text .title,
#navTop .company .text .subtitle {
  font-size: 16px;
  line-height: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#navTop .company .text .subtitle {
  font-size: 13px;
  opacity: 0.8;
}

#navTop .blocks {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  font-size: 16px;
}

#navTop .blocks:after {
  content: "";
  display: block;
  clear: both;
}

#navTop .blocks > .divider {
  float: left;
  width: 1px;
  height: 30px;
  margin: 10px 0.5em;
  background: rgba(255, 255, 255, 0.3);
}

#navTop .blocks > .block {
  float: left;
  width: auto;
  height: 100%;
  margin: 0;
  position: relative;
}

#navTop .blocks > .block > .button {
  display: block;
  padding: 0 20px;
  line-height: 50px;
  max-height: 50px;

  -webkit-transition: background 0.4s ease;
  -moz-transition: background 0.4s ease;
  -ms-transition: background 0.4s ease;
  -o-transition: background 0.4s ease;
  transition: background 0.4s ease;

  cursor: pointer;
}

#navTop .blocks > .block > .button > svg {
  fill: #fff;
  width: 24px;
  height: 24px;
  margin: -3px 0 0 0;
  vertical-align: middle;
}

#navTop .blocks > .block > .button > .text {
  display: inline-block;
  margin-right: 5px;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

#navTop .blocks > .block > .button:hover {
  background: #33b5e5;
}

#navTop .block .topModal {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
  -moz-transition: transform 0.4s ease, opacity 0.4s ease;
  -ms-transition: transform 0.4s ease, opacity 0.4s ease;
  -o-transition: transform 0.4s ease, opacity 0.4s ease;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

#navTop .block .topModal.open {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

#navTop .block .topModal:after {
  bottom: 100%;
  right: 22px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

#navTop .mobileMenu {
  display: flex;
  align-items: center;
  width: 30px;
  height: 50px;
  margin-left: 15px;
}

#navTop .block.languages .topModal:after {
  right: 35px;
}

@media all and (max-width: 999px) {
  #navTop .blocks > .divider.secondary {
    display: none;
  }

  #navTop .blocks > .block > .button > .text {
    display: none;
  }

  #navTop .block.timer .topModal,
  #navTop .block.notifications .topModal,
  #navTop .block.tasks .topModal {
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 20px);
    -moz-transform: translate(-50%, 20px);
    -ms-transform: translate(-50%, 20px);
    -o-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }

  #navTop .block.timer .topModal.open,
  #navTop .block.notifications .topModal.open,
  #navTop .block.tasks .topModal.open {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  #navTop .block.timer .topModal:after,
  #navTop .block.notifications .topModal:after,
  #navTop .block.tasks .topModal:after {
    right: auto;
    left: 50%;
  }
}

@media all and (max-width: 700px) {
  #navTop .company {
    display: none;
  }
}

@media all and (max-width: 799px) {
  #navTop .logo .title {
    font-size: 18px;
  }
  #navTop .logo .subtitle {
    font-size: 12px;
  }

  #navTop .company .logoBg {
    width: 50px;
  }
  #navTop .company .text {
    left: 55px;
  }
}

@media all and (max-width: 599px) {
  #navTop .company.logoOnly .logoBg {
    width: 50px;
  }

  #navTop .company .text {
    display: none;
  }

  #navTop .blocks > .block > .button {
    padding-left: 15px;
    padding-right: 15px;
  }

  #navTop .block .topModal,
  #navTop .block.timer .topModal,
  #navTop .block.notifications .topModal,
  #navTop .block.tasks .topModal {
    position: fixed;
    left: auto;
    right: 20px;
    top: 50px;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }

  #navTop .block .topModal.open,
  #navTop .block.timer .topModal.open,
  #navTop .block.notifications .topModal.open,
  #navTop .block.tasks .topModal.open {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  #navTop .block.languages .topModal {
    right: 125px;
  }

  #navTop .block.timer .topModal {
    right: 90px;
  }

  #navTop .block .topModal:after,
  #navTop .block.timer .topModal:after,
  #navTop .block.notifications .topModal:after,
  #navTop .block.tasks .topModal:after {
    left: auto;
  }

  #navTop .block.timer .topModal:after {
    right: 230px;
  }
  #navTop .block.user .topModal:after {
    right: 192px;
  }
  #navTop .block.bugs .topModal:after {
    right: 69px;
  }
  #navTop .block.languages .topModal:after {
    right: 30px;
  }
}

@media all and (max-width: 499px) {
  #navTop .blocks > .block > .button {
    padding-left: 10px;
    padding-right: 10px;
  }

  #navTop .block.languages .topModal {
    right: 105px;
  }

  #navTop .block.timer .topModal:after {
    right: 165px;
  }
  #navTop .block.user .topModal:after {
    right: 147px;
  }
  #navTop .block.bugs .topModal:after {
    right: 53px;
  }
  #navTop .block.languages .topModal:after {
    right: 15px;
  }
}

@media all and (max-width: 399px) {
  #navTop .blocks > .divider {
    margin-left: 2px;
    margin-right: 2px;
  }

  #navTop .blocks > .block > .button > svg {
    width: 20px;
    height: 20px;
  }

  #navTop .block .topModal,
  #navTop .block.timer .topModal,
  #navTop .block.notifications .topModal,
  #navTop .block.tasks .topModal {
    right: 10px;
  }

  #navTop .block.languages .topModal {
    right: 85px;
  }

  #navTop .block.timer .topModal:after {
    right: 215px;
  }
  #navTop .block.user .topModal:after {
    right: 135px;
  }
  #navTop .block.bugs .topModal:after {
    right: 45px;
  }
  #navTop .block.languages .topModal:after {
    right: 15px;
  }
}

@media all and (max-width: 350px) {
  #navTop .blocks > .block > .button {
    padding-left: 8px;
    padding-right: 8px;
  }

  #navTop .block.languages .topModal {
    right: 77px;
  }

  #navTop .block.timer .topModal:after {
    right: 192px;
  }
  #navTop .block.user .topModal:after {
    right: 121px;
  }
  #navTop .block.bugs .topModal:after {
    right: 39px;
  }
}
