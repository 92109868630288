#contract-billing-modal table.componentTable > tbody > tr > td.fixed {
    width: 30px;
    min-width: 30px;
    max-width: 100%;
}
#contract-billing-modal table.componentTable > tbody > tr > td.fixed.actions {
    width: 50px;
    min-width: 50px;
    max-width: 100%;
}

@media (min-width: 1200px) {
    #contract-billing-modal .apModalWrapper .apModal .apModalFooter {
        overflow: visible;
    }
}