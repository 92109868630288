
#NewComponent .stepType .option:hover {
    background: #FFF;
    border-bottom-color: #33b5e5;
}
#NewComponent .stepType .option {
    position: relative;
    padding: 1em;
    margin: 1em 0;

    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    background: #f9f9f9;
    cursor: pointer;
}

#NewComponent .stepType .option.selected {
    outline: 1px solid #33b5e5;
    border-color: #33b5e5;
    background: #fff;


}

#NewComponent .stepType .option .iconContainer {

    position: absolute;
    left: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#NewComponent .stepType .option:hover .iconContainer svg { color: #333; }
#NewComponent .stepType .option .iconContainer svg {
    height: 30px;
    width: auto;
}

#NewComponent .stepType .option .textContainer {
    margin-left: 60px;
    border-left: 1px solid #ccc;
    padding-left: 20px;
}

#NewComponent .stepType .option:hover .textContainer .header { color: #0099CC; }
#NewComponent .stepType .option .textContainer .header {
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

#NewComponent .stepType .option .textContainer .text {
    padding-left: 1em;
}

#NewComponent .stepDetail .apInputStack {
    margin-bottom: 10px;
}

#NewComponent .stepConfirm .apStatusBox {
    display: inline;
    padding: 6px 1em;
}

