.apOptionInput {
    display: inline-block;
    width: auto;
    margin: 0 0.5em 0 0;
    vertical-align: middle;
    height: 46px;
}

.apOptionInput .option {
    float: none;
    display: inline;
    padding: 7px 0.5em;
    line-height: 43px;
}

.apOptionInput.fullWidth {
    display: block;
    width: 100%;
    margin: 5px 0 0 0;
}
.apOptionInput.fullWidth .option {
    float: left;
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 33px;
}
.apOptionInput.count-1 .option { width: 100%; }
.apOptionInput.count-2 .option { width: 50%; }
.apOptionInput.count-3 .option { width: 33.33%; }
.apOptionInput.count-4 .option { width: 25%; }
.apOptionInput.count-5 .option { width: 20%; }
.apOptionInput.count-6 .option { width: 16.66%; }
.apOptionInput.count-7 .option { width: 14.28%; }
.apOptionInput.count-8 .option { width: 12.5%; }

.apOptionInput.disabled {
    cursor: not-allowed;
}

.apOptionInput.disabled .option,
.apOptionInput .option.disabled {
    pointer-events: none;
}