.ApRangeSlider {
	margin: 1em 0;
	padding: 30px 20px 20px;
}

.ApRangeSlider .rc-slider {
	height: 15px;
	padding: 0;
	border-radius: 0;
}
.ApRangeSlider .rc-slider.rc-slider-disabled { 
	opacity: 0.5;
	pointer-events: none;
}

.ApRangeSlider .rc-slider .rc-slider-rail,
.ApRangeSlider .rc-slider .rc-slider-track,
.ApRangeSlider .rc-slider .rc-slider-step { height: 100%; overflow: hidden; border-radius: 999px; }

.ApRangeSlider .rc-slider .rc-slider-rail {
	background: #eee;

	-webkit-box-shadow:inset 0 3px 10px 0 rgba(0,0,0,0.3);
			box-shadow:inset 0 3px 10px 0 rgba(0,0,0,0.3);
}

.ApRangeSlider .rc-slider .rc-slider-track {
	background: #00C851;
	-webkit-box-shadow:inset 0 3px 10px 0 rgba(0,0,0,0.3);
			box-shadow:inset 0 3px 10px 0 rgba(0,0,0,0.3);
}
.ApRangeSlider .rc-slider.rc-slider-disabled .rc-slider-track { background: #ccc; }

.ApRangeSlider .rc-slider .rc-slider-handle {
	top: 0;
	width: 20px;
	height: 20px;
	margin: -3px 0 0 -10px;
	background: #f9f9f9;
	border: 2px solid #ccc;

	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.7);
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.7);
}

.ApRangeSlider .rc-slider .rc-slider-handle:hover {
	border-color: #aaa;
}

.ApRangeSlider .rc-slider .rc-slider-handle .handleTooltip {
	position: absolute;
	z-index: 10;
	bottom: calc( 100% + 10px );
	left: 50%;
	background: #f9f9f9;
	border: 1px solid #ccc;
	border-bottom: 3px solid #ccc;
	padding: 0 0.5em;
	font-size: 16px;
	line-height: 25px;

	-webkit-user-select: none; /* Safari 3.1+ */
       -moz-user-select: none; /* Firefox 2+ */
    	-ms-user-select: none; /* IE 10+ */
    		user-select: none; /* Standard syntax */

	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}
.ApRangeSlider .rc-slider .rc-slider-handle .handleTooltip:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(204, 204, 204, 0);
	border-top-color: #ccc;
	border-width: 10px;
	margin-left: -10px;
}

.ApRangeSlider .rc-slider .rc-slider-handle:hover .handleTooltip,
.ApRangeSlider .rc-slider .rc-slider-handle.dragging .handleTooltip { border-bottom-color: #33b5e5; }
.ApRangeSlider .rc-slider .rc-slider-handle:hover .handleTooltip:after,
.ApRangeSlider .rc-slider .rc-slider-handle.dragging .handleTooltip:after { 
	border-color: rgba(51, 181, 229, 0);
	border-top-color: #33b5e5; 
}
.ApRangeSlider .rc-slider .rc-slider-handle.onHoverOnly .handleTooltip { 
	opacity: 0; 
	-webkit-transition: opacity 0.4s ease;
	   -moz-transition: opacity 0.4s ease;
	    -ms-transition: opacity 0.4s ease;
	     -o-transition: opacity 0.4s ease;
	        transition: opacity 0.4s ease;
}
.ApRangeSlider .rc-slider .rc-slider-handle.onHoverOnly:hover .handleTooltip,
.ApRangeSlider .rc-slider .rc-slider-handle.onHoverOnly.dragging .handleTooltip { opacity: 1; }


.ApRangeSlider .rc-slider .rc-slider-step .rc-slider-dot { 
	bottom: -4px;
	height: 8px;
	width: 2px;
	margin: 0 0 0 -1px;
	background: rgba(0,0,0,0.2);
	border: 0;
	border-radius: 0;
}

.ApRangeSlider .rc-slider .rc-slider-mark {
	top: 100%;
	pointer-events: none;
}
.ApRangeSlider .rc-slider .rc-slider-mark .rc-slider-mark-text {
	height: 20px;
	line-height: 20px;
	font-size: 12px;
}

.ApRangeSlider.withSections {
	padding-top: 0;
}

.ApRangeSlider .sections {
	width: 100%;
	height: 30px;
	overflow: hidden;
	position: relative;
}
.ApRangeSlider .sections .section {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: 100%;
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
	text-align: center;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;

	padding: 0 0.5em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ApRangeSlider .sections .section:hover {
	background: #f9f9f9;
}

