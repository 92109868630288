.width100 {
    width: 100px;
}

.alignRight {
    text-align: right;
}

#inventoryModalBody input.newBalanceInput {
    padding: 6px 10px;
}

#inventoryModalBody td,
#inventoryModalBody th {
    padding: 0.2em 0.3em !important;
}

#inventoryModalBody td div {
    margin: 5px 0 5px;
}
