.open-date-picker {
	height: auto;
	border: 1px solid #ccc;
	border-bottom-width: 3px;

	font-family: 'Roboto-Light', Arial, sans-serif;
	font-size: 16px;
	line-height: 20px;
	color: #000;

	-webkit-transition: all 0.2s ease;
	   -moz-transition: all 0.2s ease;
		-ms-transition: all 0.2s ease;
		 -o-transition: all 0.2s ease;
			transition: all 0.2s ease;

    display: block;
    position: absolute;
    z-index: 999999;
  }

  .open-date-picker:after {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #0099CC;
	border-width: 10px;
	margin-left: -10px;
} 

  .react-calendar {
	width: 350px;
	max-width: 100%;
	background: white;
	border: 1px solid #a0a096;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
  }
  .react-calendar--doubleView {
	width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
	background: #e3f2fd;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {

	width: 50%;
	margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
  }
  .react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
  }
  .react-calendar button:enabled:hover {
	cursor: pointer;
  }
  .react-calendar__navigation {
	background: #0099CC; color: #fff; padding: 5px 0;
  }
  .react-calendar__navigation button {
	min-width: 44px;
	background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
	background-color: #33b5e5;
  }
  .react-calendar__navigation button[disabled] {
	background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.75em;
	background: #e3f2fd; border-bottom: 1px solid #90caf9; color: #0099CC;

  }
  .react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
	font-weight: bold;
	background: #e3f2fd; color: #0099CC;
	border-right: 1px solid #90caf9 !important; 
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days {
	background: white;
  }

  .react-calendar__month-view__days__day--weekend {
	color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
  }
  .react-calendar__tile {
	max-width: 100%;
	text-align: center;
	padding: 0.75em 0.5em;
	background: none;
	border: 1px solid #eee !important;
  }
  .react-calendar__tile:disabled {
	background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
	background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
	background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
	background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
	background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
	background: #a9d4ff;
  }
  .react-calendar__tile--active {
	background: #006edc;
	color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
	background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
  }
  