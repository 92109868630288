.collections-add-manual-button {
    background-color: #008000;
}

.collections-item-row {
    display: grid;
    width: 100%;
    grid-template-columns: 80% 20%;
    align-items: stretch;
    min-width: 321px;
    margin-bottom: 10px;
}

.collections-item-row:hover:not(.header-row) {
    cursor: pointer;
    color: #0099CC;
    transform: translateX(5px);
}

.collection-list-row:hover {
    cursor: pointer;
}

.collection-list-row-text:hover {
    color: #0099CC;
}

#collectModal .closeCross {
	position: absolute;
	z-index: 99;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
}