
#help {
	position: absolute;
	z-index: 10000;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,0);
	transition: background 0.4s ease;
	pointer-events: none;
}

#help.open { 
	background: rgba(0,0,0,0.5);
	pointer-events: auto;
}

#help .help {
	position: absolute;
	top: 0;
	right: 0;
	width: 90%;
	max-width: 500px;
	height: 100%;
	background: #fff;
	color: #333;
	transform: translateX(100%);
	transition: transform 0.4s ease;
}
#help.open .help { transform: translateX(0); }

#help .help .top {
	width: 100%;
	height: 50px;
	background: #eee;
	position: relative;
}

#help .help .top .title {
	margin: 0 1em;
	line-height: 50px;
	color: #999;
	font-weight: normal;
}

#help .help .top .title svg {
	position: absolute;
	top: 9px;
	left: 10px;
	width: 32px;
	height: 32px;
	stroke: #999;
}

#help .help .top .close {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
}
#help .help .top .close svg {
	display: block;
	margin: 9px;
	width: 32px;
	height: 32px;
}
#help .help .top .close:hover { background: #ddd; }

#help .navigation:after {
	content: '';
	clear: both;
	display: block;
}
#help .navigation .section {
	float: left;
	width: 50%;
	height: 50px;
	border-top: 3px solid #ccc;
	background: #ddd;
	background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
	background: -webkit-linear-gradient(top, #ddd 0%,#bbb 100%);
	background: linear-gradient(to bottom, #ddd 0%,#bbb 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dddddd', endColorstr='#bbbbbb',GradientType=0 );
	position: relative;
	cursor: pointer;
	font-size: 15px;
}
#help .navigation .section:hover {
	background: #eee;
	background: -moz-linear-gradient(top, #eee 0%, #ccc 100%);
	background: -webkit-linear-gradient(top, #eee 0%,#ccc 100%);
	background: linear-gradient(to bottom, #eee 0%,#ccc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 );
}
#help .navigation .section.selected {
	background: #fff;
	border-top-color: #33b5e5;
}

#help .navigation .section svg {
	position: absolute;
	top: 15px;
	left: 10px;
	width: 20px;
	height: 20px;
}

#help .navigation .section .text {
	position: absolute;
	top: 50%;
	left: 40px;
	line-height: 1em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#help .navigation .section .text small {
	font-size: 13px;
	color: rgba(0,0,0,0.5);
}

#help h2,
#help h3,
#help h4,
#help h5,
#help h6 { margin-top: 1.5em; }

#help h2:first-child,
#help h3:first-child,
#help h4:first-child,
#help h5:first-child,
#help h6:first-child { margin-top: 0; }

#help p { 			font-size: 16px; }
#help p.ingress { 	font-size: 20px; color: #999; }

#help ul li { 		margin: 0 0 0.5em; }

#help svg.inline {
	width: 24px;
	height: 24px;
	margin: -3px 3px 0 0;
	background: #eee;
	padding: 4px;

	-webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
			box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
}
#help svg.inline.noBg { 
	padding: 0;
	background: transparent;
	-webkit-box-shadow: none;
			box-shadow: none;
}
#help svg.inline.success 	{ fill: #4caf50; }
#help svg.inline.warning 	{ fill: #FF8800; }
#help svg.inline.error 		{ fill: #ff4444; }

#help svg.inline.topbar 	{ background: #0099CC; fill: #fff; }


#help .help .scroller {
	width: 100%;
	height: calc( 100% - 50px );
	overflow-x: auto;
	overflow-y: scroll;
}
