
.apProjectPicker {
	width: 100%;
	position: relative;
}

.apProjectPicker svg {
	width: 16px;
	height: 16px;
	margin: 0 0.5em;
}

.apProjectPicker .apProjectPart {
	display: inline-block;
	padding: 0.5em 10px;
	margin: 0 5px 0 0;
	position: relative;
	background: #fff;
	position: relative;
	box-shadow: 0 2px 10px rgba(0,0,0,0.2);

	background: #eeeeee;
	background: -moz-linear-gradient(left, #eeeeee 0%, #ffffff 100%);
	background: -webkit-linear-gradient(left, #eeeeee 0%,#ffffff 100%);
	background: linear-gradient(to right, #eeeeee 0%,#ffffff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#ffffff',GradientType=1 );
}

.apProjectPicker .apProjectPart:after {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	z-index: 99;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: 10px;
	margin-top: -10px;
}

.apProjectPicker .apProjectPart .options {
	position: absolute;
	z-index: 999;
	top: 100%;
	left: 0;
	width: 100%;
	height: auto;
	background: #fff;
	box-shadow: 0 5px 20px rgba(0,0,0,0.3);
	pointer-events: none;
	opacity: 0;

	-webkit-transform: translateY(-10px);
       -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
         -o-transform: translateY(-10px);
            transform: translateY(-10px);
	
	-webkit-transition: transform 0.4s ease, opacity 0.4s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s ease;
            transition: transform 0.4s ease, opacity 0.4s ease;
}

.apProjectPicker .apProjectPart input:focus + .options {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translateY(0);
       -moz-transform: translateY(0);
        -ms-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
}

.apProjectPicker .apProjectPart input:focus + .options li.selected {
	background: #fed;
}
