
#newCrmCompanyFooter {
	padding: 1em;
	text-align: right;
}

#newCrmCompanyFooter .apButton {
	margin: 0;
}

#newCrmCompanyFooter .apButton.cancel {
	float: left;
}