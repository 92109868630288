#projectFiles .filesTable {
    margin-top: 2em;
}


#projectFiles .filesTable {
    width: 100%;
}

#projectFiles .filesTable tr {
    border-bottom: 1px solid #ccc;
    height: 42px;
}

#projectFiles .filesTable td.smallButton {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

#projectFiles .filesTable .apSimpleButton {
    width: 50px;
    height: 42px;
    text-align: center;
}