#pageBillable .changeCounter {
    position: absolute;
    top: 2px;
    right: 10px;
}

#pageBillable .companyOption .badges {
    position: absolute;
    right: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

#pageBillable .companyOption .badges .apBadge {
    display: inline-block;
    width: 40px;
    height: 20px;
    vertical-align: middle;
    margin: 0 5px 0 0;
    text-align: center;
}

#pageBillable .companyOption .badges .apBadge.none {
    background: rgba(0,0,0,0.05);
}

#pageBillable .badgeInfo {
    font-size: 13px;
    margin-bottom: 1em;
}

#pageBillable .badgeInfo span {
    display: inline-block;
}

#pageBillable .badgeInfo .apBadge {
    display: inline-block;
    width: 16px; 
    height: 16px;
    margin-left: 2em;
    vertical-align: middle;
}
#pageBillable .badgeInfo .apBadge:first-child { margin-left: 0; }

#pageBillable .tableHeader {
    position: relative;
    height: 100%;
}

#pageBillable .tableHeader .toggleSwitch {
	position: absolute;
	top: 50%;
	right: 0.5em; 
	line-height: 25px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageBillable .tableHeader .toggleSwitch .switchLabel {
	padding: 0 50px 0 1em;
	font-size: 14px;
	margin: 0;
	cursor: pointer;
}

#pageBillable .tableHeader .toggleSwitch .apSwitchWrapper {
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageBillable .rt-tr .editableCell {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    border: 1px solid #eee;
    border-bottom-width: 3px;
    padding: 0 20px 0 0.2em;
    overflow: hidden;
    text-overflow: ellipsis;
}
#pageBillable .rt-tr.selected .editableCell { border-color: #ddd; }

#pageBillable .rt-tr svg.indicator { display: none; }

#pageBillable .rt-tr .editableCell svg.indicator {
    display: block;
    position: absolute;
    top: 50%;
    right: 4px;
    width: 12px;
    height: 12px;
    fill: #33b5e5;
    -webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0;
}

#pageBillable .rt-tr .editableCell:hover { color: #0099CC; border-bottom-color: #33b5e5; }
#pageBillable .rt-tr .editableCell:hover svg.indicator { opacity: 1; }

#pageBillable .rt-tr .editableCell.haveChanges {
    border-bottom-color: #ffbb33;
}

#pageBillable .apStatusBox.editable {
    border: 1px solid #eee;
    border-bottom-width: 3px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
    	-ms-user-select: none; 
    		user-select: none;
}
#pageBillable .apStatusBox.editable:hover { border-bottom-color: #33b5e5; }
#pageBillable .apStatusBox.editable.haveChanges { border-bottom-color: #ffbb33; }


#pageBillable .approverSelect .apInputBlock,
#pageBillable .approverSelect .apInputBlock .apSelect { margin-bottom: 0 }
#pageBillable .approverSelect .apInputBlock .apSelect select {
    font-size: 14px;
    height: 36px;
    line-height: 30px;
    padding-right: 24px;
}
#pageBillable .approverSelect .apInputBlock .apSelect svg.dropdownArrow {
    width: 16px;
    height: 16px;
    right: 4px;
}
#pageBillable .approverSelect .apInputBlock svg.validationIcon { display: none; }


#pageBillable .approverComment { 
    color: #ff4444; 
    text-align: center;
}

#pageBillable .approverComment svg {
    width: 20px;
    height: 20px;
}

#pageBillable .approverComment .apTooltip {
    white-space: normal;
    min-width: 200px;
    text-align: left;
}
#pageBillable .approverComment .apTooltip hr {
    margin: 0.5em 0;
    border-color: #666;
}

#pageBillable .approverColumn .apTooltipper,
#pageBillable .approverColumn .approverCell {
   width: 100%;
   height: 100%;
}

#pageBillable .approverColumn .approverCell.rejected {
    background: #ffebee;
    color: #CC0000;
    border-right: 3px solid #ff4444;
}

#pageBillable .approverColumn .approverCell svg {
    position: absolute;
    bottom: 5px;
    right: 5px;
}


#pageBillable .stickyFooter {
	border-top: 2px solid #eee;
    background: #fff;
    position: relative;
}
#pageBillable .ApStickyBar.sticky .stickyFooter {
    border-top-color: #33b5e5;
}

#pageBillable .stickyFooter .info {
    padding: 1em;
}

#pageBillable .stickyFooter .apButtonGroup {
    position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageBillable .stickyFooter .apButtonGroup > .divider {
    display: inline-block;
    width: 1px;
    height: 30px;
    background: #ccc;
    margin: 0 1em;
    vertical-align: middle;
}

#pageBillable .apReactTable .ReactTable .rt-th .dropPanelWrapper .dropPanel .scroller { max-height: 300px; }
#pageBillable .stickyFooter .apDropdown .apDropdownMenu { width: 300px; }

#pageBillable .selector {
    margin-right: 1em;
}

#pageBillable .selector:hover {
    cursor: pointer;
    color: #33B5E5;
}

#pageBillable .dimmed-selector {
    margin-right: 1em;
    text-decoration: line-through;
    opacity: 0.6;

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    /* IE 5-7 */
    filter: alpha(opacity=50);

    /* Netscape */
    -moz-opacity: 0.5;

    /* Safari 1.x */
    -khtml-opacity: 0.5;
}

#pageBillable .dimmed-selector:hover {
    cursor: pointer;
    color: #33B5E5;
}

#pageBillable .hover:hover {
    cursor: pointer;
}

#pageBillable .billable-content {
    display: flex;
	flex-direction: row;
}

@media all and (max-width: 900px) {
    #pageBillable .billable-content{
        flex-direction: column;
	}
}