.calculationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.toggleContainer {
  display: flex;
  justify-content: flex-end;
}

.toggleContainer label {
  padding-right: 5px;
  margin: 0;
}

.toggleContainer .apSwitchWrapper {
  transform: translateY(25%);
}
