#Chat{
    overflow: hidden;
    display: flex;
    max-height: 90vh;
    

}
/* #Chat .roomSelect{
    
    width: 25%;
} */

#Chat .chatArea {
    flex-basis: auto;
    width: 75%;
    position: relative;
}

@media all and (max-width: 600px) {
    #Chat .chatArea{
        flex-basis: fit-content;
        width: 75%;
        position:relative;
    
    }
}


#Chat .chatArea .messageList {
    /*max-height: 67vh;*/
    /*min-height: 70vh;*/
    overflow: auto;
}
#Chat > div.chatArea > .MessageAndWritingArea{
    max-height: 80vh;
    
}
/* three items */
#Chat .chatArea .messageList:first-child:nth-last-child(3),
#Chat .chatArea .messageList:first-child:nth-last-child(3) ~ div {
    max-height: 72vh;
    
    
}

/* four items */
#Chat .chatArea .messageList:first-child:nth-last-child(4),
#Chat .chatArea .messageList:first-child:nth-last-child(4) ~ div {
    max-height: 64vh;
    
}
