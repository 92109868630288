#pageTimetrackingOverview .scroller {
    width: 100%;
    overflow: scroll;
    background: #eee;
    box-shadow: inset 0 5px 20px rgba(0,0,0,0.2);
    padding: 1em;
    margin: 0 0 1em;
}

#pageTimetrackingOverview .entryTable {
    font-size: 14px;
    -webkit-transition: opacity 0.6s ease;
       -moz-transition: opacity 0.6s ease;
        -ms-transition: opacity 0.6s ease;
         -o-transition: opacity 0.6s ease;
            transition: opacity 0.6s ease;
}

#pageTimetrackingOverview .entryTable.loading {
    opacity: 0.2;
    pointer-events: none;
}

#pageTimetrackingOverview .entryTable th.date {
    background: #ddd;
    line-height: 1.2em;
}

#pageTimetrackingOverview .entryTable th,
#pageTimetrackingOverview .entryTable td {
    padding: 0.5em 0.2em;
    border: 1px solid #eee;
}

#pageTimetrackingOverview .entryTable td.date:hover {
    background: #f9f9f9;
}

#pageTimetrackingOverview .entryTable th.user,
#pageTimetrackingOverview .entryTable td.user,
#pageTimetrackingOverview .entryTable th.totals,
#pageTimetrackingOverview .entryTable td.totals {
    min-width: 200px;
    max-width: 200px;
    text-align: left;
    background: #ddd;
    padding: 0.5em;
}

#pageTimetrackingOverview .entryTable th.date,
#pageTimetrackingOverview .entryTable td.date {
    max-width: 50px;
    min-width: 50px;
    text-align: center;
}
#pageTimetrackingOverview .entryTable td.date svg {
    width: 12px;
    height: 12px;
}
#pageTimetrackingOverview .entryTable td.date svg.lockedDay {
    fill: rgba(0,0,0,0.2);
}

#pageTimetrackingOverview .entryTable td.date.selected { background: #90caf9; }
#pageTimetrackingOverview .entryTable td.date.selected:hover { background: #bbdefb; }

#pageTimetrackingOverview .entryTable .apTooltip {
    text-align: left;
}

#pageTimetrackingOverview .guides {
    display: flex;
    flex-direction: row;
	font-size: 14px;
	padding: 0 2em 0 0;
}

#pageTimetrackingOverview .apBadge.green.confirmed {
    background: transparent;
    color: black;
    /* box shadow instead of border to keep element the same size as others like it */
    box-shadow: inset 0px 0px 0px 2px #388e3c;
}
#pageTimetrackingOverview .apBadge.green.confirmed .SvgIcon {
    color: #388e3c;
}
