
#newProject .stepBasic table.apInputStack .apAddon {
    height: 46px;
}

#newProject .apModalWrapper .apModal .apModalBody {
    max-height: calc( 90vh - 150px );
    overflow: auto;
}

#newProject_end_date ~ .open-date-picker {
    left: -82px;
}

#newProject .statsTitle {
    color: #0099CC;
    font-weight: 700;
    margin-bottom: 0.2em;
}
#newProject .statsTable {
    margin-left: 2em;
}
#newProject .statsTable td {
    /* border: 1px solid black; */
    font-size: 0.9em;
}

#newProject .statsTable td:nth-child(1) {
    padding-right: 1em;
    font-size: 0.9em;
}
#newProject .statsTable td:nth-child(2) {
    font-weight: 700;
}
#newProject .statsTable td:nth-child(3) {
    font-size: 0.8em;
    padding-left: 1em;
}
