.apFileUpload {
    margin: 0 0 1em;
}

.apFileUpload.loading {
    opacity: 0.5;
    pointer-events: none;
}

.apFileUpload .apDropzone {
    width: 100%;
    height: auto;
    min-height: 70px;
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);

    position: relative;
    text-align: center;
}

.apFileUpload .apDropzone.active { background: #e3f2fd; border-color: #33b5e5; }
.apFileUpload .apDropzone.accept { background: #e8f5e9; border-color: #4caf50; }
.apFileUpload .apDropzone.rejected { background: #ffcdd2; border-color: #ff4444; }

.apFileUpload .apDropzone .topbar {
    width: 100%;
    height: 25px;
    line-height: 25px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-size: 12px;
    text-align: left;
    position: relative;
}

.apFileUpload .apDropzone .topbar .message {
    padding: 0 0.5em;
}

.apFileUpload .apDropzone .topbar .message svg {
    width: 16px;
    height: 16px;
    margin: -3px 5px 0 0;
}

.apFileUpload .apDropzone .noFiles {
    padding: 1em;
}

.apFileUpload .apDropzone .addButton {
    border-top: 1px solid rgba(0,0,0,0.1);
    background: rgba(0,0,0,0.05);
    text-align: center;
    padding: 0.5em 1em;
    cursor: pointer;
    width: 100%;
}

.apFileUpload .apDropzone .addButton:hover {
    color: #0099CC;
    background: rgba(3, 169, 244, 0.1);
}

.apFileUpload .apDropzone .addButton.disabled {
    color: #ccc;
    background: rgba(0,0,0,0.05);
    cursor: not-allowed;
}

.apFileUpload .apDropzone .addButton svg {
    width: 20px;
    height: 20px;
    margin: -3px 5px 0 0;
}

.apFileUpload .apDropzone .files {

}

.apFileUpload .apDropzone .files .file {
    margin: 0;
    padding: 0.5em 30px;
    border-bottom: 1px solid #ccc;

    text-align: left;
    position: relative;
}

.apFileUpload .apDropzone .files .file.removed {
    text-decoration: line-through;
    color: #ccc;
    display: none;
}

.apFileUpload.showRemoved .apDropzone .files .file.removed { display: block; }

.apFileUpload .apDropzone .files .file svg.icon {
    position: absolute;
    widows: 16px;
    height: 16px;
    margin-top: -8px;
    top: 50%;
    left: 4px;
    fill: #33b5e5;
}

.apFileUpload .apDropzone .files .file.uploaded svg.icon { fill: #4caf50; }
.apFileUpload .apDropzone .files .file.removed svg.icon { fill: #ef9a9a; }

.apFileUpload .apDropzone .files .file:last-child { border-bottom: none; }

.apFileUpload .apDropzone .files .file .info { opacity: 0.5; }
.apFileUpload .apDropzone .files .file .info svg {
    width: 16px;
    height: 16px;
    margin: -3px 5px 0 0;
}

.apFileUpload .apDropzone .files .file.uploaded .info { opacity: 1; }

.apFileUpload .apDropzone .files .file .uploadProgress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    -webkit-transition: opacity 0.6s ease;
       -moz-transition: opacity 0.6s ease;
        -ms-transition: opacity 0.6s ease;
         -o-transition: opacity 0.6s ease;
            transition: opacity 0.6s ease;
}
.apFileUpload .apDropzone .files .file.uploaded .apLoader,
.apFileUpload .apDropzone .files .file.uploaded .uploadProgress { opacity: 0; }

.apFileUpload .apDropzone .files .file .uploadProgress > div {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: rgba(76, 175, 80, 0.3);
}

.apFileUpload .apDropzone .files .file svg.removeButton {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0.5em;

    width: 28px;
    height: 28px;
    padding: 4px;
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apFileUpload .apDropzone .files .file .removeButton:hover {
    background: #eee;
    border-color: #aaa;
}


.apFileUpload.validator-success .apDropzone { border-bottom-color: #4caf50; }
.apFileUpload.validator-warning .apDropzone { border-bottom-color: #FF8800; }
.apFileUpload.validator-error .apDropzone   { border-bottom-color: #ff4444; }

