
#pageUserPermissions .guides {
	margin: 0 0 1em;
}
#pageUserPermissions .guides .guide {
	display: inline-block;
	font-size: 14px;
	padding: 0 2em 0 0;
}
#pageUserPermissions .guides .guide svg {
	width: 20px;
	height: 20px;
	margin: -3px 3px 0;
}

#pageUserPermissions .permissions {
	width: 100%;
	overflow: auto;
	border: 1px solid #ccc;
	background: #eee;
	padding: 0.5em;
}

#pageUserPermissions .permissions.loading {
	opacity: 0.5;
	pointer-events: none;
}

#pageUserPermissions .permissions table {
	width: auto;
	min-width: 100%;
	background: #fff;
	font-size: 14px;
}

#pageUserPermissions .permissions table th,
#pageUserPermissions .permissions table td {
	border: 1px solid #eee;
	padding: 0.2em;
}

#pageUserPermissions .permissions table thead th.permission,
#pageUserPermissions .permissions table tbody td.permission {
	text-align: left;
	min-width: 300px;
	padding: 0 1em;
}

#pageUserPermissions .permissions table thead th.user,
#pageUserPermissions .permissions table tbody td.user {
	text-align: center;
	min-width: 50px;
}

#pageUserPermissions .permissions table thead th.user .name {
	font-size: 14px;
	line-height: 1.2em;
	padding: 0 0.5em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

#pageUserPermissions .permissions table thead th.permission .onlyActiveSwitch {
	cursor: pointer;
	position: relative;
	padding: 0.5em 0.5em 0.5em 50px;
}

#pageUserPermissions .permissions table thead th.permission .onlyActiveSwitch .apSwitchWrapper {
	position: absolute;
	top: 50%;
	left: 5px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}



#pageUserPermissions .permissions table tbody tr.level0 {
	background: #f9f9f9;
	font-weight: bold;
}

#pageUserPermissions .permissions.onlyActive table thead th.user.notActive,
#pageUserPermissions .permissions.onlyActive table tbody td.user.notActive,
#pageUserPermissions .permissions.onlyActive table thead th.user.notLoggable,
#pageUserPermissions .permissions.onlyActive table tbody td.user.notLoggable { display: none; } 

#pageUserPermissions .permissions table thead th.user.notActive,
#pageUserPermissions .permissions table tbody td.user.notActive { background: #ffcdd2; }

#pageUserPermissions .permissions table thead th.user.notLoggable,
#pageUserPermissions .permissions table tbody td.user.notLoggable { background: #ffe0b2; }

#pageUserPermissions .permissions table thead tr.level0 th.user.notActive,
#pageUserPermissions .permissions table tbody tr.level0 td.user.notActive { background: #f2b5b5; }

#pageUserPermissions .permissions table thead tr.level0 th.user.notLoggable,
#pageUserPermissions .permissions table tbody tr.level0 td.user.notLoggable { background: #ffcc80; }


#pageUserPermissions .permissions table thead th.user .image { margin: 0.5em auto; }
#pageUserPermissions .permissions table thead th.user.notActive .image { border-color: #CC0000; }
#pageUserPermissions .permissions table thead th.user.notLoggable .image { border-color: #e65100; }