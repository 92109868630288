

.apInputSmallBlock input {
    width: 100%;
    margin: 0;
    padding: 0 30px 0 0;

    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    line-height: 30px;

    text-align: right;

}
.apInputSmallBlock input.left {
    text-align: left;
}


.apInputSmallBlock .unit {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 25px;
    line-height: 30px;
    margin-top: -15px;
    text-align: left;
    font-size: 12px;
    color: #aaa;

    overflow: hidden;
    text-overflow: ellipsis;
}

.apInputSmallBlock.focused input {
    outline: 0;
    border-color: #33b5e5;
}
.apInputSmallBlock.validator-error input {
    border-bottom-color: #ff4444;
}
.apInputSmallBlock.validator-warning  input {
    border-bottom-color: #FF8800;
}
.apInputSmallBlock.validator-loading  input {
    border-bottom-color: #DAA838;
}
.apInputSmallBlock.validator-success  input {
    border-bottom-color: #4caf50;
}

.apInputSmallBlock .apTooltip {
    display: block;
    position: absolute;
    z-index: 999999;
    bottom: calc( 100% + 10px );
    left: 50%;
    background: #000;

    color: #fff;
    font-size: 14px;
    line-height: 1.2em;
    white-space: nowrap;

    padding: 0.5em;
    pointer-events: none;
    opacity: 0;
    -webkit-transform: translate(-50%, 10px);
       -moz-transform: translate(-50%, 10px);
        -ms-transform: translate(-50%, 10px);
         -o-transform: translate(-50%, 10px);
            transform: translate(-50%, 10px);

    -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s ease;
            transition: transform 0.4s ease, opacity 0.4s ease;
}

.apInputSmallBlock .apTooltip:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #000;
    border-width: 5px;
    margin-left: -5px;
}

.apInputSmallBlock.focused .apTooltip {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
       -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
         -o-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

