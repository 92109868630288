


.audits {
    padding: 1em 1em 1em 0;
    position: relative;
}

.audits .timeline {
    position: absolute;
    top: 2em;
    bottom: 2em;
    left: 25px;
    width: 2px; 
    background: #ccc;
}

.audits .audit {
    margin: 0 0 1em 50px;
    position: relative;
}


.audits .audit:last-of-type { margin-bottom: 0; }


.audits .audit {
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

.audits .audit svg.auditIcon {
    position: absolute;
    top: 9px;
    left: -25px;
    width: 32px;
    height: 32px;
    padding: 8px;
    background: #999;
    fill: #fff;
    border-radius: 999px;

    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
}

.audits .audit .header {
    background: #fff;
    border-left: 3px solid #999;
    padding: 0.5em;
    padding-left: 60px;
    line-height: 1.2em;
    position: relative;

    -webkit-transition: background 0.4s ease;
       -moz-transition: background 0.4s ease;
        -ms-transition: background 0.4s ease;
         -o-transition: background 0.4s ease;
            transition: background 0.4s ease;
}
.audits .audit.hasDetails .header {
    cursor: pointer;
}

.audits .audit .header:hover { background: #f9f9f9; }

.audits .audit.green svg.auditIcon { background: #81c784; }
.audits .audit.green .header { border-left-color: #81c784; }
.audits .audit.red svg.auditIcon { background: #e57373; }
.audits .audit.red .header { border-left-color: #e57373; }
.audits .audit.purple svg.auditIcon { background: #9575cd; }
.audits .audit.purple .header { border-left-color: #9575cd; }

.audits .audit .header .user {
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.audits .audit .header .user .image {
    width: 40px;
    height: 40px;
    background: #f9f9f9;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 2px solid #ccc;
    border-radius: 999px;
}

.audits .audit .content {
    border-top: 1px solid #eee;
    background: #fff;
    padding: 1em;
}

.audits .audit .downloadLink {
    text-decoration: underline;
}

.audits .audit .content div strong {
    font-family: 'Roboto', Arial, sans-serif;
    color: #0099CC;
    font-size: 14px;
}

.audits .audit .content div small {
    font-size: 100%;
    color: #aaa;
}

.audits .detailRow {
    font-size: 0.8em;
}

.audits .detailRow .changeArrow {
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 12px;
    height: 12px;

}

.audits .description .cTitle {
    font-size: 0.9em;
    color: #33b5e5;
    margin-right: 0.5em;
}

