.apImageUpload {
	margin: 0 0 1em;
	border: 1px solid #ccc;
	border-bottom: 3px solid #ccc;
}

.apImageUpload .apLabel {
	padding: 0 10px;
	background: #f9f9f9;
	border-bottom: 1px solid #ccc;

	font-family: 'Roboto', Arial, sans-serif;
	font-size: 12px;
	line-height: 20px;
	color: #999;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.apImageUpload .apDropzone {
	background: #f9f9f9;
	width: 100%;
	overflow: hidden;
	padding: 0.5em 0;
}

.apImageUpload .apDropzone.active { background: #e3f2fd; border-color: #33b5e5; }
.apImageUpload .apDropzone.accept { background: #e8f5e9; border-color: #4caf50; }
.apImageUpload .apDropzone.rejected { background: #ffcdd2; border-color: #ff4444; }



.apImageUpload .apDropzone .uploadProgress {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background: rgba(76,175,80,0.3);
}

.apImageUpload .apDropzone .preview {
	width: 100%;
	height: 400px;
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}

.apImageUpload .apDropzone .preview .noImage {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	text-align: center;
	color: #999;
	cursor: pointer;
}
.apImageUpload .apDropzone .preview:hover .noImage { color: #666; }

.apImageUpload .apDropzone .changeButton,
.apImageUpload .apDropzone .clearButton {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0.5em;
	background: rgba(0,153,204,0.8);
	text-align: center;
	color: #fff;
	cursor: pointer;	

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;

	-webkit-transform: translateY(100%);
	   -moz-transform: translateY(100%);
	    -ms-transform: translateY(100%);
	     -o-transform: translateY(100%);
	        transform: translateY(100%);
}

.apImageUpload .apDropzone .changeButton:hover {
	background: rgba(0,153,204,1);
}

.apImageUpload .apDropzone .clearButton {
	bottom: auto;
	top: 0;
	left: auto;
	right: 0;
	width: auto;
	background: rgba(255,68,68,0.8);

	-webkit-transform: translateY(-100%);
	   -moz-transform: translateY(-100%);
	    -ms-transform: translateY(-100%);
	     -o-transform: translateY(-100%);
	        transform: translateY(-100%);
}

.apImageUpload .apDropzone .clearButton:hover {
	background: rgba(255,68,68,1);
}

.apImageUpload .apDropzone:hover .changeButton,
.apImageUpload .apDropzone:hover .clearButton {
	-webkit-transform: translateY(0);
	   -moz-transform: translateY(0);
	    -ms-transform: translateY(0);
	     -o-transform: translateY(0);
	        transform: translateY(0);
}