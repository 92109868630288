#reportsPage .apInputBlock .apSelect select.dateMode{
    background: #f5f5f5;
}
#reportsPage .reportDates table td:first-child {
    width: 200px;
}
#reportsPage .depthInputContainer {
    margin-bottom: 10px;
}

#reportsPage .buttonBar {
    display: flex;
    flex-direction: row;
}
#reportsPage .buttonBar button {
    margin-right: 0.5em;
}

#reportsPage .buttonBar .rightButton {
    margin-left: auto;
}

#reportsPage .item {
    cursor: pointer;
}

#reportsPage .open-date-picker {
    right: 0;
}

@media all and (max-width: 600px) {
    #reportsPage .buttonBar {
        flex-direction: column;
    }
    #reportsPage .buttonBar .rightButton {
        margin-left: 0;
    }
}