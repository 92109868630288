

#pageManagementUserTracking .block {
	margin: 0 0 2em;
}

#pageManagementUserTracking .block .rows {
	max-height: 300px;
	overflow: auto;
	border: 1px solid #ccc;
	background: #f9f9f9;
	padding: 0.5em;
}
#pageManagementUserTracking .block .rows table {
	font-size: 14px;
}
#pageManagementUserTracking .block .rows table th,
#pageManagementUserTracking .block .rows table td {
	max-width: 200px;
	padding: 0 0.5em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#pageManagementUserTracking .block .rows table th.idColumn { text-decoration: underline; color: #000; }