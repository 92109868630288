#pageArrivalEdit .supplierContainer {
    font-size: 0.8em;
    width: 48%;
    margin-right: 2%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#pageArrivalEdit .receiverContainer {
    font-size: 0.8em;
    width: 50%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#pageArrivalEdit .referenceContainer {
    font-size: 0.8em;
}

#pageArrivalEdit .rValue {
    float: right;
    font-weight: 700;
}

#pageArrivalEdit .rest-to-zero {
    float:right
}

#pageArrivalEdit .view-po {
    cursor: pointer;
    text-align: center;
}

#pageArrivalEdit .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#pageArrivalEdit .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }

#pageArrivalEdit .saveBar .left {
    display: inline-block;
    width: 50%;
    text-align: left;
}
#pageArrivalEdit .saveBar .right {
    display: inline-block;
    width: 50%;
    text-align: right;
}

#pageArrivalEdit .apFormColumn.fullColumn {
    overflow: visible;
}

@media screen and (max-width: 1300px) {
    #pageArrivalEdit #componentInstanceTable .componentTable {
        min-width: auto;
    }
}
