#OfferCalculation > .componentsListForm > .apBox {
    margin-top: 0;
}

#OfferCalculation .offerProjectTitle {
    padding: 1em;
}

#OfferCalculation .offerSheetTitle {
    text-align: right;
    float: right;
    padding: 2em 1em 1em 1em;
}

#OfferCalculation .projectedText {
    font-size: 0.8em;
    font-weight: 700;
}

#OfferCalculation .sheetContainer .apSimpleButton {
    width: 50px;
    height: 42px;
}


#OfferCalculation .saveBar {
    position: relative;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}

#OfferCalculation .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }

#OfferCalculation .saveBar .selectedRows {
    float: left;
    text-align: left;
}

#OfferCalculation .saveBar .selectedRows .list {
    position: absolute;
    bottom: calc( 100% + 10px );
    left: 0;
    padding: 1em;

    background: #e0f2f1;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);
    border-top: 3px solid #80cbc4;

    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
       -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
         -o-transform: translateY(10px);
            transform: translateY(10px);

    -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s ease;
            transition: transform 0.4s ease, opacity 0.4s ease;
}

#OfferCalculation .saveBar .selectedRows .list.show {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
        -ms-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
}

#OfferCalculation .saveBar .selectedRows .list .scroller {
    max-height: 50vh;
    overflow: auto;

}

#OfferCalculation .apTree .apBadge {
    position: absolute;
    top: 0;
    left: 0;
}

#OfferCalculation .apTree .moveToButton {
    position: absolute;
    top: 50%;
    right: 1em;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}





#offerSend {
}
#offerSend .sentsContainer {
    margin-bottom: 2em;
}
#offerSend .sentsContainer .apInfo {
    margin-top: 0.5em;
}

#offerSend .editOfferStepContainer{
    margin-bottom: 14px;
}

#offerSend .formHeader {
    margin-bottom: 1em;
}

#offerSend .formHeader svg {
    margin-right: 0.5em;
    width: 18px;
    height: 18px;
    -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease;
}

#offerSend .formHeader.open svg {
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

#confirmTypeChange .padding-small {
    padding: 0.5em;
}

#confirmTypeChange .red {
    font-weight: 700;
    color: #CC0000;
}
#confirmTypeChange .blue {
    font-weight: 700;
    color: #0099CC;
}
#confirmTypeChange .green {
    font-weight: 700;
    color: #007E33;
}

#confirmTypeChange .failswitch .apSwitchWrapper .apSwitch.on {
    background: #ff4444;
}
#confirmTypeChange .footer {
    float: right;
}
#confirmTypeChange .footer button {
    margin: 0 0.2em;
}

#OfferCalculation .saveBar .footerMenu {
    padding: 0.5em;
    margin-left: 0.2em;
}
#OfferCalculation .saveBar .apDropdown.open .apDropdownMenu {
    z-index: 100;
}


#offerSend {
}
#offerSend .sentsContainer {
    margin-bottom: 2em;
}
#offerSend .sentsContainer .apInfo {
    margin-top: 0.5em;
}

#offerSend .editOfferStepContainer{
    margin-bottom: 14px;
}

#offerSend .formHeader {
    margin-bottom: 1em;
}

#offerSend .formHeader svg {
    margin-right: 0.5em;
    width: 18px;
    height: 18px;
    -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease;
}

#offerSend .formHeader.open svg {
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

#confirmTypeChange .padding-small {
    padding: 0.5em;
}

#confirmTypeChange .red {
    font-weight: 700;
    color: #CC0000;
}
#confirmTypeChange .blue {
    font-weight: 700;
    color: #0099CC;
}
#confirmTypeChange .green {
    font-weight: 700;
    color: #007E33;
}

#confirmTypeChange .failswitch .apSwitchWrapper .apSwitch.on {
    background: #ff4444;
}
#confirmTypeChange .footer {
    float: right;
}
#confirmTypeChange .footer button {
    margin: 0 0.2em;
}

#OfferCalculation .sheetContainer {
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
}

#OfferCalculation .sheetContainer svg.icon {
    width: 20px;
    height: 20px;
    margin: -3px 5px 0 0;
}
#OfferCalculation .sheetContainer table.apInputStack .apAddon.noLeftBorder {
    padding-left: 0;
    padding-right: 0;
    font-family: 'Roboto-Light', Arial, sans-serif;
}

#OfferCalculation .sheetContainer .apSimpleButton {
    width: 50px;
    height: 42px;
}
#OfferCalculation .offerHeaderContainer {
    padding-bottom: 0;
}
#OfferCalculation .apModalWrapper .apModal .apModalBody {
    overflow: initial;
}
#OfferCalculation .componentEdit .apModalWrapper .apModal .apModalBody {
    overflow: auto;
}

/*
#offerBudget {
    min-height: 80vh;
    height: 80vh;
}
#offerBudget .offerContent {
    height: 100%;
}
*/


#offerBudget .massList .massListButton {
    float: left;
    margin: 0.5em;
}

#offerBudget .treeContainer {
    margin: 1em 0;
    background-color: #f5f5f5;
    padding: 0.2em 1em;
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
    box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
    border-radius: 10px;
}

#offerBudget .projectTable {
    width: 100%;
    margin-bottom: 20px;
}

#offerBudget .projectTable .projectTitle {
}

#offerBudget .projectTable .projectActions {
    top: 0;
    right: 0;
    float: right;
}

.ApListTreeContainer ul.apListTree {
    overflow: visible; /* Make dropdown work inside the tree */
}

/*
#offerBudget .ApListTreeContainer ul.apListTree > li .apListTreeItem {
padding: 0;
}
*/

#offerBudget .componentTable {
    width: 100%;
    font-size: 14px;
    line-height: 1.5em;
    background-color: #fff;
}

#offerBudget .componentTable.selectedComponentTable {
    background-color: transparent;
}



#offerBudget .componentTable tr.componentHeader,
#offerBudget .componentTable tr.component {
    border-bottom: 1px solid #ccc;
}

#offerBudget .componentTable tr.selected {
    background: #e1f5fe;
    border-left: 3px solid #03a9f4;
}

#offerBudget .componentTable tr.totals td {
    white-space: nowrap;
}

#offerBudget .componentTable th {
    font-size: 14px;
    vertical-align: bottom;
}

#offerBudget .componentTable tr.component > td {
    padding: 0.5em 0.2em;
}

#offerBudget .componentTable td.select { width: 20px; }
#offerBudget .componentTable td.icon { width: 20px; }
#offerBudget .componentTable td.name { width: auto; }
#offerBudget .componentTable td.fixed { width: 80px; min-width: 80px; max-width: 110px; }

#offerBudget .componentTable th.fixed,
#offerBudget .componentTable td.fixed {
    text-align: center;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}

#offerBudget .componentTable td.saveNew {
	white-space: nowrap;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}

td.saveNew div svg{
	float: left;
}

#offerBudget .componentTable td.fixed.menu {
    width:     45px;
    min-width: 45px;
    max-width: 45px;
}


#offerBudget .componentTable td input.componentNameInput {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 30px;

    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(0,0,0,0);

}

#offerBudget .componentTable td input.componentNameInput.error {
    color: #ff4444;
    border-bottom-color: #ff4444;
    border-bottom: 3px solid;
}


#offerBudget .componentTable td.fixed input.componentRowInput {
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0 30px 0 0;

    border: 1px solid #ccc;
    border-bottom-width: 3px;

    font-size: 14px;
    line-height: 30px;
}

#offerBudget .componentTable td.fixed input.componentRowInput.error {
    color: #ff4444;
    border-bottom-color: #ff4444;
}

#offerBudget .componentTable td.fixed input.componentRowInput.warning {
    color: #FF8800;
    border-bottom-color: #FF8800;
}

#offerBudget .componentTable td.fixed input.componentRowInput:focus {
    outline: 0;
    border-color: #33b5e5;
}

#offerBudget .componentTable td.fixed .unit {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 25px;
    line-height: 30px;
    margin-top: -15px;
    text-align: left;
    font-size: 12px;
    color: #aaa;

    overflow: hidden;
    text-overflow: ellipsis;
}

#offerBudget .componentTable td.fixed .calculatedCount {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    padding: 0.5em;
    border-top: 3px solid #2BBBAD;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    opacity: 0;
    pointer-events: none;

    -webkit-transform: translateY(-10px);
       -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
         -o-transform: translateY(-10px);
            transform: translateY(-10px);

    -webkit-transition: opacity 0.4s ease, transform 0.4s ease;
       -moz-transition: opacity 0.4s ease, transform 0.4s ease;
        -ms-transition: opacity 0.4s ease, transform 0.4s ease;
         -o-transition: opacity 0.4s ease, transform 0.4s ease;
            transition: opacity 0.4s ease, transform 0.4s ease;
}
#offerBudget .componentTable td.fixed input.componentRowInput:focus + .calculatedCount {

    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
        -ms-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);

    opacity: 1;
    pointer-events: auto;
}

#offerBudget .componentTable td.fixed .calculatedCount:after {
    bottom: 100%;
    left: 50px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(43, 187, 173, 0);
    border-bottom-color: #2BBBAD;
    border-width: 10px;
    margin-left: -10px;
}

#offerBudget .componentTable td.fixed .calculatedCount svg {
    width: 16px;
    height: 16px;
    margin: -3px 5px 0 0;
}

#offerBudget .componentTable td.fixed .calculatedCount small {
    color: #999;
}

#offerBudget .componentTable td svg {
    width: 20px;
    height: 20px;
}

#offerBudget .componentTable td.componentCode {
    font-weight: 700;
}

#offerBudget .componentTable td.componentName {
    white-space: nowrap;
}

#offerBudget .componentTable td.componentCount {

}

#offerBudget .componentTable td.componentPrice {
    padding-left: 1em;
    text-align: right;
}

#offerBudget .componentTable td.totalText {
    font-weight: 700;
    text-align: right;
    padding-right: 1em;
}

#offerBudget .componentTable td.totalValue {
    font-weight: 700;
    text-align: right;
    padding-left: 1em;
}

#offerBudget .calculatablesContainer {
    width: 100%
}

#offerBudget .calculatablesItem {
    width: 48%;
    display: inline-block;
}

#offerBudget .calcultableTotalsTable {

}

#offerBudget .calcultableTotalsTable th {
    text-align: center;
    padding: 0 0.5em;
    line-height: 1em;
}

#offerBudget .calcultableTotalsTable td {
    padding: 0.2em 0.5em;
}

#offerBudget .calcultableTotalsTable td.name { width: 200px; text-align: right; }
#offerBudget .calcultableTotalsTable td.bars { width: 200px; }
#offerBudget .calcultableTotalsTable td.difference { text-align: right; }
#offerBudget .calcultableTotalsTable td.unit { padding-left: 0; }



#offerBudget .calcultableTotalsTable td.bars .barContainer {
    position: relative;
    width: 100%;
    height: 5px;
    margin: 0 0 2px;
}

#offerBudget .calcultableTotalsTable td.bars .barContainer .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #2BBBAD;
}
#offerBudget .calcultableTotalsTable td.bars .barContainer .bar.all {
    opacity: 0.5;
}

#offerBudget .calcultableTotalsTable td.bars .barContainer.work .bar { background: #4285F4; }


#offerBudget .saveBar {
    position: relative;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}

#offerBudget .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }

#offerBudget .saveBar .selectedRows {
    float: left;
    text-align: left;
}

#offerBudget .saveBar .selectedRows .list {
    position: absolute;
    bottom: calc( 100% + 10px );
    left: 0;
    padding: 1em;

    background: #e0f2f1;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);
    border-top: 3px solid #80cbc4;

    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
       -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
         -o-transform: translateY(10px);
            transform: translateY(10px);

    -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s ease;
            transition: transform 0.4s ease, opacity 0.4s ease;
}

#offerBudget .saveBar .selectedRows .list.show {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
        -ms-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
}

#offerBudget .saveBar .selectedRows .list .scroller {
    max-height: 50vh;
    overflow: auto;
}

#offerBudget .offerContent {
    background: #eee;
    padding: 1em 0.5em 0.5em;
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
}

#offerBudget .offerContent:after {
    top: 0;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 10px;
    margin-left: -10px;
}

#offerBudget .splitView:after {
    content: '';
    display: block;
    clear: both;
}

#offerBudget .splitView .left,
#offerBudget .splitView .right {
    float: left;
    width: 300px;
}

#offerBudget .splitView .right {
    width: calc( 100% - 300px );
}

#offerBudget .splitView .details {
    margin-left: 1em;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

#offerBudget .details .projectPath {
    margin: 0;
}

#offerBudget .details .projectPath .inner.current {
    position: relative;
    padding-right: 50px;
}

#offerBudget .details .projectPath .inner.current .apDropdown {
    position: absolute;
    z-index: 99;
    top: 50%;
    right: 0;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}




.apProjectTree {
    margin: 0;
}

.apProjectTree .detailPicker .apInputBlock .apSelect {
    background: #fff;
}

.apProjectTree .detailPicker table.apInputStack .apAddon.noRightBorder {
    padding-left: 0;
    padding-right: 0;
    font-family: 'Roboto-Light', Arial, sans-serif;
}

.apProjectTree .apSimpleButton {
    width: 50px;
    height: 42px;
}

/*
.apProjectTree ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.apProjectTree ul.subproject { padding-left: 1em; border-left: 1px dashed #ccc; }

.apProjectTree .project {
    border-left: 3px solid #ccc;
    border-right: 3px solid #fff;
    background: #fff;
    padding: 0.5em 1em;
    margin: 3px 0;

    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.2em;
    font-size: 14px;
    position: relative;

    cursor: pointer;
}

.apProjectTree ul.root > li > .project { border-left-color: #999; }

.apProjectTree .project:hover {
    border-right-color: #ccc;
}

.apProjectTree .project.selected {
    border-right-color: #33b5e5;
}
.apProjectTree .project.selected:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 181, 229, 0);
    border-left-color: #33b5e5;
    border-width: 10px;
    margin-top: -10px;
}

.apProjectTree .project.withIcon { padding-left: 32px; }

.apProjectTree .project svg.icon {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 16px;
    height: 16px;
    margin-top: -8px;
}

.apProjectTree .project small {
    color: #999;
    font-size: 12px;
    font-family: 'Roboto-Light', Arial, sans-serif;
}

.apProjectTree .project .apBadge {
    position: absolute;
    top: 0;
    left: 0;
}

.apProjectTree .project .moveToButton {
    position: absolute;
    top: 50%;
    right: 1em;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apProjectTree .project .value {
    position: absolute;
    top: 50%;
    right: 0;

    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    border-left: 1px solid #fff;
    opacity: 0.5;

    padding: 0.5em ;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);

    -webkit-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
       -moz-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
        -ms-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
         -o-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
            transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
}

.apProjectTree .project:hover .value {
    opacity: 1;
    background: #fff;
    border-left-color: #ccc;
}
*/

#offerBudget .relatedWorkContainer {
    background-color: #fff;
    margin-top: 0.2em;
}
#offerBudget .relatedWorkContainer .rText {
    padding: 0.5em;
}

#offerBudget .relatedWorkContainer .rTitle {
    color: #0099CC;
    border-bottom: 1px solid #0099CC;
    margin-bottom: 0.5em;

    padding-top: 0.2em;
    padding-left: 1em;
    padding-bottom: 0.2em;
}


#offerBudget .filterBar {
    width: 100%;
    height: 50px;
    background: #f9f9f9;
    border-bottom: 3px solid #ccc;
    position: relative;
    z-index: 1;

    -webkit-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
       -moz-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
        -ms-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
         -o-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
            transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
}

#offerBudget .filterBar .apOptionBar {
    margin: 6px;
}

#offerBudget .filterBar .apOptionBar.smallerIcons svg {
    width: 16px;
    height: 16px;
}

#offerBudget .filterBar .viewsTitle {
    display: inline-block;
    line-height: 50px;
    float: left;
    margin-left: 1em;
}

#offerBudget .filterBar .viewsSelect {
    display: inline-block;
    float: left;
}

#offerBudget .filterBar .rightFilter {
    display: inline-block;
    float: right;
    padding-right: 1em;
}

#offerBudget .filterBar .rightFilter .filter {
    float: right;
    width: auto;
    height: 100%;
    position: relative;
}


#offerBudget .filterBar .rightFilter .filter.title {
    line-height: 50px;
    font-size: 14px;
    padding: 0 0em;
}

#offerBudget .filterBar .rightFilter .filter.title .apBadge {
    position: absolute;
    top: 5px;
    left: 5px;
    background: #FF8800;
}


#offerBudget .filterBar .rightFilter  .calcFilter {
    position: relative;
}

#offerBudget .filterBar .rightFilter  .calcFilter .apBadge {
    position: absolute;
    top: -2px;
    right: -10px;
    background: #0099CC;
    z-index: 4;
}

#offerBudget .componentTable th.calcHeader svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

#offerBudget .filterBar .calcSelect {
    position: relative;
}

#offerBudget .filterBar .calcSelect.selected .unit ,
#offerBudget .filterBar .calcSelect.selected {
    color: #0099CC;
    font-weight: 700;
}

#offerBudget .filterBar .calcSelect .unit {
    font-size: 0.8em;
    color: #AAA;
    position: absolute;
    right: 6px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);

}

#offerBudget .componentTable td.icon svg.outsideStorage {
    color: #DDD;
}

#offerContainer {
    display: flex;
    justify-content: space-between;
}

#offerContainer .offerPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#offerContainer .leftPanel{
    margin-right: 2em;
}

#offerBudget .groupRow {
    background-color: #f2f2f2;
    padding: 0.5em 0.2em;

    
    
}
#offerBudget .groupRow:not(:first-child){
    border-top: dimgray solid 2px;
}
#offerBudget .groupRow input {
    background-color: #ffffff;
    
}
#offerBudget .groupRow input:disabled {
    background-color: #fafafa;
    
}
#offerBudget .groupRowComponent {
    background-color: #f2f2f2;
    padding: 0.5em 0.2em;

}

#offerBudget .groupRowComponent input {
    background-color: #ffffff;

}

#offerBudget .groupRowComponent input:disabled {
    background-color: #fafafa;

}


/* #offerBudget .componentTable tr td.groupTd {
  min-width: 24px;
  max-width: 24px;
  width: 24px;
}

#offerBudget .componentTable tr.inGroup td.groupTd {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fafafa;
}

#offerBudget .componentTable tr.inGroup:not(.groupLast) td.groupTd {
  border-bottom: 0;
} */

#offerBudget .componentTable tr.groupRow td.groupTd {
  -webkit-transition: transform 0.4s ease;
  -moz-transition: transform 0.4s ease;
  -ms-transition: transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
}

#offerBudget .componentTable > tbody > tr.groupRow.open td.groupTd {
  border-bottom: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

#OfferCalculation ul.apSteps li.step {
    max-width: 300px;
}

@media (max-width: 1450px) {
    #offer-calculation-container {
        overflow: auto;
    }
}