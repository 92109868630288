
.ApModalWizard .wizardFooter,
.ApModalWizard .wizardHeader {
    padding: 0.5em;
}
.ApModalWizard .wizardFooter .prev {
    display: inline-block;
    width: 40%;
}

.ApModalWizard .wizardFooter .next {
    float: right;
    width: 40%;
}

.ApModalWizard .wizardFooter .apButton {
    margin: 0;
    width: 100%;
}

.ApModalWizard .apModalWrapper .apModal .apModalFooter {
    overflow: visible;
}
