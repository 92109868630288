.idleScreen {
    position: fixed;
    z-index: 999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);

    opacity: 0;
    pointer-events: none;

    -webkit-transition: opacity 0.4s ease;
       -moz-transition: opacity 0.4s ease;
        -ms-transition: opacity 0.4s ease;
         -o-transition: opacity 0.4s ease;
            transition: opacity 0.4s ease;
}

.idleScreen.showWarning {
    opacity: 1;

    -webkit-transition-duration: 1s;
       -moz-transition-duration: 1s;
        -ms-transition-duration: 1s;
         -o-transition-duration: 1s;
            transition-duration: 1s;
}

.idleScreen .message {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 400px;

    background: #fff;
    padding: 2em;

    text-align: center;

    box-shadow: 0 0 30px rgba(0,0,0,0.5);

    -webkit-transform: translate(-50%,-50%);
       -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
         -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

.idleScreen .message h2 {
    margin-top: 0;
    color: #FF8800;
}