
svg.SvgIcon {
	display: inline-block;
	fill: currentColor;
	width: 24px;
	height: 24px;
	vertical-align: middle;
}

svg.SvgIcon.block {
	display: block;
	margin: 0 auto;
}

svg.SvgIcon.size-small {
	width: 16px;
	height: 16px;
}

svg.SvgIcon.size-medium {
	width: 20px;
	height: 20px;
}

svg.SvgIcon.size-large {
	width: 32px;
	height: 32px;
}

svg.SvgIcon.size-huge {
	width: 48px;
	height: 48px;
}


svg.SvgIcon.small-inline {
	margin: -3px 5px 0 0;
	width: 16px;
	height: 16px;
}