#new-transport-modal #componentInstanceTable .componentTable {
    min-width: auto;
}

#new-transport-modal .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}
#new-transport-modal .apModalWrapper .apModal .apModalBody .componentSelectionStep {
    overflow-x: auto;
    max-height: calc( 90vh - 300px );
}
#new-transport-modal .apModalWrapper .apModal .apModalBody #componentInstanceTable {
    min-height: 35vh;
    overflow: auto;
}
#new-transport-modal #componentInstanceTable td.fixed.c-target {
    width: 160px;
    min-width: 140px;
    max-width: 160px;
}
