
.holidaySections {
	background: #eee;
}

.holidaySections:after {
	content: '';
	display: block;
	clear: both;
}

.holidaySections .section {
	float: left;
	width: 33%;
	margin-right: 0.5%;
	background: #f9f9f9;
	line-height: 1.2em;
	color: #999;
	border-top: 3px solid #ccc;
	cursor: pointer;
	position: relative;
}

.holidaySections .section:last-child { margin-right: 0; }

.holidaySections .section:hover {
	background: #fff;
}

.holidaySections .section.selected { 
	background: #fff; 
	border-top-color: #33b5e5;
	color: #666;
	cursor: normal;
}

.holidaySections .section.disabled {
	color: #ccc;
	background: #e9e9e9;
	border-top-color: #ddd;
	cursor: not-allowed;
}

.holidaySections .section .padding {
	padding-left: 50px;
}

.holidaySections .section svg {
	position: absolute;
	top: 50%;
	left: 15px;
	width: 24px;
	height: 24px;
	margin-top: -12px;
}

.holidaySections .section strong {
	display: block;
	font-size: 18px;
}

.holidaySections .section small {
	font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
