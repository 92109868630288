.apSwitchWrapper {
	position: relative;
	overflow: hidden;
}

.apSwitchWrapper.inline {
	display: inline-block;
	vertical-align: middle;
	margin: 0 0.5em;
}

.apSwitchWrapper input {
	position: absolute;
	top: -50px;
	left: -50px;
}

.apSwitchWrapper .apSwitch {
	display: inline-block;
	width: 60px;
	height: 30px;
	margin: 0;
	vertical-align: middle;
	background: #ddd;
	position: relative;

	-webkit-border-radius: 999px;
			border-radius: 999px;

	-webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
			box-shadow: inset 0 0 10px rgba(0,0,0,0.5);

	overflow: hidden;
	cursor: pointer;

	-webkit-transition: background 0.4s ease;
	   -moz-transition: background 0.4s ease;
	    -ms-transition: background 0.4s ease;
	     -o-transition: background 0.4s ease;
	        transition: background 0.4s ease;
}

.apSwitchWrapper .apSwitch.small {
	width: 40px;
	height: 20px;
}

.apSwitchWrapper .apSwitch.on {
	background: #00C851;
}

.apSwitchWrapper .apSwitch .apSwitchLabel {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 10px;
	right: 10px;
	pointer-events: none;

	font-size: 12px;
	color: rgba(0,0,0,0.5);

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	-webkit-transition: opacity 0.4s ease;
	   -moz-transition: opacity 0.4s ease;
	    -ms-transition: opacity 0.4s ease;
	     -o-transition: opacity 0.4s ease;
	        transition: opacity 0.4s ease;
}

.apSwitchWrapper .apSwitch .apSwitchLabel.off { text-align: right; }

.apSwitchWrapper .apSwitch.on .apSwitchLabel.on,
.apSwitchWrapper .apSwitch .apSwitchLabel.off { opacity: 1; }

.apSwitchWrapper .apSwitch .apSwitchLabel.on,
.apSwitchWrapper .apSwitch.on .apSwitchLabel.off { opacity: 0; }


.apSwitchWrapper .apSwitch .apSwitchHandler {
	position: absolute;
	z-index: 5;
	top: 5%;
	left: 5%;
	width: 45%;
	height: 90%;
	background: #eee;

	-webkit-border-radius: 999px;
			border-radius: 999px;

	-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.5);			
			box-shadow: 0 0 10px rgba(0,0,0,0.5);

	-webkit-transition: left 0.4s ease;
	   -moz-transition: left 0.4s ease;
	    -ms-transition: left 0.4s ease;
	     -o-transition: left 0.4s ease;
	        transition: left 0.4s ease;
}

.apSwitchWrapper input:focus + .apSwitch { border: 2px solid #0099CC; }

.apSwitchWrapper .apSwitch.on .apSwitchHandler { left: 50%; }

.apSwitchWrapper .apSwitch.disabled { 
	opacity: 0.5; 
	cursor: not-allowed;
}
.apSwitchWrapper .apSwitch.disabled .apSwitchHandler { opacity: 0.5; }

