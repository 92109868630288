
#pageCrmGroups .list {
	margin: 0 0 0.5em;
	border: 1px solid #ccc;
	height: 40vh;
	min-height: 200px;
	overflow: auto;
	background: #eee;
	padding: 0.5em;
	padding-bottom: 100px;
	position: relative;
}

#pageCrmGroups .list .empty {
	padding: 1em;
}

#pageCrmGroups .list .item {
	background: #fff;
	margin: 0 0 0.5em;
	padding: 0.5em;
	padding-left: 35px;
	line-height: 1.2em;
	border-left: 3px solid #ccc;
	position: relative;
}

#pageCrmGroups .list .item:hover { border-left-color: #33b5e5; }
#pageCrmGroups .list .item.unsaved { border-left-color: #FF8800; }


#pageCrmGroups .list .item.removed {
	background: #f9f9f9;
	color: #aaa;
	text-decoration: line-through;
}

#pageCrmGroups .list .item .actions {
	float: right;
	margin: 3px 0 0 0;
}

#pageCrmGroups .list .item svg.icon {
	position: absolute;
	top: 50%;
	left: 10px;
	width: 16px;
	height: 16px;
	margin-top: -8px;
}

#pageCrmGroups .list .item svg.unsavedIcon {
	position: absolute;
	top: 50%;
	right: 60px;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	fill: #FF8800;
}