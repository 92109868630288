.apListTable {
	width: 100%;
	height: auto;	

	font-size: 15px;
	line-height: 1.2em;

	position: relative;
}

.apListTable .multiselectActions {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	min-width: 200px;
	background: rgba(0,105,92,0.95);
	color: #fff;

	pointer-events: none;
	opacity: 0;

	-webkit-transform: translateY(-100%);
	   -moz-transform: translateY(-100%);
	    -ms-transform: translateY(-100%);
	     -o-transform: translateY(-100%);
	        transform: translateY(-100%);

	-webkit-transition: opacity 0.4s ease, top 0.2s ease;
	   -moz-transition: opacity 0.4s ease, top 0.2s ease;
	    -ms-transition: opacity 0.4s ease, top 0.2s ease;
	     -o-transition: opacity 0.4s ease, top 0.2s ease;
	        transition: opacity 0.4s ease, top 0.2s ease;
}

.apListTable.rowsSelected .multiselectActions {
	opacity: 1;
	pointer-events: auto;
}

.apListTable .multiselectActions:after {
	top: 100%;
	left: 26px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 105, 92, 0);
	border-top-color: rgba(0,105,92,0.95);
	border-width: 10px;
	margin-left: -10px;
}

.apListTable .multiselectActions .padding {
	padding: 0;
}

.apListTable .multiselectActions .text { 
	color: #fff;
	line-height: 30px;
	padding: 0 0.5em;
	background: #2BBBAD;
}

.apListTable .multiselectActions .cancel { 
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
}

.apListTable .multiselectActions .cancel:hover { opacity: 0.8; } 

.apListTable .multiselectActions .cancel svg {
	width: 30px;
	height: 30px;
	padding: 4px;
} 

.apListTable .multiselectActions .action { 
	display: inline-block;
	border: 1px solid rgba(255,255,255,0.4);
	border-radius: 5px;
	margin: 5px;
	padding: 5px 10px;
}

.apListTable .multiselectActions .action svg {
	width: 16px;
	height: 16px;
	margin: -3px 5px 0 0;
}

.apListTable .multiselectActions .action:hover {
	border-color: #fff;
	background-color: rgba(255,255,255,0.1);
} 

.apListTable table.list {
	width: 100%;
}

.apListTable table.list thead tr {
	background: #eee;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.apListTable table.list thead .cell {
	padding: 0.7em 1em;
	position: relative;
	white-space: nowrap;
}

.apListTable table.list thead .cell.sortable:hover {
	background: rgba(255,255,255,0.5);
	cursor: pointer;
	-webkit-user-select: none;
       -moz-user-select: none;
    	-ms-user-select: none;
    		user-select: none;
}

.apListTable table.list thead .cell.sorted {
	background: rgba(255,255,255,0.5);
}

.apListTable table.list thead .cell svg.sortArrow {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 16px;
	height: 16px;
	background: #f7f7f7;
	padding: 2px;
	margin-top: -8px;
	border-radius: 99px;
	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;
}

.apListTable table.list thead .cell svg.sortArrow.reverse {
	-webkit-transform: rotate(180deg);
	   -moz-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	     -o-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.apListTable table.list thead .cell.align-right svg.sortArrow { right: auto; left: 10px; }


.apListTable table.list thead .cell.group {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
}

.apListTable table.list thead .cell.group svg.colGroupArrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 100%;
	padding: 12px 0;
	cursor: pointer;
}

.apListTable table.list thead .cell.group svg.colGroupArrow.next { left: auto; right: 0; }

.apListTable table.list thead .cell.group .colGroupLabel {
	text-align: center;
	-webkit-user-select: none;
       -moz-user-select: none;
    	-ms-user-select: none;
    		user-select: none;
}

.apListTable table.list thead .cell.group .colGroupList {
	position: absolute;
	bottom: 2px;
	left: 50%;
	
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.apListTable table.list thead .cell.group .colGroupList .item {
	float: left;
	width: 5px;
	height: 5px;
	margin: 1px 2px;
	background: #ccc;
	border-radius: 999px;
}

.apListTable table.list thead .cell.group .colGroupList .item.selected { background: #33b5e5; }

.apListTable table.list tbody .cell {
	padding: 0.5em 1em;
}

.apListTable table.list .cell.clickableRow { cursor: pointer; }
.apListTable table.list .cell.clickable { color: #0099CC; cursor: pointer; }
.apListTable table.list .cell.clickable:hover { color: #33b5e5; }

.apListTable table.list tbody tr {
	background: #fff;
	border-bottom: 1px solid #eee;
}

.apListTable table.list .cell.multiselect {
	border-right: 1px solid #eee;
	border-left: 3px solid transparent;
	cursor: pointer;
}

.apListTable table.list thead .cell.multiselect {
	border-right-color: #ccc;
}

.apListTable table.list tr.selected { background: #e0f2f1; border-color: #b2dfdb; }
.apListTable table.list tr.selected .cell.multiselect {
	border-left-color: #00695c;
	border-right-color: #b2dfdb;
}

.apListTable .rowAction {
	cursor: pointer;
	padding: 5px;
}

.apListTable .rowAction svg {
	width: 20px;
	height: 20px;
}

.apListTable .rowActions .action {
	text-align: left;
	padding: 10px;
	cursor: pointer;
}
.apListTable .rowActions .action:hover { background: #eee; }

.apListTable .rowActions .action svg {
	width: 20px;
	height: 20px;
	margin: -3px 5px 0 0;
}

/*
.apListTable table.list tr.status-success .cell:first-child		{ border-left: 3px solid #007E33; }
.apListTable table.list tr.status-warning .cell:first-child		{ border-left: 3px solid #FF8800; }
.apListTable table.list tr.status-error .cell:first-child		{ border-left: 3px solid #CC0000; }
.apListTable table.list tr.status-disabled .cell:first-child 	{ border-left: 3px solid #ccc; }
*/

.apListTable table.list .cell.apHidden { display: none; }

.apListTable table.list .cell.align-right { text-align: right; }
.apListTable table.list .cell.align-center { text-align: center; }


.apListTable .mobileList {
	width: 100%;
	display: none;
}

.apListTable .mobileList .cell {
	width: 100%;
	border-bottom: 1px solid #eee;
	padding: 0.5em;
	position: relative;
}

.apListTable .mobileList.multiselectAvailable .cell { padding-left: 30px; }
.apListTable .mobileList.rowActionsAvailable .cell { padding-right: 60px; }


.apListTable .mobileList .cell .multiselect {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	width: 27px;
	border-left: 3px solid transparent;
}

.apListTable .mobileList .cell .multiselect input {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0;
	display: block;
	-webkit-transform: translate(-50%,-50%);
	   -moz-transform: translate(-50%,-50%);
	    -ms-transform: translate(-50%,-50%);
	     -o-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
}

.apListTable .mobileList .cell.selected  { background: #e0f2f1; border-color: #b2dfdb; }
.apListTable .mobileList .cell.selected .multiselect { border-left-color: #00695c; }

.apListTable .mobileList .cell .actions { 
	position: absolute;
	top: 50%;
	right: 0;

	margin-top: -16px; /* some how transform: translateY(-50%) messes up the z-index :( */;
}


@media all and (max-width: 800px) {

	.apListTable { font-size: 14px; }

	.apListTable table.list .cell {
		padding: 0.5em 0.5em;
	}

	.apListTable table.list thead .cell.group {
		padding-left: 25px;
		padding-right: 25px;
	}
	.apListTable table.list thead .cell.group svg.colGroupArrow { width: 25px; padding: 14px 0; }

}


@media all and (max-width: 600px) {

	.apListTable table.list { display: none; }
	.apListTable .mobileList { display: block; }

	.apListTable .multiselectActions:after { left: 15px; }

}
