#codeFormat {

}

#codeFormat .previewContainer {
    background: #eee;
    padding: 2em 0.5em 0.5em;
    -webkit-box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
    margin-bottom: 2em;
    text-align: center;
}

#codeFormat .freeText,
#codeFormat .previewX {
    font-size: 2em;
    font-weight: 700;
}
#codeFormat .previewSeparator {
    font-size: 2em;
    font-weight: 700;
    margin: 0 6px;
}

#codeFormat .previewGroup {
    display: inline-block;
    margin: 0 2px;
}

#codeFormat .previewGroupName {
    border-bottom: 1px solid #999;
}

/* #codeFormat .editContainer { */
    /* padding: 1em; */
/* } */

#codeFormat .editContainer {
    background: #fafafa;
    padding: 2em 1em 1em;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
}


#codeFormat .top-left {
    font-size: 0.8em;
    position: absolute;
    left: 4px;
    top: 4px;
}
#codeFormat .top-right {
    font-size: 0.8em;
    position: absolute;
    right: 4px;
    top: 4px;
}


#codeFormat .levelsTable {
    width: 100%;
}
/* #codeFormat .levelsTable th, */
/* #codeFormat .levelsTable td { */
    /* border: 1px solid black; */
/* } */

#codeFormat .levelsTableHeader {
    font-weight: 700;
    font-size: 1.2em;
}

#codeFormat .titleCol {
    width: 150px;
    max-width: 150px;
    min-width: 150px;

    font-weight: 700;
    font-size: 1.2em;
}
#codeFormat .nameCol {
    padding-top: 0.5em;
    padding-right: 1em;

}
#codeFormat .lengthCol {
    padding-top: 0.5em;
    width: 150px;
    max-width: 150px;
    min-width: 150px;
}
#codeFormat .removeCol {
    text-align: right;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

#codeFormat .addCol {
    padding-top: 0.5em;
}

#codeFormat .levelRow {
    border-bottom: 1px solid #ccc;
}

