#pageExcelImport .tableScroller {
	width: auto;
	height: auto;
	max-height: 400px;
	margin: 0 0 1em;
	background: #f9f9f9;
	overflow: scroll;
	-webkit-box-shadow:inset 0 0 10px 0 rgba(0,0,0,0.5);
			box-shadow:inset 0 0 10px 0 rgba(0,0,0,0.5);
}

#pageExcelImport table.import {
	font-size: 14px;
	line-height: 1em;
}

#pageExcelImport table.import td {
	padding: 0.5em;
	border: 1px solid #ccc;
	width: 150px;
	min-width: 150px;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

#pageExcelImport table.import td.rowNum {
	background: #eee;
	text-align: center;
	width: auto;
	min-width: 0;
	max-width: none;
	position: relative;
}
#pageExcelImport table.import tr.imported td.rowNum {
	color: #eee;
}
#pageExcelImport table.import td.rowNum svg.imported {
	position: absolute;
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
	top: 50%;
	left: 50%;
	fill: #007E33;
}
#pageExcelImport table.import tr.importError td.rowNum {
	background: #ff4444;
	color: #ff4444;
	overflow: visible;
}
#pageExcelImport table.import td.rowNum .apTooltipper {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
#pageExcelImport table.import td.rowNum .apTooltipper svg.importError {
	width: 16px;
	height: 16px; 
	fill: #fff; 
}

#pageExcelImport table.import tr.newRow td.idColumn {
	background: #e8f5e9;
	color: #388e3c;
}
#pageExcelImport table.import tr.newRow td.idColumn:after {
	content: ' (uusi)';
}
#pageExcelImport table.import.skipNewRows tr.newRow td,
#pageExcelImport table.import.skipOldRows tr.oldRow td {
	text-decoration: line-through;
	background: rgba(0,0,0,0.05);
	color: #999;
}

#pageExcelImport table.import tr.importError td,
#pageExcelImport table.import tr.error td {
	background: #ffcdd2;
	color: #b71c1c;
}

#pageExcelImport table.import tr.header td {
	font-family: 'Roboto', Arial, sans-serif;
	background: #eee;
}

#pageExcelImport table.import td.skippedCol {
	background: #ffebee;
	color: #b71c1c;
}

#pageExcelImport table.import tr.header td.skippedCol {
	background: #ffcdd2;
}

#pageExcelImport table.import tr td.error {
	background: #ff4444;
	color: #fff;
	overflow: visible;
}

#pageExcelImport table.import tr td.defaultValue {
	color: #999;
}
#pageExcelImport table.import tr td.defaultValue:after { 
	content: ' (default)';
	font-size: 11px; 
}

#pageExcelImport table.import tr td.idColumn {
	background: #e1f5fe;
	color: #01579b;
}

#pageExcelImport table.import tr.header td.idColumn {
	background: #b3e5fc;
}

#pageExcelImport table.import tr td.error svg.errorInfo {
	width: 16px;
	height: 16px;
	vertical-align: middle;
	margin: -3px 5px 0;
}