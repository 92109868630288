#mobileNav {
    position: fixed;
    z-index: 500;
    top: 50px;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #eee;
    border-right: 1px solid #ccc;
    color: #666;

    transform: translateX(-100%);
    /* transition: transform 0.4s ease; */
}

#mobileNav > .top,
#mobileNav > .bottom {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ccc;
}

#mobileNav > .bottom {
    border-bottom: none;
    border-top: 1px solid #ccc;
    text-align: right;
    position: relative;
}

#mobileNav> .top .searchField {
    position: relative;
    margin: 0 5px;
}

#mobileNav> .top .searchField input {
    width: 100%;
    height: 40px;
    padding: 0 1em 0 45px;
    line-height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px 0;
    background: #f9f9f9;

    /* -webkit-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
       -moz-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
        -ms-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
         -o-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
            transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease; */
}

#mobileNav> .top .searchField input:focus {
    background: #fff;
    outline: 0;
}

#mobileNav> .top .searchField svg {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 20px;
    height: 20px;
    fill: #999;
    pointer-events: none;
}

#mobileNav > .bottom .toggleButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background: #ddd;
    border-left: 1px solid #ccc;
    cursor: pointer;
}

#mobileNav > .bottom .toggleButton:hover { background: #f9f9f9; }

#mobileNav > .bottom .toggleButton svg {
    display: block;
    width: 20px;
    height: 20px;
    margin: 15px auto;

    /* -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease; */
}

#mobileNav > .middle {
    width: 100%;
    height: calc(100% - 50px );
}

#mobileNav.open { 
    transform: translateX(0);
}

#mobileNav.open::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 50px rgba(0,0,0,0.5);
}