
#pageTimetrackingTimeAttendance .ValueEdited {
    
    background-color: bisque;
}

#pageTimetrackingTimeAttendance .inprogress {

    background-color: rgb(179, 230, 179);
}
#pageTimetrackingTimeAttendance .rt-tr-group:nth-child(2n) .inprogress {
        background-color: rgb(146, 218, 146);
}
#pageTimetrackingTimeAttendance .rt-tr-group:nth-child(2n) .inprogress.ValueEdited {
    background-color: rgb(165, 203, 105);
}
#pageTimetrackingTimeAttendance .inprogress.ValueEdited {

    background-color: rgb(194, 245, 107);
}