.modalBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.member-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.members-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.error-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #ff4444;
}