svg.arrow {
  -webkit-transition: transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
  -ms-transition: transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
}

svg.arrow.open {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.loading-mask {
  position: relative;
}

.loading-mask::before {
  content: "";
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  opacity: 0.6;
}

.loading-mask::after {
  content: "";
  z-index: 99;
  position: absolute;
  border-width: 3px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));

  -webkit-animation: apSpinAnimation 1s infinite;
  -moz-animation: apSpinAnimation 1s infinite;
  -o-animation: apSpinAnimation 1s infinite;
  -ms-animation: apSpinAnimation 1s infinite;
  animation: apSpinAnimation 1s infinite;
}

@keyframes apSpinAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes apSpinAnimation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes apSpinAnimation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-o-keyframes apSpinAnimation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes apSpinAnimation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}

.sticky {
  position: sticky;
  top: 50px;
  z-index: 90;
  border-top: 2px solid #bbb;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.tooltip {
  position: relative;
  /* color: black; */
  color: hsl(0, 0%, 100%);
  opacity: 1;
  cursor: default;
  z-index: unset;
  font: inherit;
  font-size: 13px;
}

.tooltip:hover {
  color: hsl(0, 0%, 95%);
}

.tooltip-span {
  display: none;
  z-index: 99 !important;
}

.tooltip:hover .tooltip-span {
  color: white;
  padding: 5px;
  background-color: black;
  border-radius: 3px;
  display: block;
  position: fixed;
  overflow: hidden;
  z-index: 99;
}

.tooltip-total-time {
  font-size: 15px;
  font-weight: bold;
  color: whitesmoke;
}

span svg.size-tiny {
  width: 16px;
  height: 16px;
}

.react-calendar-timeline {
  min-width: 1100px;
}

.react-calendar-timeline
  .rct-sidebar.rct-sidebar-right
  .rct-sidebar-row.rct-sidebar-row-even,
.react-calendar-timeline
  .rct-sidebar.rct-sidebar-right
  .rct-sidebar-row.rct-sidebar-row-odd {
  padding: 0;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: rgba(250, 246, 190, 0.5);
}

.project-title:hover {
  text-decoration: underline;
}

.hideProject {
  opacity: 0.5;
  cursor: pointer;
}

.hideProject:hover {
  opacity: 1;
}

.overflow {
  max-height: calc(90vh - 200px);
  overflow: visible;
}

.personnel-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.personnel-item-container span {
  margin-left: 5px;
}

.guide-container span {
  padding-left: 10px;
}

.tooltip-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 0;
  border-bottom: 1px solid hsla(0, 0%, 50%, 0.2);
}

.tooltip-row span:first-child {
  margin-right: 15px;
}

.zoom-btn-container {
  width: 190px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.guide-color-box {
  width: 15px;
  height: 15px;
}
.guide-ok {
  background-color: hsl(195, 100%, 40%);
}
.guide-warning {
  background-color: var(--clr-warning-main);
}
.guide-incomplete {
  background-color: hsl(195, 100%, 25%);
}
.guide-plans {
  background-color: var(--clr-blue-main);
}
.guide-entries {
  background-color: var(--clr-success-dark);
}

.invalid .rdt input {
  border-bottom-color: red;
}

.work-remaining-container p {
  margin: 0;
  text-align: right;
}

#personnel-modal .apModalBody {
  overflow: inherit;
}
