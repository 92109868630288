
#newOfferDeclineFooter {
	padding: 1em;
	text-align: right;
}

#newOfferDeclineFooter .apButton {
	margin: 0;
}

#newOfferDeclineFooter .apButton.cancel {
	float: left;
}