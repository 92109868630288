

#pageTimetrackingPayroll .customFooter {
	padding: 1em;
	/* margin: 1em 0 0; */
	border-top: 2px solid #eee;
	background: #fff;
	position: relative;
	text-align: right;
	display: flex;
	flex-direction: row;
}

#pageTimetrackingPayroll .ApStickyBar.sticky .customFooter { border-top-color: #33b5e5; }


#pageTimetrackingPayroll .customFooter .info {
	top: 1em;
	left: 1em;
	text-align: left;
}

#pageTimetrackingPayroll .customFooter .apButton {
	margin-left: auto;
	max-width: 400px;
	min-width: 300px;
}

#pageTimetrackingPayroll .filters {
	padding: 1em;
	border-bottom: 1px solid #ccc;
}

#pageTimetrackingPayroll .filters .apSelect {
	min-width: 350px;
}

#pageTimetrackingPayroll .filters .collective-agreement .apSelect {
	min-width: 700px;
}

#pageTimetrackingPayroll .entries {
	padding: 1em;
}

#pageTimetrackingPayroll .entries .entry {
	border: 1px solid #eee;
	border-left: 3px solid #ccc;
	margin: 0 0 0.2em;
	padding: 0.5em;
	padding-left: 40px;
	position: relative;
}


#pageTimetrackingPayroll .entries .entry.selected { border-left-color: #33b5e5; }
#pageTimetrackingPayroll .entries .entry.readOnly { border-left-color: #ff4444; background: #eee; }
#pageTimetrackingPayroll .entries .entry.batched { border-left-color: #ccc; background: #eee; }

#pageTimetrackingPayroll .entries .entry .selection {
	position: absolute;
	top: 50%;
	left: 10px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageTimetrackingPayroll .entries .entry .selection svg {
	width: 16px;
	height: 16px;
	fill: #999;
}

#pageTimetrackingPayroll .entries .entry .batchInfoButton {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}


#pageTimetrackingPayroll .apModalFooter .createButton { float: right; }




#pageTimetrackingPayroll .editModal .file {
	margin: 0 0 1em;
	padding: 0.5em;
	padding-left: 50px;
	background: #eee;
	border-left: 3px solid #ccc;
	line-height: 1.2em;

	position: relative;
}

#pageTimetrackingPayroll .editModal .file > svg {
	position: absolute;
	top: 50%;
	left: 10px;
	width: 28px;
	height: 28px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageTimetrackingPayroll .editModal .file span.pointer {
	position: absolute;
	top: 50%;
	right: 0.5em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}
#pageTimetrackingPayroll .editModal .file span.pointer .apButton { margin: 0; }

#pageTimetrackingPayroll .apFormGroup {
	min-width: 350px;
	margin-left: 10px;
	padding: 0.5em;
}

#pageTimetrackingPayroll .apAddon{
    text-align: center;
}

#pageTimetrackingPayroll .payroll-content {
    display: flex;
	flex-direction: row;
}

@media all and (max-width: 1200px) {
	#pageTimetrackingPayroll .customFooter {
		flex-direction: column;
	}
	#pageTimetrackingPayroll .customFooter .info {
		margin-right: 'auto';
		margin-bottom: 5px;
	}
	#pageTimetrackingPayroll .customFooter .apButton {
		margin-left: 0;
	}
}

@media all and (max-width: 900px) {
    #pageTimetrackingPayroll .payroll-content{
        flex-direction: column;
	}
	#pageTimetrackingPayroll .apFormGroup {
		min-width: unset;
		margin-left: 0;
	}
	#pageTimetrackingPayroll .filters .apSelect,
	#pageTimetrackingPayroll .filters .collective-agreement .apSelect {
		min-width: unset;
	}
}
