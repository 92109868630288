

.apModal.apInputModal {
	max-width: 400px;
}

.apModal.apInputModal .header {
	padding: 1em;
}

.apModal.apInputModal .footer {
	padding: 1em;
	text-align: right;
}

.apModal.apInputModal .footer .apButton {
	margin: 0 0 0 1em;
}