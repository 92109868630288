
ul.apSteps {
	width: calc( 100% - 25px );
	list-style: none;
	margin: 1em 0;
	padding: 0;
}

ul.apSteps:after {
	content: '';
	display: block;
	clear: both;
}

ul.apSteps li.step,
ul.apSteps li.custom {
	float: left;
	height: 50px;
	border: 1px solid #ccc;
	border-bottom: 3px solid #ccc;
	margin: 0 0 0.2em;
	position: relative;

	background: #f9f9f9;
	background: -moz-linear-gradient(left, #eeeeee 0%, #f9f9f9 20%, #f9f9f9 20%);
	background: -webkit-linear-gradient(left, #eeeeee 0%,#f9f9f9 20%,#f9f9f9 20%);
	background: linear-gradient(to right, #eeeeee 0%,#f9f9f9 20%,#f9f9f9 20%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#f9f9f9',GradientType=1 );
} 

ul.apSteps li.step.clickable { cursor: pointer; }

ul.apSteps li.custom {
	border-bottom-width: 1px;
	border-color: #eee;
	background: #fff;	
}

ul.apSteps li.custom.start {
	-webkit-border-radius: 10px 0 0 10px;
			border-radius: 10px 0 0 10px;
}

ul.apSteps li.custom.end {
	-webkit-border-radius: 0 10px 10px 0;
			border-radius: 0 10px 10px 0;
}

ul.apSteps li.step:after {
	content: '';
	position: absolute;
	z-index: 1;
	top: 0;
	right: -25px;
	width: 48px;
	height: 48px;
	background: #f9f9f9;

	-webkit-transform: scale(0.707) rotate(45deg);
	   -moz-transform: scale(0.707) rotate(45deg);
	    -ms-transform: scale(0.707) rotate(45deg);
	     -o-transform: scale(0.707) rotate(45deg);
	        transform: scale(0.707) rotate(45deg);
	
	box-shadow: 1px -1px 0 #ccc;
	border-radius: 0 10px 0 50px;
}

ul.apSteps.flatEnd li.step:last-child:after { display: none; }
ul.apSteps.flatEnd 	{ width: 100%; }

ul.apSteps.fullWidth li.step 			{ width: 100%; }
ul.apSteps.fullWidth.count-2 li.step,
ul.apSteps.fullWidth.count-2 li.custom 	{ width: 50%; }
ul.apSteps.fullWidth.count-3 li.step,
ul.apSteps.fullWidth.count-3 li.custom 	{ width: 33.33%; }
ul.apSteps.fullWidth.count-4 li.step,
ul.apSteps.fullWidth.count-4 li.custom 	{ width: 25%; }
ul.apSteps.fullWidth.count-5 li.step,
ul.apSteps.fullWidth.count-5 li.custom 	{ width: 20%; }
ul.apSteps.fullWidth.count-6 li.step,
ul.apSteps.fullWidth.count-6 li.custom 	{ width: 16.66%; }
ul.apSteps.fullWidth.count-7 li.step,
ul.apSteps.fullWidth.count-7 li.custom 	{ width: 14.28%; }
ul.apSteps.fullWidth.count-8 li.step,
ul.apSteps.fullWidth.count-8 li.custom 	{ width: 12.5%; }
ul.apSteps.fullWidth.count-9 li.step,
ul.apSteps.fullWidth.count-9 li.custom 	{ width: 11.11%; }
ul.apSteps.fullWidth.count-10 li.step,
ul.apSteps.fullWidth.count-10 li.custom 	{ width: 10%; }
ul.apSteps.fullWidth.count-11 li.step,
ul.apSteps.fullWidth.count-11 li.custom 	{ width: 9.09%; }
ul.apSteps.fullWidth.count-12 li.step,
ul.apSteps.fullWidth.count-12 li.custom 	{ width: 8.33%; }

ul.apSteps li.step.clickable:hover,
ul.apSteps li.step.clickable:hover:after { background: #eee; }

ul.apSteps li.custom:hover { 
	border-color: #ccc; 
	background: #f9f9f9;
}


ul.apSteps li.step.selected,
ul.apSteps li.step.selected:after { background: #fff; }
ul.apSteps li.step.selected { 
	color: #0099CC;
	border-bottom-color: #33b5e5;
	cursor: default; 
}

ul.apSteps li.step.success { 
	border-bottom-color: #4caf50;
	color: #3d8c40;
}

ul.apSteps li.step.return { 
	border-bottom-color: var(--clr-error-light2);
	color: var(--clr-error-main);
}


ul.apSteps li.step.isreturned div strong{ 
	border-bottom-color: black;
	color:black;	
	text-decoration: line-through;;
}

ul.apSteps li.step.ghost { 
	background: #fff;
	border-color: #eee;
	border-bottom-width: 1px;
	color: #aaa;
}
ul.apSteps li.step.ghost:after { background: #fff; box-shadow: 1px -1px 0 #eee; }
ul.apSteps li.step.ghost.clickable:hover,
ul.apSteps li.step.ghost.clickable:hover:after { background: #fff; color: #0099CC; }

ul.apSteps li.step .inner,
ul.apSteps li.custom .inner {
	margin: 5px 10px 0 30px;
	position: relative;
}

ul.apSteps li.step:first-child .inner {
	margin-left: 10px;
}

ul.apSteps li.step .inner.withIcon {
	padding-left: 35px;
}

ul.apSteps li.step .inner svg.stepIcon {
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 7px;
	width: 16px;
	height: 16px;
	margin-top: -8px;
}

ul.apSteps li.step .inner.onlyIcon {
	width: 16px;
	height: 30px;
}
ul.apSteps li.step .inner.onlyIcon svg.stepIcon {
	left: 0;
}
ul.apSteps li.step .inner.onlyIcon strong,
ul.apSteps li.step .inner.onlyIcon small { display: none; }


ul.apSteps li.step .inner strong,
ul.apSteps li.step .inner small {
	display: block;
	line-height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
ul.apSteps li.step .inner small { line-height: 15px; }

