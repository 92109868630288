#pageCrm .searchOption {
	position: relative;
	padding-left: 35px;

	line-height: 1.2em;
}

#pageCrm .searchOption svg.typeIcon {
	position: absolute;
	top: 50%;
	left: 0;
	width: 24px;
	height: 24px;

	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

#pageCrm .searchOption .company {
	position: absolute;
	top: 50%;
	right: 1em;

	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

#pageCrm .searchOption .company svg {
	fill: #aaa;
}

#pageCrm .empty {
	color: #aaa;
}


#pageCrm .apReactTable .ReactTable .rt-tr.notActive {
	background: #ffebee;
	color: #ff4444;
}

#pageCrm .apReactTable .ReactTable .rt-tr.notActive.selected {
	background: #e1f5fe;
}


#pageCrm .iconCell {
	text-align: center;
}

#pageCrm .iconCell svg {
	margin: 15px 0 0;
}

#pageCrm .iconCell.verified svg {
	fill: #4caf50;
}

#pageCrm .iconCell.verified.overYear svg {
	fill: #FF8800;
}

#pageCrm .iconCell.verified.over5Years svg {
	fill: #ff4444;
}

#pageCrm .iconCell.unverified svg {
	fill: #aaa;
}

.addGroup {
	overflow: visible;
}

.addGroup .apModalBody {
	overflow: unset !important;
}

.addGroup-narrow {
	max-width: 600px;
}

#crmMassChange .apReactTable .ReactTable .rt-tbody .rt-td,
#crmMassChange .apModalBody {
	overflow: visible;
}