.reportDates table {
    width: 100%;
}

#workload-report .date-selection {
    display: flex;
    flex-direction: row;
  }
  
  @media screen and (max-width: 750px) {
    #workload-report .date-selection {
      flex-direction: column;
    }
  }
  