#pageCheckout .checkoutContainer, .vat-row{
    display: flex;
}

#pageCheckout .checkout-left-panel {
    flex-grow: 12;
}

#pageCheckout .checkout-right-panel {
    flex-grow: 7;
    margin-left: 3em;
    padding: 0 1em 1em 1em;
}

#pageCheckout .customerBlock {
    border: 1px solid lightgray;

    background: #f9f9f9;
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
}

#pageCheckout .checkout-setting-info {
    cursor: pointer; 
    color: #0099CC;
}

#pageCheckout .checkout-setting-info:hover {
    font-weight: bold;
}

#pageCheckout .checkout-header-logo {
    height: 1.2em;
    width: 1.2em;
    margin: 0;
}

#pageCheckout .checkout-header-container:hover {
    transform: scale(1.05) rotate(-10deg);
    cursor: pointer;
}

#pageCheckout .checkout-header-container {
    border: 1px solid;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;  
}

#pageCheckout .vat-row {
    align-items: center; 
    margin-top: 40px;
}

#pageCheckout .vat-row > div{
    margin-right: 5px;
}

#pageCheckout .vat-row > button{
    margin-right: 10px;
    border-radius: 5px;
}

#pageCheckout .vat-selected {
    background-color: #0099CC;
    color: white;
}

#pageCheckout .receipt-row {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 10% 20% 20%;
    align-items: stretch;
    min-width: 321px;
    margin-bottom: 10px;
}

#pageCheckout .receipt-row-item {
    text-align: right;
}

#pageCheckout .receipt-row:hover:not(.header-row) {
    cursor: pointer;
    color: #0099CC;
    transform: translateX(5px);
}

#pageCheckout .checkout-add-button, .checkout-reverse-button, .pay-button {
    display: flex;
    margin-top: 0.7em;
    background-color: #0099CC;
    color: white;
    justify-content: center;
    padding: 10px 5px 5px 10px;
    width: 48%;
    cursor: pointer;
}

#pageCheckout .checkout-add-button:hover:not(.disabled), 
#pageCheckout .checkout-reverse-button:hover:not(.disabled),
#pageCheckout .pay-button:hover:not(.disabled) {
    transform: scale(1.03);
}

#pageCheckout .checkout-reverse-button {
    background-color: #008000;
}

#pageCheckout .pay-button {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 13px;
}

#pageCheckout .disabled {
    opacity: 0.2;

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";

    /* IE 5-7 */
    filter: alpha(opacity=20);

    /* Netscape */
    -moz-opacity: 0.2;

    /* Safari 1.x */
    -khtml-opacity: 0.2;
}

#pageCheckout .checkout-not-found {
    color: red;
}

#pageCheckout .disabled:hover {
    cursor: not-allowed;
}


@media screen and (max-width: 600px) { 
    #pageCheckout .checkoutContainer {
        flex-direction: column;
    }
}