
#woocommerce-catgories .errorMessage {
    padding: 1em;
    font-weight: 700;
    text-align: center;
}

#woocommerce-catgories .splitView {
    /* min-width: 600px; */
    display: flex;
    flex-direction: row;
}

#woocommerce-catgories .splitView .left {
    /* float: left; */
    width: 300px;
}

#woocommerce-catgories .splitView .right {
    width: calc( 100% - 300px );
}

#woocommerce-catgories .splitView .details {
    margin-left: 1em;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

#woocommerce-catgories .apLocationTree {
    margin: 0;
}
#woocommerce-catgories .apLocationTree .detailPicker .apInputBlock .apSelect {
    background: #fff;
}
#woocommerce-catgories .apLocationTree .detailPicker table.apInputStack .apAddon.noRightBorder {
    padding-left: 0;
    padding-right: 0;
    font-family: 'Roboto-Light', Arial, sans-serif;
}

#woocommerce-catgories .apLocationTree .apSimpleButton {
    width: 50px;
    height: 42px;
}

#woocommerce-catgories .apLocationTree .location {
    position: relative;
    padding-left: 24px;
}

#woocommerce-catgories .apLocationTree .location .canTakeDeliveries {
    position: absolute;
    padding: 7px 4px 4px 4px;
    bottom: -6px;
    left: -6px;
}

#woocommerce-catgories .apLocationTree .location .canHaveComponents {
    position: absolute;
    padding: 7px 4px 4px 4px;
    top: -6px;
    left: -6px;
}
#woocommerce-catgories .apLocationTree .location .canTakeDeliveries svg,
#woocommerce-catgories .apLocationTree .location .canHaveComponents svg {
    width: 18px;
    height: 18px;
}

#woocommerce-catgories .receiver-indent {
    margin-left: 20px;
}


#woocommerce-catgories .details .locationPath {
    margin: 0;
}

#woocommerce-catgories .details .locationPath .inner.current {
    position: relative;
    padding-right: 50px;
}

#woocommerce-catgories .details .locationPath .inner.current .apDropdown {
    position: absolute;
    z-index: 99;
    top: 50%;
    right: 0;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}





#woocommerce-catgories .componentTable {
    width: 100%;
    font-size: 14px;
    line-height: 1.5em;
}

#woocommerce-catgories .componentTable tr {
    border-bottom: 1px solid #ccc;
}

#woocommerce-catgories .componentTable th {
    font-size: 14px;
    vertical-align: bottom;
}

#woocommerce-catgories .componentTable td {
    padding: 0.5em 0.2em;
}

#woocommerce-catgories .componentTable td.name { width: auto; }
#woocommerce-catgories .componentTable td.fixed { width: 110px; min-width: 110px; max-width: 110px; }

#woocommerce-catgories .componentTable th.fixed,
#woocommerce-catgories .componentTable td.fixed {
    text-align: center; 
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}


#woocommerce-catgories .componentTable td.fixed input.componentRowInput {
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0 30px 0 0;

    border: 1px solid #ccc;
    border-bottom-width: 3px;

    font-size: 14px;
    line-height: 30px;
} 

#woocommerce-catgories .componentTable td.fixed input.componentRowInput.error {
    color: #ff4444;
    border-bottom-color: #ff4444;
}

#woocommerce-catgories .componentTable td.fixed input.componentRowInput:focus {
    outline: 0;
    border-color: #33b5e5;
}

#woocommerce-catgories .componentTable td.fixed .unit {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 25px;
    line-height: 30px;
    margin-top: -15px;
    text-align: left;
    font-size: 12px;
    color: #aaa;

    overflow: hidden;
    text-overflow: ellipsis;
}

#woocommerce-catgories .componentTable td svg {
    width: 20px;
    height: 20px;
}

#woocommerce-catgories .componentTable td.name {
    position: relative;
    padding-bottom: 18px;
}

#woocommerce-catgories .componentTable td.name .code {
    position: absolute;
    bottom: 0;
    left: 20px;
}

#woocommerce-catgories .componentTable td.balance {
    position: relative;
    text-align: right;
    padding-right: 40px;
}

#woocommerce-catgories .componentTable td.balance .free {
    position: absolute;
    bottom: 0;
    right: 40px;
}

#woocommerce-catgories .componentTable td .setDisabled {
    position: absolute;
    top: 50%;
    left: 6px;
    margin-top: -12px;
    cursor: pointer;
}

#woocommerce-catgories .componentTable td .setEnabled {
    cursor: pointer;
}

#woocommerce-catgories .bottomInfo {
    margin-top: 2em;
}

#woocommerce-catgories .apDropdown .action {
    cursor: pointer;
}
#woocommerce-catgories .apDropdown .action.disabled {
    cursor: auto;
}

#woocommerce-catgories .changesModal .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}

#woocommerce-catgories .changesModal .apModal {
    width: 60%;
}
#woocommerce-catgories .changesModal .apModal .apModalBody {
    padding: 2em;
}

#woocommerce-catgories .changesModal .changesTable {
    width: 100%;
    font-size: 14px;
    line-height: 1.5em;
}

#woocommerce-catgories .changesModal .changesTable tr {
    border-bottom: 1px solid #ccc;
}

#woocommerce-catgories .changesModal .changesTable th {
    font-size: 14px;
    vertical-align: bottom;
}

#woocommerce-catgories .changesModal .changesTable td {
    padding: 0.5em 0.2em;
}

#woocommerce-catgories .changesModal .changesTable td.type svg {
    width: 20px;
    height: 20px;
}
#woocommerce-catgories .changesModal .changesTable td.type span {
    padding-left: 6px;
}


#woocommerce-catgories .changesModal .countWrapper {
    margin-top: 1em;
}
#woocommerce-catgories .changesModal .countWrapper div {
    text-align: right;
}
#woocommerce-catgories .changesModal .countWrapper div .count {
    display: inline-block;
    width: 50px;
    font-weight: 700;
}

#woocommerce-catgories .tempComponentsContainer {
    padding-top: 2em;
}

#woocommerce-catgories .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em 0.5em 0 0.5em;
    border-top: 3px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#woocommerce-catgories .ApStickyBar.sticky .saveBar { border-color: #33b5e5; }

#woocommerce-catgories .ApStickyBar.noSticky .saveBar {
    background: #fff;
    padding: 1em;
    margin-top: 1em;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

@media screen and (max-width: 800px) {
    #woocommerce-catgories .splitView {
        flex-direction: column;
    }
    #woocommerce-catgories .splitView .left,
    #woocommerce-catgories .splitView .right {
        /* float: left; */
        width: 100%;
    }

    #woocommerce-catgories .splitView .left {
        box-shadow: 0 3px 10px rgba(0,0,0,0.1);
        margin-bottom: 2rem;
    }

    #woocommerce-catgories .splitView .details {
        margin-left: 0;
    }

}