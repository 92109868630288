#projectTracking > .apBox {
    margin-top: 0;
}

#projectTracking .lineInfoContainer {
    position: relative;
    cursor: pointer;
}

#projectTracking .lineInfoContainer input {
    position: absolute;
    top: 40%;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);

}

#projectTracking .lineInfoContainer .apInlineLoader {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}


#projectTracking .lineInfo {
    font-size: 0.8em;
    margin-left: 24px;
}
#projectTracking .lineInfo:hover {
    font-weight: 700;
}

#projectTracking .lineInfo .colorIndicator {
    display: inline-block;
}
#projectTracking .lineInfo .colorIndicator svg {
    width: 10px;
    height: 10px;
    margin-right: 4px;
}

#projectTracking .stats {
    font-size: 16px;
    margin: 0 0 2em;
}

#projectTracking .stats:after {
    content: '';
    display: block;
    clear: both;
}

#projectTracking .stats .stat {
    width: 23.5%;
    height: 90px;
    margin: 0 2% 1% 0;
    padding: 10px 10px 10px 50px;
    float: left;
    background: #f9f9f9;
    border-bottom: 5px solid #ccc;
    position: relative;
}
#projectTracking .stats .stat:nth-child(4n+0) { margin-right: 0; }

#projectTracking .stats .stat svg {
    position: absolute;
    top: 50%;
    left: 12px;
    width: 24px;
    height: 24px;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#projectTracking .stats .stat .title {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 90%;
    line-height: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#projectTracking .stats .stat .value {
    font-size: 140%;
    line-height: 30px;

    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
#projectTracking .stats .stat .value small {
    font-size: 70%;
}

#projectTracking .stats .stat .info {
    font-size: 90%;
    line-height: 20px;
    color: #999;
    text-align: right;

    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}


#projectTracking .treeContainer {
    background: #eee;
    padding: 1em 0.5em 0.5em;
    -webkit-box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
}

#projectTracking .workProgressContainer {
    position: relative;
}

#projectTracking .workProgressProjectName {
    /* outline: 1px solid black; */
    width: 200px;
    float: left;
}

#projectTracking .barsContainer {
    float: left;
    width: calc( 100% - 200px );
    padding-left: 4px;
}

#projectTracking .workProgressBar {
    /* outline: 1px solid red; */
    float: left;
    height: 16px;
    width: calc( 100% - 100px );
}

#projectTracking .workProgressPercent {
    /* outline: 1px solid green; */
    text-align: right;
    padding-left: 4px;
    float: left;
    width: 100px;
    font-size: 0.8em;
}

#projectTracking .workProgressPercent .percent {
    font-weight: 700;
    display: inline-block;
    margin-left: 10px;
}

#projectTracking .resetZoomContainer {
    position: absolute;
    right: 0;
    top: 0;
}

