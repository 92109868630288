#WorkPermits .list {
	padding: 1em;
}

#WorkPermits .list .listItem {
	display: block;
	border-bottom: 1px solid #eee;
	padding: 0.5em 1em;
	line-height: 1.2em;
	color: inherit;
	cursor: pointer;
	position: relative;
}

#WorkPermits .list.listItem .listRow {
	display: inline;
}

#WorkPermits .list .listItem:hover {
	text-decoration: none;
	background: #f9f9f9;
}
#WorkPermits .list .listItem svg.icon {
	position: absolute;
	top: 50%;
	right: 1em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#userPermitModal{
	overflow: visible !important;
	
}
