.card {
    background-color: #ffffff;
    height: 84px;
}

.card-icon-background {
    height: 84px;
    text-align: center;
}

.card-icon {
    font-size: 2.5em;    
    color: #ffffff;
    margin-top: 20px;
}
.card-header
{
    font-weight: bold;
    font-size: 2em;
    color: #ffffff;
}
.card-title
{
    color: #444444;
    font-size: 1.25em;
    padding-top: 3px;
}
.card-text
{
    color: #444444;
}