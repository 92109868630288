#pagePos svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

#pagePos .searchOption {
	position: relative;
	padding-left: 35px;

	line-height: 1.2em;
}

#pagePos .searchOption svg.typeIcon {
	position: absolute;
	top: 50%;
	left: 0;
	width: 24px;
	height: 24px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pagePos .searchOption .pos {
	position: absolute;
	top: 50%;
	right: 1em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pagePos .searchOption .pos svg { fill: #aaa; }


#pagePos .itemnumber{
	position: relative;
	padding-right: 15px;
	float:left;

	line-height: 2em;
}