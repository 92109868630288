
#pageCrmCompany .apBoxHeader .verified,
#pageCrmCompany .apBoxHeader .unverified {
	font-size: 14px;
}

#pageCrmCompany .apBoxHeader .verified svg { fill: #4caf50; }
#pageCrmCompany .apBoxHeader .verified.overYear svg { fill: #FF8800; }
#pageCrmCompany .apBoxHeader .verified.over5Years svg { fill: #ff4444; }
#pageCrmCompany .apBoxHeader .unverified svg { fill: #aaa; }


#pageCrmCompany .industryOption {
	position: relative;
	padding-right: 70px;
}
#pageCrmCompany .industryOption .name {
	font-size: 14px; 
	line-height: 1em;
}
#pageCrmCompany .industryOption .code {
	position: absolute;
	top: 50%;
	right: 0.5em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageCrmCompany .selectedGroup {
	border: 1px solid #ccc;
	padding: 0.2em 1em;
	background: #fff;
	border-radius: 5px;
}

#pageCrmCompany .addresses,
#pageCrmCompany .contacts {
	margin: 1em 0;
}

#pageCrmCompany .addresses .address,
#pageCrmCompany .contacts .contact {
	display: inline-block;
	vertical-align: top;
	width: 31%;
	margin: 0 1em 1em 0;
	min-width: 250px;
	border: 1px solid #eee;
	border-left: 5px solid #ddd;
	line-height: 1.2em;
}

#pageCrmCompany .addresses .address:hover,
#pageCrmCompany .contacts .contact:hover { border-left-color: #ccc; }

#pageCrmCompany .addresses .address.default { border-left-color: #4caf50; }

#pageCrmCompany .contacts .contact.highlighted { border-color: #33b5e5; }
#pageCrmCompany .contacts .contact.highlighted .header { background: #e3f2fd; }
#pageCrmCompany .contacts .contact.highlighted .header strong { color: #0099CC; }

#pageCrmCompany .contacts .contact .empty { opacity: 0.5; }

#pageCrmCompany .addresses .address.removed,
#pageCrmCompany .contacts .contact.removed { border-left-color: #ff4444; }

#pageCrmCompany .addresses .address.removed .header,
#pageCrmCompany .contacts .contact.removed .header,
#pageCrmCompany .events .event.removed .header {
	background: #ffebee !important;
	text-decoration: line-through;
}
#pageCrmCompany .addresses .address.removed .content,
#pageCrmCompany .contacts .contact.removed .content,
#pageCrmCompany .events .event.removed .content { text-decoration: line-through; opacity: 0.5; }

#pageCrmCompany .addresses .address .header,
#pageCrmCompany .contacts .contact .header {
	background: #f9f9f9;
	border-bottom: 1px solid #eee;
	padding: 0.5em;
	position: relative;
}

#pageCrmCompany .addresses .address .header svg.typeIcon {
	width: 32px;
	height: 32px;
	margin: 4px 0.5em;
}

#pageCrmCompany .addresses .address .header .apDropdown,
#pageCrmCompany .contacts .contact .header .apDropdown {
	position: absolute;
	z-index: 2;
	top: 50%;
	right: 0.5em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageCrmCompany .addresses .address .content,
#pageCrmCompany .contacts .contact .content {
	padding: 1em;
	font-size: 14px;
	line-height: 2em;
	position: relative;
}

#pageCrmCompany .addresses .address .content svg.unsavedIcon,
#pageCrmCompany .contacts .contact .content svg.unsavedIcon,
#pageCrmCompany .events .event .header svg.unsavedIcon {
	position: absolute;
	bottom: 0.5em;
	right: 0.5em;
	width: 24px;
	height: 24px;
	fill: #ff9800;
}
#pageCrmCompany .events .event .header svg.unsavedIcon { 
	bottom: 50%; 
	right: 60px; 
	-webkit-transform: translateY(50%);
	   -moz-transform: translateY(50%);
	    -ms-transform: translateY(50%);
	     -o-transform: translateY(50%);
	        transform: translateY(50%);
}

#pageCrmCompany .addresses .address .content .defaultIcon {
	position: absolute;
	max-width: 35px;
	top: 0.5em;
	right: 0.5em;
}
#pageCrmCompany .addresses .address .content .defaultIcon svg {
	display: block;
	width: 32px;
	height: 32px;
	padding: 6px;
	fill: #fff;
	background: #4caf50;
	border-radius: 5px;
}
#pageCrmCompany .addresses .address .content .defaultIcon svg:hover { border-color: #ccc; }

#pageCrmCompany .contacts .contact .content .groups .group {
	border: 1px solid #ccc;
	margin: 0 0.2em 0.2em 0;
	padding: 0.2em 0.5em;
}

#pageCrmCompany .contacts .contact .content .language svg,
#pageCrmCompany .contacts .contact .content .groups svg,
#pageCrmCompany .contacts .contact .content .contactInfo svg {
	width: 16px;
	height: 16px;
	margin: -3px 5px 0 0;
}

#pageCrmCompany .contacts .contact .content .language small,
#pageCrmCompany .contacts .contact .content .groups small,
#pageCrmCompany .contacts .contact .content .contactInfo small {
	font-size: 100%;
	color: #999;
}

#pageCrmCompany .contacts .contact .content .info {
	position: absolute;
	top: 0.5em;
	right: 1em;
	cursor: pointer;
}

#pageCrmCompany .events {
	padding: 1em 1em 1em 0;
	background: #f9f9f9;
	box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
	position: relative;
}

#pageCrmCompany .events .timeline {
	position: absolute;
	top: 2em;
	bottom: 2em;
	left: 25px;
	width: 2px; 
	background: #ccc;
}

#pageCrmCompany .events .event,
#pageCrmCompany .events .message {
	margin: 0 0 1em 50px;
	position: relative;
}

#pageCrmCompany .events .event:last-of-type,
#pageCrmCompany .events .message:last-of-type { margin-bottom: 0; }

#pageCrmCompany .events .message { line-height: 50px; }

#pageCrmCompany .events .event {
	box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

#pageCrmCompany .events .event svg.eventIcon,
#pageCrmCompany .events .message svg.eventIcon {
	position: absolute;
	top: 9px;
	left: -25px;
	width: 32px;
	height: 32px;
	padding: 8px;
	background: #999;
	fill: #fff;
	border-radius: 999px;

	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}
#pageCrmCompany .events .event.call svg.eventIcon { background: #2BBBAD; }
#pageCrmCompany .events .event.email svg.eventIcon { background: #4285F4; }
#pageCrmCompany .events .event.visit svg.eventIcon { background: #aa66cc; }

#pageCrmCompany .events .event.removed svg.eventIcon { opacity: 0.5; }

#pageCrmCompany .events .event .header {
	background: #fff;
	border-left: 3px solid #999;
	padding: 0.5em;
	padding-left: 50px;
	line-height: 1.2em;
	position: relative;
	cursor: pointer;

	-webkit-transition: background 0.4s ease;
	   -moz-transition: background 0.4s ease;
	    -ms-transition: background 0.4s ease;
	     -o-transition: background 0.4s ease;
	        transition: background 0.4s ease;
}
#pageCrmCompany .events .event .header:hover { background: #f9f9f9; }
#pageCrmCompany .events .event.call .header { border-left-color: #2BBBAD; }
#pageCrmCompany .events .event.email .header { border-left-color: #4285F4; }
#pageCrmCompany .events .event.visit .header { border-left-color: #aa66cc; }

#pageCrmCompany .events .event.call.detailsOpen .header { background: #e0f2f1; }
#pageCrmCompany .events .event.email.detailsOpen .header { background: #e3f2fd; }
#pageCrmCompany .events .event.visit.detailsOpen .header { background: #f3e5f5; }

#pageCrmCompany .events .event .header .user {
	position: absolute;
	top: 50%;
	left: 10px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageCrmCompany .events .event .content {
	border-top: 1px solid #eee;
	background: #fff;
	padding: 1em;
}

#pageCrmCompany .events .event .content p strong {
	font-family: 'Roboto', Arial, sans-serif;
	color: #0099CC;
	font-size: 14px;
}

#pageCrmCompany .events .event .content p small {
	font-size: 100%;
	color: #aaa;
}

#pageCrmCompany .events .event .apDropdown {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
}


#pageCrmCompany .events .event.filtered { 
	pointer-events: none; 
	height: 10px; 
	background: #eee;
	box-shadow: none;
}
#pageCrmCompany .events .event.filtered.removed { display: none; }
#pageCrmCompany .events .event.filtered svg.eventIcon { 
	top: -3px;
	width: 16px;
	height: 16px;
	padding: 4px;
	background: #ccc;
}
#pageCrmCompany .events .event.filtered .header,
#pageCrmCompany .events .event.filtered .content,
#pageCrmCompany .events .event.filtered .apDropdown { display: none; }

#pageCrmCompany .ApStickyBar .saveBar {
	padding: 1em;
	border-top: 2px solid #ccc;
	background: #fff;

	-webkit-transition: border 0.4s ease;
	   -moz-transition: border 0.4s ease;
	    -ms-transition: border 0.4s ease;
	     -o-transition: border 0.4s ease;
	        transition: border 0.4s ease;

	position: relative;
}

#pageCrmCompany .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }

#pageCrmCompany .ApStickyBar .saveBar .info {
	min-height: 42px;
	padding: 0 150px 0 35px;
	position: relative;
}
#pageCrmCompany .ApStickyBar .saveBar .info.error { color: #ff4444; }

#pageCrmCompany .ApStickyBar .saveBar .info svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
}

#pageCrmCompany .ApStickyBar .saveBar .info.unsaved svg { fill: #FF8800; }
#pageCrmCompany .ApStickyBar .saveBar .info.error svg { fill: #ff4444; }

#pageCrmCompany .ApStickyBar .saveBar .apButton {
	position: absolute;
	top: 1em;
	right: 1em;
	margin: 0;
}




#pageCrmCompany .modalAddress .apOptionBar,
#pageCrmCompany .modalEvent .apOptionBar {
	margin: 0 0 1em;
}

#pageCrmCompany .modalAddress .apOptionBar .option { width: 25%; }
#pageCrmCompany .modalEvent .apOptionBar .option { width: 25%; }

#pageCrmCompany .modalAddress .apOptionBar .option svg,
#pageCrmCompany .modalEvent .apOptionBar .option svg {
	display: block;
	margin: 0.5em auto 0;
}


#pageCrmCompany .modalFooter {
	padding: 1em;
}

#pageCrmCompany .modalFooter .apButton {
	margin: 0;
}

#pageCrmCompany .modalFooter .apButton.save {
	float: right;
}

#pageCrmCompany .ApNestedSelect .results .item .name {
    position: static;
}

#pageCrmCompany .description,
#pageCrmCompany .action {
	white-space: break-spaces;
}

