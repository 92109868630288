.modulesTree { 
	margin: 1em 0;
    background-color: #f5f5f5;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
    max-height: 500px;
    overflow: auto;
    border: 1px solid #ccc;
}

.modulesTree .top {

}

.modulesTree .top:after {
	content: '';
	display: block;
	clear: both;
}

.modulesTree .top .toggleAll {
	float: right;
	vertical-align: middle;
	font-size: 14px;
}

.modulesTree .top .toggleAll .button {
	display: inline-block;
	margin: 5px;
	border: 1px solid transparent;
	padding: 0 0.5em;
	border-radius: 10px;
	cursor: pointer;
}

.modulesTree .top .toggleAll .button:hover { border-color: #ccc; }

.modulesTree .top .toggleAll .button svg {
	width: 16px;
	height: 16px;
	margin: -3px 5px 0 0;
}

.modulesTree .bottom {
	padding: 0 0.5em 0.2em;
}

.modulesTree li .apListTreeItem { 
	border-left: 0px solid #fff;
	-webkit-transition: border 0.4s ease;
	   -moz-transition: border 0.4s ease;
	    -ms-transition: border 0.4s ease;
	     -o-transition: border 0.4s ease;
	        transition: border 0.4s ease;
}
.modulesTree li .apListTreeItem.enabled { 
	border-left: 5px solid #00C851;
	color: #007E33;
}

.modulesTree li .info {
	font-family: 'Roboto', Arial, sans-serif;
	font-size: 16px;
	line-height: 20px;
	padding: 0.5em 0;
	width: calc( 100% - 120px );
}
.modulesTree li .info.collapsible { cursor: pointer; }

.modulesTree li .info small {
	font-family: 'Roboto-Light', Arial, sans-serif;
	display: block;
}

.modulesTree li .details {
	position: absolute;
	top: 50%;
	right: 110px;
	opacity: 0;

	font-size: 14px;
	line-height: 1em;
	color: #00C851;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	-webkit-transition: opacity 0.4s ease;
	   -moz-transition: opacity 0.4s ease;
	    -ms-transition: opacity 0.4s ease;
	     -o-transition: opacity 0.4s ease;
	        transition: opacity 0.4s ease;
}

.modulesTree li .details.visible { opacity: 1; }

.modulesTree li .apSwitchWrapper,
.modulesTree li .inheritedIcon {
	position: absolute;
	top: 50%;
	right: 40px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.modulesTree li .inheritedIcon {
	right: 10px;
	width: 20px;
	height: 20px;
}