
.fileUpload .fileWrapper {
	width: 100%;
	height: auto;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	padding: 7px 10px 0;
    position: relative;
	line-height: 30px;
}

.fileUpload .fileWrapper input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

}

.fileUpload .fileWrapper label.fileLabel {
	display: block;
	line-height: 30px;
	color: #aaa;
	cursor: pointer;
}

.fileUpload .fileWrapper .fileNameContainer {
	display: block;
	line-height: 30px;
    font-weight: 700;
	cursor: pointer;
    margin-bottom: 5px;
}
.fileUpload .fileWrapper .fileName {
    position: absolute;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
}

.fileUpload.filled .fileWrapper label.fileLabel { color: #666; }
.fileUpload .fileWrapper label.fileLabel:hover { color: #33b5e5; }

.fileUpload .fileWrapper .removeFile {
	position: absolute;
	top: 50%;
	right: 10px;
	cursor: pointer;
	opacity: 0.5;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.fileUpload .fileWrapper .removeFile:hover {
	opacity: 1;
}

.fileUpload.disabled .fileWrapper { pointer-events: none; }
