#projectManagement table.componentTable {
    width: 100%;
    font-size: 14px;
    line-height: 1.5em;
    background-color: #fff;
}


/**
 * FILTER BAR
 **/

#projectManagement .filterBar {
    width: 100%;
    height: 50px;
    background: #f9f9f9;
    border-bottom: 3px solid #ccc;
    position: relative;
    z-index: 1;

    -webkit-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
       -moz-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
        -ms-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
         -o-transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
            transition: border-bottom 0.4s ease, box-shadow 0.4s ease;
}

#projectManagement .filterBar .apOptionBar {
    margin: 6px;
}

#projectManagement .filterBar .apOptionBar.smallerIcons svg {
    width: 16px;
    height: 16px;
}

#projectManagement .filterBar .viewsTitle {
    display: inline-block;
    line-height: 50px;
    float: left;
    margin-left: 1em;
}

#projectManagement .filterBar .viewsSelect {
    display: inline-block;
    float: left;
}

#projectManagement .filterBar .rightFilter {
    display: inline-block;
    float: right;
    padding-right: 1em;
}

#projectManagement .filterBar .rightFilter .filter {
    float: right;
    width: auto;
    height: 100%;
    position: relative;
}


#projectManagement .filterBar .rightFilter .filter.title {
    line-height: 50px;
    font-size: 14px;
    padding: 0 0em;
}

#projectManagement .filterBar .rightFilter .filter.title .apBadge {
    position: absolute;
    top: 5px;
    left: 5px;
    background: #FF8800;
}


#projectManagement .filterBar .rightFilter  .calcFilter {
    position: relative;
}

#projectManagement .filterBar .rightFilter  .calcFilter .apBadge {
    position: absolute;
    top: -2px;
    right: -10px;
    background: #0099CC;
    z-index: 4;
}


#projectManagement .filterBar .calcSelect {
    position: relative;
}

#projectManagement .filterBar .calcSelect.selected .unit ,
#projectManagement .filterBar .calcSelect.selected {
    color: #0099CC;
    font-weight: 700;
}
#projectManagement .groupRow {
    background-color: #f2f2f2;
    
}
#projectManagement .groupRow input {
    background-color: #ffffff;
    
}
#projectManagement .groupRow input:disabled {
    background-color: #fafafa;
    
}


#projectManagement .filterBar .calcSelect .unit {
    font-size: 0.8em;
    color: #AAA;
    position: absolute;
    right: 6px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);

}

