
/* .apPath {
	display: inline-block;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	width: 100%;
	
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	direction: rtl;
    text-align: left;
} */

.apPath.hideFromEnd {
	direction: ltr;
}

.apPath.boldLast .item.last {
	font-weight: bold;
}

.apPath .delimiter {
	opacity: 0.5;
}

.apPath > svg {
	float: left;
	width: 16px;
	height: 16px;
	margin: 3px 5px 0 0;
}