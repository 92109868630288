
#pageTimetrackingEntry .customFooter {
	padding: 1em;
	margin: 0em 0 0;
	border-top: 2px solid #eee;
	background: #fff;
	position: relative;
}

#pageTimetrackingEntry .ApStickyBar.sticky .customFooter { border-top-color: #33b5e5; }

#pageTimetrackingEntry .customFooter .stat {
	display: inline-block;
	margin: 0 2em 0 0;
	position: relative;
}

#pageTimetrackingEntry .customFooter .stat.empty {
	font-size: 14px;
	color: #999;
}

#pageTimetrackingEntry .customFooter .stat svg {
	width: 20px;
	height: 20px;
	margin: -3px 5px 0 0;
	vertical-align: middle;
}
#pageTimetrackingEntry .customFooter .stat.empty svg { fill: #ccc; }
#pageTimetrackingEntry .customFooter .stat.success svg { fill: #4caf50; }
#pageTimetrackingEntry .customFooter .stat.warning svg { fill: #FF8800; }
#pageTimetrackingEntry .customFooter .stat.error svg { fill: #ff4444; }
#pageTimetrackingEntry .customFooter .stat small {color: #999; }


#pageTimetrackingEntry .customFooter .flexi,
#pageTimetrackingEntry .sendModal .flexi {
	background: #eee;
	-webkit-border-radius: 999px;
			border-radius: 999px;

	font-weight: normal;
	text-align: center;
	font-size: 14px;
	padding: 0.2em 0.5em;
	margin: 0 0 0 0.5em;
}
#pageTimetrackingEntry .customFooter .flexi.minus,
#pageTimetrackingEntry .sendModal .flexi.minus { background: #ffcdd2; color: #CC0000; }
#pageTimetrackingEntry .customFooter .flexi.plus,
#pageTimetrackingEntry .sendModal .flexi.plus { background: #c8e6c9; color: #007E33; }

#pageTimetrackingEntry .customFooter .stat .apBadge {
	position: absolute;
	top: -5px;
	left: -5px;
	pointer-events: none;
}

#pageTimetrackingEntry .customFooter .apTooltip { text-align: left; }

#pageTimetrackingEntry .customFooter .apTooltip .errors .unsavedChanges {
	border-bottom: 1px solid #666;
	padding-bottom: 0.5em;
	margin-bottom: 0.5em;
}

#pageTimetrackingEntry .customFooter .apTooltip .errors .day { margin: 0 0 1em; }
#pageTimetrackingEntry .customFooter .apTooltip .errors .day strong { line-height: 1.5em; }
#pageTimetrackingEntry .customFooter .apTooltip .errors .unsavedChanges svg,
#pageTimetrackingEntry .customFooter .apTooltip .errors .day svg {
	width: 16px;
	height: 16px;
	margin: -3px 5px 0 0;
}

#pageTimetrackingEntry .customFooter .apTooltip .errors .unsavedChanges svg,
#pageTimetrackingEntry .customFooter .apTooltip .errors .day .warning svg { fill: #FF8800; }
#pageTimetrackingEntry .customFooter .apTooltip .errors .day .error svg { fill: #ff4444; }

#pageTimetrackingEntry .customFooter .apButtonGroup {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageTimetrackingEntry .customFooter .apButtonGroup .stat {	margin: 0 1em; }
#pageTimetrackingEntry .customFooter .apButtonGroup .stat svg {	margin: -3px 0 0 5px; }

#pageTimetrackingEntry .customFooter .apButtonGroup .divider {
	display: inline-block;
	width: 1px;
	height: 30px;
	background: #ccc;
	margin: 0 1em;
	vertical-align: middle;
}

#pageTimetrackingEntry .entryEdit .apFormGroup .additionalFieldLabel { position: relative; }
#pageTimetrackingEntry .entryEdit .apFormGroup .additionalFieldLabel .apTooltipper {
	display: block;
	position: absolute;
	top: 50%;
	right: 0.5em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}
#pageTimetrackingEntry .entryEdit .apFormGroup .apInputStack .removeRow {
	display: block;
	margin: 10px auto 0;
	width: 24px;
	height: 24px;
	opacity: 0.8;
}
#pageTimetrackingEntry .entryEdit .apFormGroup .apInputStack .removeRow:hover { opacity: 1; }
#pageTimetrackingEntry .entryEdit .apFormGroup .apInputStack .removeRow.disabled { opacity: 0.2; cursor: not-allowed; }

#pageTimetrackingEntry .entryEdit .apFormGroup .trackingUnits {
	margin: 0 50px 1em 0;
	padding: 0.5em;
	background: #eee;
    border-top: 3px solid #0099cc;
	position: relative;
}

#pageTimetrackingEntry .entryEdit .apFormGroup .trackingUnits:after {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 153, 204, 0);
	border-bottom-color: #0099cc;
	border-width: 10px;
	margin-left: -10px;
}

#pageTimetrackingEntry .entryEdit .apFormGroup .trackingUnits .apInputStack .apInputBlock {
	margin-bottom: 5px;
}

#pageTimetrackingEntry .entryEdit .costRow { margin: 0 0 0.5em; }

#pageTimetrackingEntry .entryEdit .costRow .currencyRate {
	margin: 5px 50px 1em 0;
	background: #eee;
	border-top: 3px solid #0099cc;
	padding: 0.5em;
	position: relative;
}

#pageTimetrackingEntry .entryEdit .costRow .apInputBlock { margin-bottom: 0; }
#pageTimetrackingEntry .entryEdit .costRow .currencyRate:after {
	bottom: 100%;
	right: 50px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 153, 204, 0);
	border-bottom-color: #0099cc;
	border-width: 10px;
	margin-left: -10px;
}

#pageTimetrackingEntry .entryEdit .allowanceTime {
	overflow: hidden; 
}

#pageTimetrackingEntry .entryEdit .installationTime.auto { border-bottom-color: #4db6ac; }

#pageTimetrackingEntry .apModalFooter .applyButton { float: right; }

#pageTimetrackingEntry .sendModal .selectDates {
	margin: 0 0 1em;
}

#pageTimetrackingEntry .sendModal label.sendDate {
	display: block;
	margin: 0 0 0.5em;
	padding: 0.5em 65px;
	border: 1px solid #eee;
	border-left: 3px solid #ccc;
	position: relative;
	cursor: pointer;
}
#pageTimetrackingEntry .sendModal label.sendDate:hover {
	border-left-color: #aaa;
}
#pageTimetrackingEntry .sendModal label.sendDate.selected { 
	border-left-color: #4caf50; 
	/* background: #e8f5e9; */
}
#pageTimetrackingEntry .sendModal label.sendDate.locked {
	background: #f9f9f9;
	color: #aaa;
	border-left-color: #eee;
	cursor: not-allowed;
}
#pageTimetrackingEntry .sendModal label.sendDate.submitted {
	border-left-color: #33b5e5; 
	color: #33b5e5;
}

#pageTimetrackingEntry .sendModal label.sendDate .apSwitchWrapper,
#pageTimetrackingEntry .sendModal label.sendDate .hours,
#pageTimetrackingEntry .sendModal label.sendDate svg.warning,
#pageTimetrackingEntry .sendModal label.sendDate .entries {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageTimetrackingEntry .sendModal label.sendDate .apSwitchWrapper { left: 0; }
#pageTimetrackingEntry .sendModal label.sendDate .hours { 
	left: 270px; 
	font-weight: normal; 
	font-size: 14px;
	opacity: 0.2;
}
#pageTimetrackingEntry .sendModal label.sendDate svg.warning {
	left: 240px;
	width: 20px;
	height: 20px;
	fill: #FF8800;
} 

#pageTimetrackingEntry .sendModal label.sendDate.selected .hours { opacity: 1; } 

#pageTimetrackingEntry .sendModal label.sendDate .entries { right: 1em; font-weight: bold; }

#pageTimetrackingEntry .sendModal label.sendDate .entries { color: #666; }
#pageTimetrackingEntry .sendModal label.sendDate .entries.dayoff { color: #999; font-weight: normal; }
#pageTimetrackingEntry .sendModal label.sendDate.selected .entries { color: #4caf50; }
#pageTimetrackingEntry .sendModal label.sendDate.submitted .entries { color: #33b5e5; }




#pageTimetrackingEntry .mobileWeekSummary {
	font-size: 14px;
	line-height: 1.2em;
}

@media all and (max-width: 900px) {

	#pageTimetrackingEntry .customFooter .stat { margin: 0 1em 0 0; }
	#pageTimetrackingEntry .customFooter .stat svg { 
		width: 16px;
		height: 16px;
	}
}

@media all and (max-width: 600px) {
	#pageTimetrackingEntry .customFooter .apTooltipper { display: none; }
	#pageTimetrackingEntry .customFooter .apButtonGroup { 
		position: relative;
		top: auto;
		right: auto;
		-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
		     -o-transform: none;
		        transform: none;
		margin: 0;
	}
	#pageTimetrackingEntry .customFooter .apButtonGroup .apButton { 
		width: 49%; 
		max-width: 150px;
		margin: 0;
		padding: 0.5em 0;
	}
	#pageTimetrackingEntry .customFooter .apButtonGroup .apButton.send { float: right; } 
	#pageTimetrackingEntry .customFooter .apButtonGroup .divider { display: none; }

	.timetracking-mobile {
		width: 100%;
	}
}

@media all and (max-width: 450px) {
	#pageTimetrackingEntry .sendModal label.sendDate .hours { 
		left: 50%;
		/* right: 100px; */
	}
	#pageTimetrackingEntry .sendModal label.sendDate svg.warning {
		left: unset;
		right: 130px;
	}
	#pageTimetrackingEntry .sendModal label.sendDate {
		padding: 0.5em 50px;
	}
}