#ImportPOComponentList {
  position: relative;
}

#ImportPOComponentList .componentTable {
  width: 100%;
  font-size: 0.8em;
  background-color: #fff;
}

#ImportPOComponentList .componentTable {
  width: 100%;
  font-size: 14px;
  line-height: 1.5em;
  min-width: 850px;
}

#ImportPOComponentList .componentTable tr th {
  border-bottom: 1px solid #ccc;
}

#ImportPOComponentList .componentTable tr {
  border-bottom: 1px solid #ccc;
}

#ImportPOComponentList .componentTable tr.selected {
  background: #e1f5fe;
  border-left: 3px solid #03a9f4;
}

#ImportPOComponentList .componentTable tr.totals td {
  white-space: nowrap;
}

#ImportPOComponentList .componentTable th {
  font-size: 14px;
  vertical-align: bottom;
}

#ImportPOComponentList .componentTable td {
  padding: 0.5em 0.2em;
}

#ImportPOComponentList .componentTable th .apOptionBar .option {
  width: 50%;
}

#ImportPOComponentList .componentTable td.select {
  width: 20px;
}
#ImportPOComponentList .componentTable td.icon {
  width: 20px;
}
#ImportPOComponentList .componentTable td.name {
  width: auto;
}
#ImportPOComponentList .componentTable td.fixed {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
}

#ImportPOComponentList .componentTable th.fixed,
#ImportPOComponentList .componentTable td.fixed {
  text-align: center;
  position: relative;
  padding-left: 3px;
  padding-right: 3px;
}

#ImportPOComponentList .componentTable input.componentNameInput {
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0 30px 0 0;

  border: 0;

  font-size: 14px;
  line-height: 30px;
}

#ImportPOComponentList .componentTable input.componentNameInput.input-error {
  border-bottom: 3px solid #ff4444;
  color: #ff4444;
}

#ImportPOComponentList .componentTable td.fixed input.componentRowInput {
  width: 100%;
  text-align: right;
  margin: 0;

  border: 1px solid #ccc;
  border-bottom-width: 3px;

  font-size: 14px;
  line-height: 30px;
}

#ImportPOComponentList .componentTable td.fixed {
  padding: 0 5px 0 5px;
}

#ImportPOComponentList input.componentRowInput {
  padding: 0 25px 0 5px;
}

#ImportPOComponentList .componentTable td.fixed input.fixed-price-input {
  padding: 0 30px 0 5px;
}

#ImportPOComponentList
  .componentTable
  td.fixed
  input.componentRowInput.priceError {
  color: #ff4444;
  border-bottom-color: #ff4444;
}

#ImportPOComponentList
  .componentTable
  td.fixed
  input.componentRowInput.calculatableAvailable {
  border-bottom-color: #2bbbad;
}

#ImportPOComponentList .componentTable td.fixed input.componentRowInput:focus {
  outline: 0;
  border-color: #33b5e5;
}

#ImportPOComponentList .componentTable td.fixed .unit {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 25px;
  line-height: 30px;
  margin-top: -15px;
  text-align: left;
  font-size: 12px;
  color: #aaa;

  overflow: hidden;
  text-overflow: ellipsis;
}

#ImportPOComponentList .componentTable td.fixed .calculatedCount {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  padding: 0.5em;
  border-top: 3px solid #2bbbad;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;

  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);

  -webkit-transition: opacity 0.4s ease, transform 0.4s ease;
  -moz-transition: opacity 0.4s ease, transform 0.4s ease;
  -ms-transition: opacity 0.4s ease, transform 0.4s ease;
  -o-transition: opacity 0.4s ease, transform 0.4s ease;
  transition: opacity 0.4s ease, transform 0.4s ease;
}
#ImportPOComponentList
  .componentTable
  td.fixed
  input.componentRowInput:focus
  + .calculatedCount {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);

  opacity: 1;
  pointer-events: auto;
}

#ImportPOComponentList .componentTable td.fixed .calculatedCount:after {
  bottom: 100%;
  left: 50px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(43, 187, 173, 0);
  border-bottom-color: #2bbbad;
  border-width: 10px;
  margin-left: -10px;
}

#ImportPOComponentList .componentTable td.fixed .calculatedCount svg {
  width: 16px;
  height: 16px;
  margin: -3px 5px 0 0;
}

#ImportPOComponentList .componentTable td.fixed .calculatedCount small {
  color: #999;
}

#ImportPOComponentList .componentTable td svg {
  width: 20px;
  height: 20px;
}

#ImportPOComponentList .componentTable td.componentCode {
  font-weight: 700;
}

#ImportPOComponentList .componentTable td.componentName {
  white-space: nowrap;
}

#ImportPOComponentList .componentTable td.componentCount {
}

#ImportPOComponentList .componentTable td.componentPrice {
  padding-left: 1em;
  text-align: right;
}

#ImportPOComponentList .componentTable td.totalText {
  font-weight: 700;
  text-align: right;
  padding-right: 1em;
}

#ImportPOComponentList .componentTable td.totalValue {
  font-weight: 700;
  text-align: right;
  padding-left: 1em;
}

/*
 *
 *
 *
 *
 * */

#ImportPOComponentList thead.table-head {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

#ImportPOComponentList thead.table-head th {
  padding: 0 0.5em;
  height: 40px;
  line-height: 40px;
  text-align: center;
  /* border-bottom: 1px solid rgba(0,0,0,0.05); */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

#ImportPOComponentList td.fixed.c-type {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
}

#ImportPOComponentList td.fixed.actions {
  width: 46px;
  min-width: 46px;
  max-width: 46px;
  height: 48px;
}

#ImportPOComponentList td.fixed.c-price {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

#ImportPOComponentList td.fixed.c-balance {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}

#ImportPOComponentList td.fixed.c-count {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

#ImportPOComponentList td.fixed.c-unit {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

#ImportPOComponentList td.fixed.c-target {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}

#ImportPOComponentList td.fixed.c-entry {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

#ImportPOComponentList td.fixed.c-final-destination {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

#ImportPOComponentList td.fixed.vat {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

#ImportPOComponentList td.fixed.netvisor-vat {
    width:     200px;
    min-width: 200px;
    max-width: 200px;
}

#ImportPOComponentList td.c-name {
  position: relative;
  min-width: 190px;
  
  /* border-right: 1px solid rgba(0,0,0,0.05); */
}

#ImportPOComponentList td.c-name .name {
  position: absolute;
  top: 2px;
}

#ImportPOComponentList td.c-name small {
  color: #777;
  position: absolute;
  padding-left: 10px;
  top: 2em;
}

#ImportPOComponentList .componentTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#ImportPOComponentList td.fixed .input-menu.input-error,
#ImportPOComponentList td.fixed input.componentRowInput.input-error {
  color: #ff4444;
  border-bottom-color: #ff4444;
}

#ImportPOComponentList .noSelect {
  height: 80px;
  text-align: center;
  font-weight: 700;
  color: #777;
}

#ImportPOComponentList .noWordWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

#ImportPOComponentList .balanceTooltip .location {
  padding-right: 10px;
}

#ImportPOComponentList td.c-balance .location-number {
  position: absolute;
  top: 0;
  font-size: 10px;
  left: 10px;
  white-space: nowrap;
}
#ImportPOComponentList td.c-balance .reserve {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  right: 10px;
  white-space: nowrap;
}

#ImportPOComponentList td.fixed.c-price small.price {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  right: 0px;
  white-space: nowrap;
  color: #aaa;
}

#ImportPOComponentList td.fixed.total {
  max-width: 100px;
}

#ImportPOComponentList td.fixed.dispatch-ref {
  width: 150px;
}

#ImportPOComponentList td.fixed.netvisor-vat {
  max-width: 100px;
}

#ImportPOComponentList .netvisor-vat-input {
  width: 100px;
}
#ImportPOComponentList td.fixed.cost-center-vat {
  max-width: 75px;
}
#ImportPOComponentList .cost-center-input {
  width: 75px;
}

#ImportPOComponentList td.fixed .input-menu {
  width: 100%;
  margin: 0;
  padding: 0 30px 0 2px;
  border: 1px solid #ccc;
  border-bottom-width: 3px;
  font-size: 14px;
  line-height: 30px;
}

#ImportPOComponentList td.fixed .input-menu.disabled {
  background: #eee;
  color: #999;
  color: #999;
}

#ImportPOComponentList input:disabled {
  background: #eee;
  color: #999;
  color: #999;
}

#ImportPOComponentList td.fixed .input-menu-button {
  position: absolute;
  top: 0;
  height: 100%;
  right: 2px;
}

#ImportPOComponentList td.fixed .no-select {
  color: #aaa;
}

#ImportPOComponentList td.fixed .target-container {
  position: relative;
}

#ImportPOComponentList td.fixed .target-name {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  position: absolute;
  top: 0px;
  line-height: normal;
  width: 100%;
}

#ImportPOComponentList td.fixed .target-code {
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  position: absolute;
  bottom: 0px;
  line-height: normal;
  width: 100%;
  margin-left: 10px;
  white-space: nowrap;
}

#ImportPOComponentList td.fixed .target-order {
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  position: absolute;
  top: 0px;
  line-height: normal;
  width: 100%;
  margin-left: 10px;
}

#ImportPOComponentList td.fixed .count-helper-top {
  position: absolute;
  top: 0;
  font-size: 8px;
  left: 10px;
  white-space: nowrap;
  line-height: normal;
}

#ImportPOComponentList td.fixed .count-helper-bottom {
  position: absolute;
  bottom: 2px;
  font-size: 8px;
  left: 10px;
  white-space: nowrap;
  line-height: normal;
}

#ImportPOComponentList td.fixed .count-container {
  position: relative;
}

#ImportPOComponentList .topHoverBar {
  background-color: #fff;
}
#ImportPOComponentList .topHoverBar {
  border-bottom: 3px solid #fff;

  -webkit-transition: border-bottom 0.4s ease;
  -moz-transition: border-bottom 0.4s ease;
  -ms-transition: border-bottom 0.4s ease;
  -o-transition: border-bottom 0.4s ease;
  transition: border-bottom 0.4s ease;
}
#ImportPOComponentList .ApStickyBar.sticky .topHoverBar {
  border-bottom-color: #33b5e5;
}

#ImportPOComponentList .hideSelectList {
  cursor: pointer;
  background-color: #f7f7f7;
  color: #888;
  text-align: center;
  font-weight: 700;
  padding: 10px;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}

#ImportPOComponentList .apSelect .results .item .sComponent {
  line-height: 1.2em;
  position: relative;
}

#ImportPOComponentList .apSelect .results .item .sComponent .name {
  padding-right: 0.5em;
}

#ImportPOComponentList .apSelect .results .item .sComponent .value {
  font-weight: 700;
}

#ImportPOComponentList .apSelect .results .item .sComponent .balanceLocations {
  position: absolute;
  right: 1em;
  top: 0;
  font-size: 12px;
}

#ImportPOComponentList .apSelect .results .item .sComponent .balance {
  position: absolute;
  right: 1em;
  top: 14px;
  font-size: 14px;
}

#ImportPOComponentList .apSelect .results .item .sComponent .balanceFree {
  position: absolute;
  right: 1em;
  top: 26px;
  font-size: 10px;
}

#ImportPOComponentList .apSelect .results .item .sComponent .sIdentifier {
  font-size: 0.8em;
  padding-left: 2em;
  padding-top: 4px;
  padding-bottom: 4px;
}

#ImportPOComponentList .updatePoPriceButton {
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

#ImportPOComponentList table.apInputStack .apAddon.only-current-sypplier {
  height: 46px;
}

#confirmPoPriceModal .text {
  padding-right: 0.5em;
}

#confirmPoPriceModal .value {
  font-weight: 700;
  text-align: right;
}

#ImportPOComponentList .componentTable tr td.groupTd {
  min-width: 24px;
  max-width: 24px;
  width: 24px;
}

#ImportPOComponentList .componentTable tr td.selectTd {
  min-width: 24px;
  max-width: 24px;
  width: 24px;
  padding: 0;
  text-align: center;
}
#ImportPOComponentList .componentTable tr td.selectTd svg {
  width: 18px;
  height: 18px;
}

#ImportPOComponentList .componentTable tr.inGroup td.groupTd {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fafafa;
}
#ImportPOComponentList .componentTable tr.inGroup:not(.groupLast) td.groupTd {
  border-bottom: 0;
}

#ImportPOComponentList .componentTable tr.inGroup td.c-type {
  box-shadow: inset 10px 0px 6px -6px rgba(204, 204, 204, 0.4);
}
#ImportPOComponentList .componentTable tr.inGroup td.selectTd {
  box-shadow: inset -10px 0px 6px -6px rgba(204, 204, 204, 0.4);
}

/* #ImportPOComponentList .componentTable tr.groupRow { */
/* background-color: #fafafa; */
/* } */

#ImportPOComponentList .componentTable tr.groupRow td.groupTd {
  -webkit-transition: transform 0.4s ease;
  -moz-transition: transform 0.4s ease;
  -ms-transition: transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
}

#ImportPOComponentList .componentTable > tbody > tr.groupRow.open td.groupTd {
  border-bottom: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

#ImportPOComponentList .componentTable > tbody > tr:not(.inGroup) {
  background-color: #fafafa;
}

#ImportPOComponentList .componentTable .vat-input {
  width: 100%;
  margin: 0;
  border: 1px solid #ccc;
  border-bottom-width: 3px;
  font-size: 14px;
  text-align: right;
  height: 34px;
  background-color: #fff;
}

#ImportPOComponentList .componentTable .netvisor-vat-input {
    width: 100%;
    margin: 0;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    text-align: left;
    height: 34px;
    background-color: #fff;
}
#ImportPOComponentList .componentTable .cost-center-input {
    width: 100%;
    margin: 0;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    text-align: left;
    height: 34px;
    background-color: #fff;
}

#ImportPOComponentList .componentTable .vat-input:disabled {
  background: #eee;
  color: #999;
}

#ImportPOComponentList .componentTable .vat-input.validator-error {
  border-bottom-color: #ff4444;
  background: #ffcdd2;
}

#ImportPOComponentList .componentTable .groupVatContainer input {
  padding: 0;
  text-align: center;
}

#ImportPOComponentList .componentTable .nameTd .apInputSmallBlock input {
  text-align: left;
  padding: 0 0 0 6px;
}

#ImportPOComponentList .orderEntryTooltip .infoTable .header {
  font-weight: 700;
}
#ImportPOComponentList .orderEntryTooltip .infoTable .infoLabel {
  text-align: left;
}
#ImportPOComponentList .orderEntryTooltip .infoTable .valueText {
  text-align: left;
}

#ImportPOComponentList td.fixed.c-unit input {
  padding: 0;
  padding-left: 2px;
  text-align: left;
}

#ImportPOComponentList .icon-button {
  cursor: pointer;
}
