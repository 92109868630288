#poSettings .formHeader svg {
    margin-right: 0.2em;
    color: #AAA;
}


#poSettings .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#poSettings .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }
#poSettings .hasTooltip {
    text-decoration: underline;
}

#poSettings .poTemplateContainer {
     position: relative;
 }
#poSettings .poTemplateInfoButton {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
}

#poSettings .previewContainer {
    background: #eee;
    padding: 2em 0.5em 0.5em;
    -webkit-box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
    margin-bottom: 2em;
    text-align: left;
    font-weight: 700;
    margin-top: 1em;
}
#poSettings .previewContainer .top-left {
    font-size: 0.8em;
    position: absolute;
    left: 4px;
    top: 4px;
}

#poSettings .indentleft {
    margin-left: 1em;
}

#PoTemplateInfo th { padding-right: 1em; padding-top: 1em; }
#PoTemplateInfo td { font-size: 0.9em; }
