.apList {
    width: 100%;
    min-height: 100px;
    max-height: 600px;
    background: #eee;

    box-shadow: inset 0 5px 20px rgba(0,0,0,0.2);
    padding: 1em;

    overflow: auto;
    border: 1px solid #ccc;
    position: relative;
}

.apList-no-height-restrict {
    max-height: max-content;
}

.apList .newItem {
    margin: 0 0 0.5em;
    border: 1px solid #ddd;
    border-left: 3px solid #ddd;
    padding: 1em;
    padding-left: 40px;
    position: relative;
    cursor: pointer;
}

.apList .newItem:hover {
    background: #f9f9f9;
    border-left-color: #ccc;
}

.apList > .item,
.apListDragHelper {
    background: #fff;
    border-left: 5px solid #eee;
    margin: 0 0 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    line-height: 1.2em;
    position: relative;
}
.apList > .item:hover { border-left-color: #ddd; }

.apList > .item.clickable,
.apListDragHelper.clickable { border-left-color: #0099CC; cursor: pointer; }
.apList > .item.clickable:hover { border-left-color: #33b5e5; }

.apList > .item.warning,
.apListDragHelper.warning { border-left-color: #FF8800; }
.apList > .item.warning.clickable:hover { border-left-color: #ffbb33; }

.apList > .item.error,
.apListDragHelper.error { border-left-color: #ff4444; }
.apList > .item.error.clickable:hover { border-left-color: #ef5350; }

.apList > .item.success,
.apListDragHelper.success { border-left-color: #4caf50; }
.apList > .item.success.clickable:hover { border-left-color: #81c784; }

.apList > .item.disabled,
.apListDragHelper.disabled { border-left-color: #ccc; background: #f9f9f9; }
.apList > .item.disabled.clickable:hover { border-left-color: #ddd; }

.apList > .item.removed,
.apListDragHelper.removed { border-left-color: #ff4444; background: #eee; text-decoration: line-through; }
.apList > .item.removed.clickable:hover { border-left-color: #ef5350; }



.apList > .item.withIcon,
.apListDragHelper.withIcon { padding-left: 40px; }

.apList > .item.haveActions,
.apListDragHelper.haveActions { padding-right: 60px; }

.apList > .item.sortable,
.apListDragHelper.sortable { padding-right: 40px; }

.apList > .item.haveActions.sortable,
.apListDragHelper.haveActions.sortable { padding-right: 110px; }



.apListDragHelper { box-shadow: 0 5px 20px rgba(0,0,0,0.5); opacity: 0.8; }

.apList .newItem > svg,
.apList > .item .icon,
.apListDragHelper .icon {
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 20px;
}

.apList > .item .dragHandle,
.apListDragHelper .dragHandle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    background: #f9f9f9;
    color: #999;
    border-left: 1px solid #eee;
    cursor: ns-resize;
}

.apList > .item .dragHandle:hover,
.apList > .item .dragHandle:focus,
.apListDragHelper .dragHandle {
    background: #eee;
    color: #555;
    border-left: 1px solid #ccc;
    outline: 0;
}

.apList > .item .dragHandle svg,
.apListDragHelper .dragHandle svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.apList > .item .dragHandle svg.dragging,
.apListDragHelper .dragHandle svg.wait { display: none; }

.apList > .item .itemContent,
.apListDragHelper .itemContent {
    padding: 0.5em;
    overflow: hidden;
}


.apList > .item .columns,
.apListDragHelper .columns {
    position: absolute;
    top: 50%;
    right: 60px;
    border-left: 1px solid #ccc;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apList > .item.disabled .columns,
.apListDragHelper.disabled .columns { background: #f9f9f9; }

.apList > .item.removed .columns,
.apListDragHelper.removed .columns { background: #eee; }


.apList > .item.haveActions .columns,
.apListDragHelper.haveActions .columns { right: 110px; }

.apList > .item .columns .column,
.apListDragHelper .columns .column {
    display: inline-block;
    padding: 0.2em 0.5em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.apList > .item .apDropdown,
.apListDragHelper .apDropdown {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 10px;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apList > .item .apDropdown.open { z-index: 11; }

.apList > .item.sortable .apDropdown,
.apListDragHelper.sortable .apDropdown { right: 50px; }
