#woocommerce-edit-product .modal-content-container {
    display: flex;
    flex-direction: row;
}

#woocommerce-edit-product .apModalFooter {
    overflow: visible;
}

@media screen and (max-width: 800px) {
    #woocommerce-edit-product .modal-content-container {
        flex-direction: column;
    }
}