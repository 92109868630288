#projectManagement .apTree .node .value { overflow: visible }
#projectManagement .apTree .node .value .blue { color: #2196f3 }
#projectManagement .apTree .node .value .green { color: #4caf50 }
#projectManagement .apTree .node .value .orange { color: #ff9800 }
#projectManagement .apTree .node .value .grey { color: #9e9e9e }
#projectManagement .apTree .node .value .red { color: #f44336 }

#projectManagement .apTree {
    min-height: 675px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
}

#projectManagement .apTree .node .value .image {
	width: 34px;
	height: 34px;
	background-color: #ddd;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border-radius: 999px;
	-webkit-box-shadow:inset 0 0 5px 0 rgba(0,0,0,0.5);
			box-shadow:inset 0 0 5px 0 rgba(0,0,0,0.5);
}

#projectManagement > .componentsListForm > .apBox {
    margin-top: 0;
}

#projectManagement .treeContainer {
    background: #eee;
    padding: 1em 0.5em 0.5em;
    -webkit-box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
}

#projectManagement .splitView:after {
    content: '';
    display: block;
    clear: both;
}

#projectManagement .splitView .left,
#projectManagement .splitView .right {
    float: left;
    width: 300px;
}

#projectManagement .splitView .right {
    width: calc( 100% - 300px );
}

#projectManagement .splitView .details {
    margin-left: 1em;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}


#projectManagement .detailPicker .apInputBlock .apSelect {
    background: #fff;
}

#projectManagement .detailPicker .apSimpleButton {
    width: 50px;
    height: 42px;
}

#projectManagement .detailPicker table.apInputStack .apAddon.noRightBorder {
    padding-left: 0;
    padding-right: 0;
    font-family: 'Roboto-Light', Arial, sans-serif;
}

#projectManagement .markProjectDoneBody table {
    display:inline-block;
    margin-top: 1em;
}

#projectManagement .markProjectDoneBody table td:nth-child(1) {
    text-align: right;
}

#projectManagement .markProjectDoneBody table td:nth-child(2) {
    padding-left: 1em;
    font-weight: 700;
    text-align: right;
}

#projectManagement .saveBar {
    position: relative;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}

#projectManagement .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }
#projectManagement .saveBar .footerMenu {
    padding: 0.5em;
    margin-left: 0.2em;
}
#projectManagement .saveBar .apDropdown.open .apDropdownMenu { z-index: 100; }

#projectManagement .saveBar .selContainer {
    position: absolute;
    left: 1em;
}

#projectManagement .saveBar .selContainer .head {
    font-weight: 700;
    margin-right: 1em;
    display: inline-block;
}

#projectManagement .saveBar .selContainer .sel {
	display: inline-block;
	margin: 0 2em 0 0;
	position: relative;
}

#projectManagement .saveBar .selContainer .sel.empty {
	font-size: 14px;
	color: #999;
}

#projectManagement .saveBar .selContainer .sel svg {
	width: 20px;
	height: 20px;
	margin: -3px 5px 0 0;
	vertical-align: middle;
}

#projectManagement .details .projectPath {
    margin: 0;
}

#projectManagement ul.apSteps li.step {
    max-width: 300px;
}

#projectManagement .details .projectPath .inner.current {
    position: relative;
    padding-right: 50px;
}

#projectManagement .details .projectPath .inner.current .apDropdown {
    position: absolute;
    z-index: 99;
    top: 50%;
    right: 0;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#projectManagement .settingSchedule table {
    width: 100%;
}
#projectManagement .settingSchedule table .apInputBlock {
    margin-bottom: 0;
}

#projectManagement .settingSchedule table tr.descriptionRow td  {
    font-size: 0.7em;
    padding-left: 1em;
}

#projectManagement .projectStatusBar {
    margin-bottom: 1em;
}

#projectManagement .extranetUserValue {
    background: #eee;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    padding: 0.2em 0.5em;
}
#projectManagement .extranetUserValue.active {
    border-bottom-color: #4caf50;
}

#projectManagement .extranetUserOption .username {
    position: absolute;
    top: 50%;
    right: 1em;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #999;
}
#projectManagement .extranetUserOption .username.active { 
    color: #4caf50;
    font-weight: bold;
}

#projectManagement .extranetUserOption .username svg { margin-right: 0.5em; }

#projectManagement .extranetApproverSettings {
    margin: 0 0 0.5em;
}

#projectManagement .extranetApproverSettings .apAddon.switchAddon {
    line-height: 41px;
}

#projectManagement .extranetApproverSettings .apAddon.primary {
    color: #0099CC;
}

#projectManagement .intend {
    margin-left: 20px;
}

#projectManagement .not-in-use {
    color: #999999;
}

#projectManagement .hide {
 
    color: red;
}


#warningMessage {
    /* height: 3em; */
    background: #ffe680;
    color: #660000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.05em;
    border-radius: 0.5em;
}

#warningMessage table {
    margin: 0.5rem;
}

#warningMessage td {
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
    /* border: 1px solid rgba(0,0,0,0.1); */
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,0.1);
}

#warningMessage td:nth-child(2) {
    white-space: nowrap;
    padding-left: 0.8rem;
}

#projectManagement .treeContainer .apSelect .results,
#offerBudget .offerContent .apSelect .results {
    position: relative;
}

#projectManagement .modalFooter .apButton.save {
	float: right;
}

#projectManagement .modalFooter {
    padding: 1em;
}

/* Project components tab takes a lot of space. */
@media (max-width: 1500px) {
    #projectManagement .hideOverflow1500 {
        overflow-x: auto;
    }
}