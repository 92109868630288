#woocommerce-order .summaryBlock {
    margin-right: 0;
}

#woocommerce-order .apForm {
    display: flex;
    flex-direction: row;
}

#woocommerce-order .apFormColumn {
    float: none;
    flex: 1;
}

@media screen and (max-width: 500px) {
    #woocommerce-order .apForm {
        flex-direction: column;
    }
}