#editAssignmentClass .subclass {

	font-size: 1em;
	border: 1px solid #eee;
	padding: 16px;
	margin-bottom: 16px;

}

#editAssignmentClass .toolbar {

	text-align: right;

}