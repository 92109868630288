
#projectManagement .componentTable > thead > tr.componentHeader {
    border-bottom: 1px solid #ccc;
}

#projectManagement .componentTable > thead > tr > th {
    font-size: 14px;
    vertical-align: bottom;
}

#projectManagement .componentTable > thead  > tr > th.calcHeader svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

#projectManagement .componentTable > thead > tr > th.fixed {
    text-align: center;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}
