#oSettings .formHeader svg {
    margin-right: 0.2em;
    color: #AAA;
}


#oSettings .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#oSettings .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }
#oSettings .hasTooltip {
    text-decoration: underline;
}

#oSettings .poTemplateContainer {
     position: relative;
 }
#oSettings .poTemplateInfoButton {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
}

#oSettings .previewContainer {
    background: #eee;
    padding: 2em 0.5em 0.5em;
    -webkit-box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
    margin-bottom: 2em;
    text-align: left;
    font-weight: 700;
    margin-top: 1em;
}
#oSettings .previewContainer .top-left {
    font-size: 0.8em;
    position: absolute;
    left: 4px;
    top: 4px;
}

#oSettings .indentleft {
    margin-left: 1em;
}

#oTemplateInfo th { padding-right: 1em; padding-top: 1em; }
#oTemplateInfo td { font-size: 0.9em; }


@media screen and (min-width: 650px) {

    #oSettings .miscSettingBlock{
        display: flex;
    }
}