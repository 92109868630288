#pageWorkHours {

}

.weekSummary {

}

.weekSummary .toggler {
	text-align: center;
	font-size: 14px;
	line-height: 20px;
	padding: 0.5em;
	cursor: pointer;
	border-top: 1px solid #eee;
}

.weekSummary .toggler:hover {
	background: #f9f9f9;
}

.weekSummary .toggler svg {
	width: 16px;
	height: 16px;
	margin: -2px 5px 0 0;

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;
}

.weekSummary.open .toggler svg {
	-webkit-transform: rotate(180deg);
	   -moz-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	     -o-transform: rotate(180deg);
	        transform: rotate(180deg);
}



.apStatBoxContainer {
    display: flex;
    width: 100%;
}

.apStatBoxContainer .apStatBox {
    flex: 1;
    min-height: 50px;
    padding: 0 0 0 40px;
    margin: 0 0.5em 0.5em;

    background: #f9f9f9;
	border-bottom: 3px solid #ccc;

	position: relative;
}

.apStatBoxContainer .apStatBox svg {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 24px;
	height: 24px;
}

.apStatBoxContainer.fullWidth .apStatBox:first-child { margin-left: 0; }
.apStatBoxContainer.fullWidth .apStatBox:last-child { margin-right: 0; }


#pageWorkHours .interpretation {
	font-size: 13px;
	line-height: 1.5em;
	padding: 1em;
	border-left: 3px solid rgba(0,0,0,0.2);
	background: rgba(0,0,0,0.05);
	margin: 1em 0;
}

#pageWorkHours .interpretation .error {
	color: #ff4444;
	margin: 0 0 0.5em;
	border-left: 3px solid #ff4444;
	background: rgba(255,255,255,0.5);
	padding: 0.5em;
}


@media all and (max-width: 700px) {
	
	.weekSummary {
		display: none;
	}

}