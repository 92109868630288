#orderReceiverDataSelect  .receiver-indent {
    margin-left: 20px;
}

#orderReceiverDataSelect .col-container {
    width: 100%;
}

#orderReceiverDataSelect .col-left {
    width: 48%;
    display: inline-block;
    margin-right: 2%;
}
#orderReceiverDataSelect .col-right {
    width: 48%;
    display: inline-block;
    margin-left: 2%;
}
