.viestisuora-float-right {
	position: relative;
	float: right;
	margin: 1em;
	z-index: 2;
}





