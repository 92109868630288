.apModalWrapper {
	position: fixed;
	z-index: 5000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);

	opacity: 0;
	pointer-events: none;

	-webkit-transition: opacity 0.4s ease;
	   -moz-transition: opacity 0.4s ease;
	    -ms-transition: opacity 0.4s ease;
	     -o-transition: opacity 0.4s ease;
	        transition: opacity 0.4s ease;
}

.apModalWrapper.show {
	opacity: 1;
	pointer-events: auto;
}

.apModalWrapper .apModal {
	position: absolute;
	top: 5vh;
	left: 50%;
	width: 100%;
	max-width: 1200px;
	max-height: 90vh;
	background: #fff;

	box-shadow: 0 10px 40px rgba(0,0,0,0.5);

	-webkit-transform: translateX(-50%) scale(1.2);
	   -moz-transform: translateX(-50%) scale(1.2);
	    -ms-transform: translateX(-50%) scale(1.2);
	     -o-transform: translateX(-50%) scale(1.2);
	        transform: translateX(-50%) scale(1.2);

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;
}

.apModalWrapper .apModal.narrow { max-width: 600px; }
.apModalWrapper .apModal.medium { max-width: 800px; }

.apModalWrapper .scroll-y { overflow-y:auto; }
.apModalWrapper .scroll-x { overflow-x:auto; }
.apModalWrapper .scroll { overflow:auto; }


.apModalWrapper.show .apModal {
	-webkit-transform: translate(-50%) scale(1);
	   -moz-transform: translate(-50%) scale(1);
	    -ms-transform: translate(-50%) scale(1);
	     -o-transform: translate(-50%) scale(1);
	        transform: translate(-50%) scale(1);
}

.apModalWrapper .apModal .closeCross {
	position: absolute;
	z-index: 99;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
}

.apModalWrapper .apModal .apModalHeader,
.apModalWrapper .apModal .apModalFooter {
	max-height: 100px;
	overflow: hidden;
} 


.apModalWrapper .apModal .apModalHeader {
	border-bottom: 1px solid #eee;
}

.apModalWrapper .apModal .apModalBody {
	max-height: calc( 90vh - 200px );
	overflow: auto;
}

.apModalWrapper .apModal.overflow .apModalBody {
    max-height: none;
}

.overflow  {
	overflow: visible !important;
}

.apModalWrapper .apModal .apModalFooter {
	border-top: 1px solid #eee;
}

@media all and (max-width: 700px) {
	.apModalWrapper {
		-webkit-transition: none;
		   -moz-transition: none;
			-ms-transition: none;
			 -o-transition: none;
				transition: none;
	}
	.apModalWrapper.show .apModal {
		-webkit-transform: translateX(-50%);
		   -moz-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			 -o-transform: translateX(-50%);
				transform: translateX(-50%);
	}
	.apModalWrapper .apModal {
		-webkit-transform: translateX(-50%);
		   -moz-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			 -o-transform: translateX(-50%);
				transform: translateX(-50%);
	}
}
