.radio-switch-container input {
    opacity: 0;
    height: 0;
    width: 0;
}

.radio-switch-label {
    margin-left: 1em;
}

.label-container {
    display: flex;
}

.label-container:hover .radio-switch-check-button {
    background-color: #C5EBF7;
}

.label-container:hover .radio-switch-label {
    color: #0099CC;
}

.radio-switch-check-button {
    display: flex;
    height: 1.7em;
    width: 1.7em;
    background-color: #EAEAEA;
    border-radius: 50%;
    margin-bottom: 15px;
}

.radio-switch-checked {
    display: flex;
    border: 3px solid #0099CC;
    background-color: #FFFFFF;

    border-radius: 50%;
    margin-bottom: 15px;
    display: flex;
    height: 1.7em;
    width: 1.7em; 
    align-items: center;
    justify-content: center;
}

.radio-switch-label-checked {
    color: #0099CC;
    margin-left: 1em;
}

.radio-switch-inner-checked {
    display: flex;
    border-radius: 50%;
    background-color:  #0099CC;
    height: 1em;
    width: 1em;
}