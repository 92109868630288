.priceInputs .valueInputContainer {
    position: relative;
}
.priceInputs .valueInputContainer .valueInput {
    padding-right: 30px;
    text-align: right;
}
.priceInputs .valueInputContainer .unit {
    position: absolute;
    right: 10px;
    bottom: 2px;

}
.priceInputs .infoCol {
    padding-left: 1.2em;
}
