#mobileNav .nav-group {
	margin-bottom: 2em;
	list-style: none;
	padding: 0;
}

#mobileNav .nav-group .nav-group {
	margin-bottom: 0;
	padding-left: 1em;
	background: rgba(0,0,0,0.1);
	background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 20%, rgba(0,0,0,0.1) 100%);
	background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 20%,rgba(0,0,0,0.1) 100%);
	background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 20%,rgba(0,0,0,0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 );
	max-height: 0;
	overflow: hidden;
	/* transition: max-height 0.4s ease; */
}

#mobileNav .nav-group .nav-group:before,
#mobileNav .nav-group .nav-group:after {
	content: '';
	position: absolute;
	top: 40px; /* If you change this also change calcMaxHeight-function in SideNavMenu.js */ 
	left: -1em;
	right: 0;
	height: 20px;
	background: -moz-linear-gradient(top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#00000000',GradientType=0 );
	pointer-events: none;
	/* transition: opacity 0.4s ease; */
	opacity: 0;
}

#mobileNav .nav-group .nav-group:after {
	top: auto;
	bottom: 0;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=0 );
}

#mobileNav .nav-group li.item.subnav-open > .nav-group {
	max-height: 400px; /* This will be calculated via JS */
}

#mobileNav .nav-group li.item.subnav-open > .nav-group:before,
#mobileNav .nav-group li.item.subnav-open > .nav-group:after { opacity: 1; }

#mobileNav .nav-group li.item svg.subnav-indicator {
	position: absolute;
	top: 12px;
	right: 12px;
	width: 16px;
	height: 16px;
	fill: #666;
	/* transition: transform 0.4s ease; */
	pointer-events: none;
}

#mobileNav .nav-group li.item.subnav-open > svg.subnav-indicator {
	transform: rotate(-90deg);
}

#mobileNav .nav-group li.item {
	font-family: 'Roboto', Arial, sans-serif;
	border-bottom: 1px solid rgba(0,0,0,0.1); /* If you change this also change calcMaxHeight-function in SideNavMenu.js */ 
	position: relative;
}

#mobileNav .nav-group li.item.disabled a { pointer-events: none; }
#mobileNav .nav-group li.item.disabled a .title:after { 
	content: 'DISABLED';
	display: inline-block;
	font-size: 11px;
	line-height: 1em;
	padding: 2px 5px;
	margin-left: 5px;
	color: #fff;
	background: #e91e63;
	border-radius: 99px;
	pointer-events: none;
	opacity: 0.5;
}



#mobileNav .nav-group li.item a {
	display: block;
	height: 40px; /* If you change this also change calcMaxHeight-function in SideNavMenu.js */ 
	text-decoration: none;
	color: #666;
	position: relative;
}

#mobileNav .nav-group li.item a:hover {
	background: rgba(255,255,255,0.3);
	color: #0099CC;
}
#mobileNav .nav-group li.item a:hover > svg { fill: #0099CC; }

#mobileNav .nav-group li.item.selected a {
	background: rgba(255,255,255,0.7);
	color: #0F789F;
}
#mobileNav .nav-group li.item.selected a > svg { fill: #0F789F; }


#mobileNav .nav-group li.item a > svg {
	position: relative;
	z-index: 2;
	width: 24px;
	height: 24px;
	margin: 8px 13px;
	fill: #666;
}

#mobileNav .nav-group li.item a > .title {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 50px;
	width: 250px;
	height: 40px; /* If you change this also change calcMaxHeight-function in SideNavMenu.js */ 
	line-height: 40px; /* If you change this also change calcMaxHeight-function in SideNavMenu.js */ 
	white-space: nowrap;
	/* transition: opacity 0.4s ease; */
}

#mobileNav .nav-group .nav-group li.item a > svg {
	width: 20px;
	height: 20px;
	margin: 10px 15px;
}

#mobileNav .nav-group li.item a > .badge {
	position: absolute;
	top: 3px;
	left: 3px;
	background: #FF8800;
	z-index: 2;
}
#mobileNav .nav-group li.item a > .badge.error { background: #ff4444; z-index: 3; }
#mobileNav .nav-group li.item.doubleBadges a > .badge.warning { left: 20px; }

#mobileNav .nav-group .nav-group li.item a > .title { font-size: 90%; }

#mobileNav .nav-group li.divider {
	height: 20px;
	margin-top: 20px;
	padding: 0 0.5em;
	font-size: 14px;
	line-height: 20px;
	text-transform: uppercase;
	color: #999;
	white-space: nowrap;
	border-bottom: 1px solid rgba(0,0,0,0.1);

	/* -webkit-transition: opacity 0.4s ease;
	   -moz-transition: opacity 0.4s ease;
	    -ms-transition: opacity 0.4s ease;
	     -o-transition: opacity 0.4s ease;
	        transition: opacity 0.4s ease; */
}
