/*
#projectManagement table.componentTable td input.componentNameInput {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 30px;

    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(0,0,0,0);

}

#projectManagement table.componentTable td input.componentNameInput.error {
    color: #ff4444;
    border-bottom-color: #ff4444;
    border-bottom: 3px solid;
}

#projectManagement table.componentTable td.fixed input.componentRowInput {
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0 30px 0 0;

    border: 1px solid #ccc;
    border-bottom-width: 3px;

    font-size: 14px;
    line-height: 30px;
}

#projectManagement table.componentTable td.fixed input.componentRowInput.error {
    color: #ff4444;
    border-bottom-color: #ff4444;
}

#projectManagement table.componentTable td.fixed input.componentRowInput.warning {
    color: #FF8800;
    border-bottom-color: #FF8800;
}

#projectManagement table.componentTable td.fixed input.componentRowInput:focus {
    outline: 0;
    border-color: #33b5e5;
}

#projectManagement table.componentTable td.fixed .unit {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 25px;
    line-height: 30px;
    margin-top: -15px;
    text-align: left;
    font-size: 12px;
    color: #aaa;

    overflow: hidden;
    text-overflow: ellipsis;
}

#projectManagement table.componentTable td.fixed .calculatedCount {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    padding: 0.5em;
    border-top: 3px solid #2BBBAD;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    opacity: 0;
    pointer-events: none;

    -webkit-transform: translateY(-10px);
       -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
         -o-transform: translateY(-10px);
            transform: translateY(-10px);

    -webkit-transition: opacity 0.4s ease, transform 0.4s ease;
       -moz-transition: opacity 0.4s ease, transform 0.4s ease;
        -ms-transition: opacity 0.4s ease, transform 0.4s ease;
         -o-transition: opacity 0.4s ease, transform 0.4s ease;
            transition: opacity 0.4s ease, transform 0.4s ease;
}
#projectManagement table.componentTable td.fixed input.componentRowInput:focus + .calculatedCount {

    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
        -ms-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);

    opacity: 1;
    pointer-events: auto;
}

#projectManagement table.componentTable td.fixed .calculatedCount:after {
    bottom: 100%;
    left: 50px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(43, 187, 173, 0);
    border-bottom-color: #2BBBAD;
    border-width: 10px;
    margin-left: -10px;
}

#projectManagement table.componentTable td.fixed .calculatedCount svg {
    width: 16px;
    height: 16px;
    margin: -3px 5px 0 0;
}

#projectManagement table.componentTable td.fixed .calculatedCount small {
    color: #999;
}

#projectManagement table.componentTable td svg {
    width: 20px;
    height: 20px;
}

#projectManagement table.componentTable td.componentCode {
    font-weight: 700;
}

#projectManagement table.componentTable td.componentName {
    white-space: nowrap;
}

#projectManagement table.componentTable td.componentCount {

}

#projectManagement table.componentTable td.componentPrice {
    padding-left: 1em;
    text-align: right;
}

#projectManagement table.componentTable td.totalText {
    font-weight: 700;
    text-align: right;
    padding-right: 1em;
}

#projectManagement table.componentTable td.totalValue {
    font-weight: 700;
    text-align: right;
    padding-left: 1em;
}
*/

#projectManagement table.componentTable > tbody > tr.component {
    border-bottom: 1px solid #ccc;
}

#projectManagement table.componentTable > tbody > tr {
    border-left: 3px solid transparent;
}

#projectManagement table.componentTable > tbody > tr.selected {
    background: #e1f5fe;
    border-left: 3px solid #03a9f4;
}

#projectManagement table.componentTable > tbody > tr > td {
    padding: 0.5em 0.2em;
}

#projectManagement table.componentTable > tbody > tr > td.select { width: 20px; }
#projectManagement table.componentTable > tbody > tr > td.icon { width: 20px; }
#projectManagement table.componentTable > tbody > tr > td.fixed { width: 110px; min-width: 110px; max-width: 110px; }

#projectManagement table.componentTable > tbody > tr > td.fixed {
    text-align: center;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}

#projectManagement table.componentTable > tbody > tr > td.fixed.menu {
    width:     45px;
    min-width: 45px;
    max-width: 45px;
}

#projectManagement table.componentTable > tbody > tr > td.name { width: auto; }
#projectManagement table.componentTable > tbody > tr > td.name input.componentNameInput {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 30px;

    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(0,0,0,0);
}



#projectManagement table.componentTable > tbody > tr > td.saveNew div svg{
	float: left;
}

#projectManagement table.componentTable > tbody > tr > td .tallyButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

#projectManagement table.componentTable > tbody > tr > td .tallyButton svg {
    width: 12px;
    height: 12px;
}

#projectManagement table.componentTable > tbody > tr > td .billingTdInput {
    margin-right: 24px;
    position: relative;
}

#projectManagement table.componentTable > tbody > tr > td .billingTdIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#projectManagement table.componentTable > tbody > tr > td .billingTdIcon svg {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);

    -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease;

}
#projectManagement table.componentTable > tbody > tr.billingOpen > td .billingTdIcon svg {
    -webkit-transform: rotate(-90deg);
       -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
         -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
}


#projectManagement table.componentTable > tbody > tr.component.billingOpen {
    border-bottom: 0;
}

#projectManagement table.componentTable > tbody > tr.billingTr {
    border-bottom: 1px solid #ccc;
    background-color: #f9f9f9;
}

#projectManagement table.hourTypesTable {
    width: 100%;
}

#projectManagement table.hourTypesTable td.fixed {
    position: relative;
    min-width: 110px;
    max-width: 110px;
        width: 110px;

    min-height: 35px;
        height: 35px;
}

#projectManagement table.hourTypesTable td.fixed-switch { 
    min-width: 90px;
    max-width: 90px;
        width: 90px;
    text-align: center;
}

#projectManagement table.hourTypesTable td.saveNew { 
	position: relative;
	min-width: 50px;
    max-width: 90px;
        width: 50px;
    text-align: center;
}

#projectManagement table.hourTypesTable td div svg{
	float: left;
}

 
#projectManagement .componentTable tr td.groupTd {
  min-width: 24px;
  max-width: 24px;
  width: 24px;
}

#projectManagement .componentTable tr.inGroup td.groupTd {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fafafa;
}

#projectManagement .componentTable tr.inGroup:not(.groupLast) td.groupTd {
  border-bottom: 0;
}

#projectManagement .componentTable tr.groupRow td.groupTd {
  -webkit-transition: transform 0.4s ease;
  -moz-transition: transform 0.4s ease;
  -ms-transition: transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
}

#projectManagement .componentTable > tbody > tr.groupRow.open td.groupTd {
  border-bottom: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}