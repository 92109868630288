#pageDemands {
}

#pageDemands .infoTitle {
    color: #337ab7;
    font-weight: 700;
    margin-bottom: 0.5em;
}

#pageDemands .apInfo {
    margin-left: 8px;
}
#pageDemands .apInfo .notification {
    color: #ff9800;
}

#pageDemands .apInfo .alarm {
    color: #e53935;
}



#pageDemands .numberTd .notification {
    color: #ff9800;
    font-weight: 700;
}
#pageDemands .numberTd .alarm {
    color: #e53935;
    font-weight: 700;
}

#pageDemands .splitView {
    min-width: 900px;
    overflow: auto;
}

#pageDemands .splitView:after {
    content: '';
    display: block;
    clear: both;
}

#pageDemands .splitView .left,
#pageDemands .splitView .right {
    float: left;
    width: 300px;
}

#pageDemands .splitView .right {
    width: calc( 100% - 300px );
}

#pageDemands .splitView .details {
    margin-left: 1em;
    /* background: #fff; */
    /* box-shadow: 0 3px 10px rgba(0,0,0,0.1); */
}

#pageDemands .supplierList .supplier {
    border-left: 3px solid #ccc;
    border-right: 3px solid #fff;
    background: #fff;
    padding: 0.5em 1em 0.5em 32px;
    margin: 3px 0 3px 10px;
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.2em;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}
#pageDemands .supplierList .supplierSelected{
    height: 40px;
}

#pageDemands .suppliersHeader {
    border-bottom: 1px solid #ccc;
    border-right: 3px solid #fff;
    background: #fff;
    padding: 0.8em 0 0.8em 14px;
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.2em;
    font-size: 14px;
    position: relative;
    font-weight: 700;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

#pageDemands .suppliersHeader small {
    font-weight: normal;
    padding-left: 14px;
}

#pageDemands .suppliersHeader.selected,
#pageDemands .supplier.selected {
    border-right-color: #33b5e5;
}

#pageDemands .suppliersHeader.selected:after,
#pageDemands .supplier.selected:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 181, 229, 0);
    border-left-color: #33b5e5;
    border-width: 10px;
    margin-top: -10px;
}

#pageDemands .supplierList .supplier .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 16px;
    height: 16px;
    margin-top: -8px;
}
#pageDemands .supplierList .components {
    color: #999;
    font-size: 0.8em;
    position: absolute;
    right: 4px;
    bottom: 4px;
}

#pageDemands .supplierList .number {
    color: #AAA;
    font-size: 0.9em;
    padding-left: 10px;
}

#pageDemands .supplierList .orderDate {
    color: #AAA;
    font-size: 0.8em;
    top: 4px;
    position: absolute;
    right: 4px;
}

#pageDemands .supplierList .receiverType {
    font-size: 0.8em;
    color: #AAA;
}


#pageDemands .supplierList .receiverName {
    padding-bottom: 4px;
    padding-left: 4px;
}

#pageDemands .supplierList .componentName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

#pageDemands .supplierList .componentCode {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-left: 6px;
    color: #AAA;
    font-size: 0.9em;
    padding-bottom: 2px;
}

/*
#pageDemands .component {
    margin: 0 0 0.5em;
}

#pageDemands .component .header {
    margin: 0;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);

    border-left: 3px solid #ccc;
    -webkit-transition: border 0.4s ease;
       -moz-transition: border 0.4s ease;
        -ms-transition: border 0.4s ease;
         -o-transition: border 0.4s ease;
            transition: border 0.4s ease;

    padding: 0 100px 0 52px;

    position: relative;
}

#pageDemands .component.notPrimary .header {
    opacity: 0.6;
}

#pageDemands .component.detailsOpen .header .indicator {
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

#pageDemands .component .header .indicator {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 20px;
    height: 20px;
    margin: -10px 0 0;

    -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease;
}

#pageDemands .component .header .check {
    position: absolute;
    top: 50%;
    margin: -6px 0 0;
    left: 32px;
}

#pageDemands .component .header .price {
    position: absolute;
    top: 50%;
    margin: -10px 0 0;
    right: 10px;
    color: #999;
}
*/

#pageDemands .componentTable {
    width: 100%;
    font-size: 0.8em;
}

#pageDemands .targetIcon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
}

#pageDemands .numberTh,
#pageDemands .numberTd {
    text-align: right;
    padding: 0px 10px 0 0;
}

#pageDemands .numberTh svg {
    width: 12px;
    height: 12px;
}

#pageDemands .countInputContainer {
    position: relative;
}

#pageDemands .countInputContainer .countInput {
    width: 100%;
    text-align: right;
    margin: 2px 0;
    padding: 0px 30px 0 0;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    line-height: 20px;
}

#pageDemands .countInputContainer .unit {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 20px;
    line-height: 20px;
    margin-top: -10px;
    text-align: left;
    font-size: 12px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
}

#pageDemands .demandsComment {
    position: relative;
    margin-right: 2px;
}

#pageDemands .demandsComment .comment_input {
    width: 100%;

    margin: 2px 0;
    padding: 0px 30px 0 0;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    line-height: 20px;
}

#pageDemands .demandsComment svg.clearIcon {
	position: absolute;
	top: 50%;
	
    right: 2px;

	width: 18px;
	height: 18px;
	fill: #999; 
	cursor: pointer;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}



#pageDemands .countInputContainer .inputError {
    border-bottom-color: #ff4444;
    background: rgba(255, 205, 210, 0.25);
}

#pageDemands .tooltipTable .tText {
    text-align: left;
}

#pageDemands .tooltipTable .tValue {
    margin-left: 10px;
    padding-left: 10px;
    text-align: right;
}

#pageDemands .detailPicker .apInputBlock .apSelect {
    background: #fff;
}




#pageDemands .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em 0.5em 0 0.5em;
    border-top: 3px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#pageDemands .ApStickyBar.sticky .saveBar { border-color: #33b5e5; }

#pageDemands .ApStickyBar.noSticky .saveBar {
    background: #fff;
    padding: 1em;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

#pageDemands .saveBar .left {
    display: inline-block;
    width: 50%;
    text-align: left;
}
#pageDemands .saveBar .right {
    display: inline-block;
    width: 50%;
    text-align: right;
}

#pageDemands .saveBar .buttonContainer {
    display: inline-block;
    margin-left: 4px;
}

#pageDemands .marginBottom {
    margin-bottom: 2em;
}

#pageDemands .componentContainer {
    background: #fff;
    padding: 1em;
    margin-bottom: 5px;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    position: relative;
}



#pageDemands .componentContainer .name {
    color: #0099CC;
    font-weight: 700;
}

#pageDemands .componentContainer .code {
    color: #888;
    font-size: 0.8em;
    font-weight: 700;
    margin-left: 1em;
}

#pageDemands .componentContainer .headerPrice {
    position: absolute;
    right: 1em;
    top: 1.5em;
    color: #AAA;
    font-weight: 700;
}

#pageDemands .componentContainer .priceContainer,
#pageDemands .componentContainer .targetContainer {
    border-top: 1px solid #CCC;
    padding-top: 0.5em;
    padding-bottom: 1em;
}

#pageDemands .noDemand {
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    padding: 1em;

}

#pageDemands .notNeeded {
    border-top: 1px solid #CCC;
}

#pageDemands .viewSettings {
    background: #fff;
    padding: 1em;
    margin-bottom: 5px;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    position: relative;
}

#pageDemands .viewSettings .apSwitchBlock {
    margin-bottom: 4px;
}

#pageDemands .priceTable {
    font-size: 0.8em;
}

#pageDemands .priceTable .primary {
    width:     80px;
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

#pageDemands .priceTable .primary svg.SvgIcon {
    width: 10px;
    height: 10px;
}

#pageDemands .priceTable .supplier {
    padding-right: 2em;
}

#pageDemands .priceTable .price {
    padding-right: 2em;
}

#pageDemands .priceTable .storagePrice {
    border-bottom: 1px solid #AAA;
    background-color: #f3f3f3;
}





#pageDemands .saveBar .selectedRows {
    float: left;
    text-align: left;
}

#pageDemands .saveBar .selectedRows .list {
    position: absolute;
    bottom: calc( 100% + 10px );
    left: 0;
    padding: 1em;

    background: #fff;
    border-top: 3px solid #33b5e5;

    /* background: #e0f2f1; */
    /* border-top: 3px solid #80cbc4; */
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);

    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
       -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
         -o-transform: translateY(10px);
            transform: translateY(10px);

    -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s ease;
            transition: transform 0.4s ease, opacity 0.4s ease;
}

#pageDemands .saveBar .selectedRows .list.show {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
        -ms-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
}

#pageDemands .saveBar .selectedRows .list .scroller {
    max-height: 40vh;
    overflow: auto;
}


#pageDemands .saveBar .selectedComponentContainer {
    border-bottom: 1px solid #222;
}

#pageDemands .supplierSelect {
    position:relative;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    height: 42px;
    line-height: 44px;
    padding: 0 40px 0 10px;
    font-weight: 700;
    min-width: 150px

}

#pageDemands .saveBar .noSel {
    color: #AAA;
}

#pageDemands .supplierSelect .input-menu-button {
    position: absolute;
    top: -4px;
    height: 100%;
    right: 2px;
}
#pageDemands .supplierSelect .input-menu-title {
    position: absolute;
    top: 2px;
    left: 2px;
    display: inline-block;
    font-size: 10px;
    line-height: normal;
    color: #999;
}


#pageDemands .saveBar .sMenuRow {
    position: relative;
}
#pageDemands .saveBar .sMenuRow .name {

}
#pageDemands .saveBar .sMenuRow .count {
    position: absolute;
    right: 2px;
    bottom: 0;
    font-size: 0.9em;
    color: #AAA;
}

#pageDemands .targetTable td {
    position: relative;
    padding-top: 2px;
}

#pageDemands .targetTable tr.headerRow td .name {
    color: #0099CC;
    font-weight: 700;
    font-size: 12px;
    padding-bottom: 16px;
}

#pageDemands .targetTable tr.headerRow td .code {
    line-height: normal;
    color: #999;
    position: absolute;
    top: 22px;
    left: 10px;
    font-size: 11px;
}

#pageDemands .targetTable tr.headerRow td .price {
    color: #AAA;
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 2px;
    font-size: 10px;

}

#pageDemands .targetTable tr.headerRow td .priceTotal {
    color: #AAA;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 2px;
    font-size: 12px;
    font-weight: 700;
}

#pageDemands .targetTable tr.headerRow {
    border-top: 1px solid #CCC;
}

#pageDemands .targetTable tr.targetRow {
    font-size: 0.8em;
}

#pageDemands .targetTable .tRowPrice {
    padding-left: 1em;
    text-align: right;
}

#pageDemands .targetTable td.countTd {
    width: 120px;
    max-width: 120px;
    min-width: 120px;
}

#pageDemands .targetTable td.countTd .countInputContainer {
    padding-left: 1em;
}

#pageDemands .targetTable .tName,
#pageDemands .targetTable .tTarget {
    padding-left: 0.5em;
}



