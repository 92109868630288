#newPo .apModalWrapper .apModal .apModalBody {
    min-height: 500px;
}

#newPo .supplierInfo {
    margin-left: 1em;
}
#newPo .supplierInfo .mainTitle {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}
#newPo .supplierInfo .title {
    font-weight: 700;
    margin-left: 1em;
}
#newPo .supplierInfo .value {
    margin-left: 2em;
}

#newPo .receiverFields {

    margin-left: 1em;
}
