
.ApUserImage {
	display: inline-block;
	vertical-align: middle;
	margin: 0 0.5em 0 0;

	width: 48px;
	height: 48px;
	border-radius: 999px;
	border: 2px solid #ccc;

	background-color: #eee;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	position: relative;
}

.ApUserImage img {
	object-fit: cover;
}

.ApUserImage.block {
	display: block;
	margin: 0 auto;
}

.ApUserImage.withShadow {
	-webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
			box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
}

.ApUserImage .initials {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	text-align: center;
	font-family: 'Roboto', Arial, sans-serif;
	color: rgba(0,0,0,0.5);
}

.ApUserImage.size-tiny {
	width: 24px;
	height: 24px;
	border-width: 1px;
}
.ApUserImage.size-tiny .initials { font-size: 11px; }

.ApUserImage.size-small {
	width: 32px;
	height: 32px;
	border-width: 1px;
}
.ApUserImage.size-small .initials { font-size: 14px; }

.ApUserImage.size-large {
	width: 64px;
	height: 64px;
}
.ApUserImage.size-large .initials { font-size: 26px; }

.ApUserImage.size-huge {
	width: 100px;
	height: 100px;
	border-width: 3px;
}
.ApUserImage.size-huge .initials { font-size: 42px; }

.ApUserImage.group {
	display: flex;
	justify-content: center;
	align-items: center;
}
.ApUserImage.group svg {
	margin-right: 0;
}
