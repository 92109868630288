#OfferTemplates .offerTemplateContainer {
    position: relative;
}
#OfferTemplates .offerTemplateInfoButton {
    float: right;

    position: absolute;
    right: 1em;
    top: 1em;
}

#OfferTemplates table {
    margin: 0.5em;
}

#OfferTemplates th {
    padding-bottom: 0.5em;
}
#OfferTemplates .text {
    padding-right: 1em;
    font-weight: 700;
}
#OfferTemplates .description {

}

