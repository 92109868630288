
.ApNestedSelect {
	width: 100%;
	margin: 0 0 10px;
	border: 1px solid #ccc;
	border-bottom: 3px solid #ccc;
	background: #eee;
	position: relative;
}

.ApNestedSelect.focused { border-bottom-color: #33b5e5; }

.ApNestedSelect label {
	display: block;
	margin: 0;
	padding: 0 10px;
	background: #f9f9f9;
	border-bottom: 1px solid #ccc;
	
	font-family: 'Roboto', Arial, sans-serif;
	font-size: 14px;
    line-height: 30px;
    color: #999;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ApNestedSelect.focused label { color: #33b5e5; }

.ApNestedSelect label .value {
	font-family: 'Roboto-Light', Arial, sans-serif;
	color: #999;
}

.ApNestedSelect .breadcrump {
	width: 100%;
}

.ApNestedSelect .breadcrump td {
	text-align: center;
	line-height: 42px;
	padding: 0 0.2em;
	border-left: 1px solid #ccc;
}

.ApNestedSelect .breadcrump td.root {
	border: none;
	width: 40px;
	padding: 0;
}
.ApNestedSelect .breadcrump td.root svg {
	width: 20px;
	height: 20px;
}

.ApNestedSelect.disabled .breadcrump td.root svg { opacity: 0.2 }

.ApNestedSelect .breadcrump td.level {
	padding: 0;
	position: relative;
}
.ApNestedSelect .breadcrump td.level:after {
	content: '';
	position: absolute;
	z-index: 1;
	top: 0;
	right: -7px;
	width: 14px;
	bottom: 0;
	background: #eee;
	border-right: 1px solid #ccc;

	-webkit-transform: skew(-15deg);
	   -moz-transform: skew(-15deg);
	    -ms-transform: skew(-15deg);
	     -o-transform: skew(-15deg);
	        transform: skew(-15deg);
}

.ApNestedSelect.enabled .breadcrump td:hover,
.ApNestedSelect .breadcrump td.level.current {
	font-family: 'Roboto', Arial, sans-serif; 
	color: #33b5e5;
	background: #fff;
}

.ApNestedSelect.endReached .breadcrump td.level.current:after { border-right-width: 3px; }


.ApNestedSelect .breadcrump td.level.current:after,
.ApNestedSelect.enabled .breadcrump td.level:hover:after { background: #fff; }

.ApNestedSelect.enabled .breadcrump td:hover ~ .level {
	color: #ccc;
	background: #f9f9f9;
}
.ApNestedSelect.enabled .breadcrump td:hover ~ .level:after { background: #f9f9f9; border-right-color: #ddd; }

.ApNestedSelect .breadcrump td.level .inner {
	padding: 0 10px 0 20px;
	white-space: nowrap;
}

.ApNestedSelect .breadcrump td.input {
	background: #fff;
	position: relative;
}

.ApNestedSelect.readOnly .breadcrump td.input,
.ApNestedSelect.endReached .breadcrump td.input { background: #eee; }
.ApNestedSelect.readOnly.focused .breadcrump td.input { background: #fff; }
.ApNestedSelect.disabled .breadcrump td.input { background: #ccc; }


.ApNestedSelect .breadcrump td.input input {
	background: transparent;
	border: 0;
	width: 100%;
	padding: 0 30px 0 20px;

	font-family: 'Roboto-Light', Arial, sans-serif;
	font-size: 16px;
	line-height: 42px;
	color: #000;
}

.ApNestedSelect .breadcrump td.input input:focus { outline: 0; }
.ApNestedSelect .breadcrump td.input input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fffde7 inset;
    /* -webkit-text-fill-color: #0099CC !important; */
}

.ApNestedSelect .breadcrump td.input svg.arrow {
	position: absolute;
    bottom: 14px;
    right: 10px;
    width: 20px;
    height: 20px;

    -webkit-transition: transform 0.4s ease, opacity 0.4s 0.5s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s 0.5s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s 0.5s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s 0.5s ease;
            transition: transform 0.4s ease, opacity 0.4s 0.5s ease;

    pointer-events: none;
}

.ApNestedSelect.readOnly .breadcrump td.input svg.arrow,
.ApNestedSelect.endReached .breadcrump td.input svg.arrow { opacity: 0; }

.ApNestedSelect.resultShowing .breadcrump td.input svg.arrow {
	-webkit-transform: rotate(90deg);
	   -moz-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
	     -o-transform: rotate(90deg);
	        transform: rotate(90deg);
}

.ApNestedSelect .results {
	position: absolute;
	z-index: 101;
	top: calc( 100% + 3px );
	left: -1px;
	right: -1px;
	height: auto;
	background: #f9f9f9;
	border: 1px solid #ccc;
	border-top: 0;
	display: none;

	max-height: 300px;
	overflow: auto;
}

.ApNestedSelect .results.show { display: block; }

.ApNestedSelect .results .noResults {
	text-align: center;
	color: #999;
	padding: 0.5em;
}

.ApNestedSelect .results .item {
	padding: 0.5em;
	border-left: 5px solid #eee;
	position: relative;
}
.ApNestedSelect.loading .results .item { opacity: 0.5; pointer-events: none; }

.ApNestedSelect .results .item:hover { background: #fff; }
.ApNestedSelect .results .item.selected { border-left-color: #33b5e5; }
.ApNestedSelect .results .item.active { color: #0099CC; background: #e3f2fd; }





.ApNestedSelect.validator-error { border-bottom-color: #ff4444; }
.ApNestedSelect.validator-error label { color: #ff4444; }

.ApNestedSelect.validator-warning { border-bottom-color: #FF8800; }
.ApNestedSelect.validator-warning label { color: #FF8800; }

.ApNestedSelect.validator-loading { border-bottom-color: #DAA838; }
.ApNestedSelect.validator-loading label { color: #DAA838; }

.ApNestedSelect.validator-success { border-bottom-color: #4caf50; }
.ApNestedSelect.validator-success label { color: #4caf50; }

.ApNestedSelect .breadcrump td.input .tailInput input { background-color: white; }
.ApNestedSelect .breadcrump td.input .tailInput input:disabled { background: transparent; }

.ApNestedSelect .results .item .codeOption {
    position: relative;
    padding-top: 1em;
}

.ApNestedSelect .results .item .name {
    position: absolute;
    top: 0;
    left: 1em;
    color: #AAA;
    font-size: 0.8em;
}

.ApNestedSelect .results .item .code {
    padding-left: 1em;
}
