#storageWholesaleComponents {

}
#storageWholesaleComponents .saveBar {
    position: relative;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}

#storageWholesaleComponents .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }
#storageWholesaleComponents .saveBar .footerMenu {
    padding: 0.5em;
    margin-left: 0.2em;
}
#storageWholesaleComponents .saveBar .apDropdown.open .apDropdownMenu { z-index: 100; }

#storageWholesaleComponents .editModal .textInput {
    width: 100%;
    margin: 0;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    font-size: 14px;
    line-height: 30px;
    padding: 0 4px;

    text-overflow: ellipsis;
    overflow: hidden;
}

#storageWholesaleComponents .editModal .codeInput {
    height: 100%;
    cursor: pointer;

}
#storageWholesaleComponents .apModalWrapper .apModal.codeModal .apModalBody {
    overflow: visible;
}

#storageWholesaleComponents .checkboxLinked {
    height: 100%;
    text-align: center;
    position: relative;
}
