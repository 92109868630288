#publicTimeAttendance .pageBackButton {
    margin-bottom: 0.5em;
}

#publicTimeAttendance .fullScreen  {
    position:absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(5, 0, 105);
}
#publicTimeAttendance .in {
    float: right;
}

#publicTimeAttendance .Clock {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -200px;
}

#publicTimeAttendance .back .apButton {
    font-size: 40px;
    margin: 0px;
    
}
#publicTimeAttendance .back .apButton svg {
    width: 40px;
    height: 40px;
}
#publicTimeAttendance .clockTime {
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    padding-top: 10px;
}
#publicTimeAttendance h1 {
    
    text-align: center;
    
}
#publicTimeAttendance .inputBox {

    position: absolute;
    top:-1000px

}




