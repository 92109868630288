#projectContainer > .apBox {
    margin-top: 0;
}

#projectContainer .loaderContainer {
    text-align: center;
    padding-bottom: 1em;
}

#projectContainer .mainProjectPath .apDropdown {
    position: absolute;
    z-index: 99;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#projectContainer .mainProjectPath .withdropdown {
    position: relative;
    padding-right: 50px;
}

#projectContainer .selectSubPorject .name {
    color: #0099CC;
    font-size: 0.9em;
}
#projectContainer .selectSubPorject .code {
    font-size: 0.7em;
}

#projectContainer .fileButtonContainer {
    position: absolute;
    top: 32px;
    right: 20px;
}
#projectContainer .fileButtonContainer .fileButton { padding: 0.5em; }
#projectContainer .fileButtonContainer svg { width: 28px; height: 28px; }


