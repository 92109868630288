#login {
  width: 100vw;
  min-height: 100vh;
  background: #33b5e5;
  background: -moz-linear-gradient(-45deg, #33b5e5 0%, #0099cc 100%);
  background: -webkit-linear-gradient(-45deg, #33b5e5 0%, #0099cc 100%);
  background: linear-gradient(135deg, #33b5e5 0%, #0099cc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33b5e5', endColorstr='#0099cc',GradientType=1 );
  position: relative;
  padding: 100px 0 50px;
}

#login .bgLogo {
  position: absolute;
  z-index: 1;
  top: -60px;
  right: 55%;
  width: 300px;
  height: auto;
  pointer-events: none;
  opacity: 0.3;
}

#login .login-box {
  margin: 0 auto;
  width: 79vw;
  max-width: 350px;
  height: auto;
  background: #fff;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 2;
}

#login .login-box .languages {
  position: absolute;
  right: 100%;
  top: 50px;
}

#login .login-box .languages > button {
  width: 50px;
  height: 50px;
  background: #ddd;
  margin: 0 0 10px;
  padding: 0;
  border: 0;
  cursor: pointer;
  box-shadow: inset -10px 0 10px rgba(0, 0, 0, 0.3);
}

#login .login-box .languages > button:focus,
#login .login-box .languages > button:hover {
  outline: 0;
  background: #eee;
}

#login .login-box .languages > button.selected {
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
}

#login .login-box .languages > button .flag {
  display: block;
  width: 32px;
  height: 32px;
  margin: 9px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#login .login-box h3 {
  margin: 0 0 0.5em;
  padding: 0.5em 0;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

#login .login-box h3 svg {
  width: 24px;
  height: 24px;
}

#login .login-box .linkButton {
  display: block;
  text-align: center;
}

#login .footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 1em;
  text-align: center;
  color: #bbdefb;
  font-size: 14px;
  line-height: 15px;
}

#login .footer a {
  color: #bbdefb;
}

#login .devArea {
  position: absolute;
  z-index: 9999;
  top: 10px;
  transform: translate(50%,50%);
  right: 50%;
  width: 200px;
  opacity: 0.5;
  -webkit-transition: opacity 0.4s ease;
  -moz-transition: opacity 0.4s ease;
  -ms-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

#login .devArea:hover {
  opacity: 1;
}

#login .devArea svg {
  position: absolute;
  top: 5px;
  right: calc(100% + 5px);
  fill: #1c2331;
}

#login #navTop {
  position: absolute;
  background: unset;
  width: 99%;
}

@media all and (max-width: 699px) {
  #login {
    padding: 60px 0 50px;
  }
}

@media all and (max-width: 599px) {
  #login #navTop .block.languages .topModal {
    right: 6px;
  }
  #login #navTop .block.languages .topModal:after {
    right: 29px;
  }
}

@media all and (max-width: 499px) {
  #login .bgLogo {
    top: -4vw;
    width: 40vw;
  }

  #login .login-box {
    max-width: none;
  }

  #login .login-box .languages > button {
    width: 15vw;
    margin: 0 0 5px;
  }
  #login .login-box .languages > button .flag {
    width: 28px;
    height: 28px;
    margin: 6px auto;
  }

  #login .footer {
    font-size: 12px;
  }

  #login #navTop .block.languages .topModal:after {
    right: 13px;
  }
}
