.statisticsContainer tr:last-child {
  font-weight: bold;
}

.statisticsContainer th {
  padding: 0.5em 0.3em 0;
}
.statisticsContainer td {
  padding: 0.5em 0.3em;
}

.statisticsContainer tr:nth-child(2n) {
  background-color: hsl(194, 100%, 95%);
}
