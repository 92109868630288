
#pageNotificationSettings .iconHeader {
	text-align: center;
}

#pageNotificationSettings .iconHeader svg {
	width: 20px;
	height: 20px;
}

#pageNotificationSettings .nameCell,
#pageNotificationSettings .booleanCell,
#pageNotificationSettings .currencyCell,
#pageNotificationSettings .iconCell {
	font-size: 16px;
	line-height: 1.2em;
	padding: 0.5em;
}

#pageNotificationSettings .nameCell small {
	font-size: 14px;
	color: #999;
}

#pageNotificationSettings .booleanCell {
	text-align: left;
}
#pageNotificationSettings .booleanCell.onlyIcons { text-align: center; }
#pageNotificationSettings .booleanCell.true svg { fill: #4caf50; }
#pageNotificationSettings .booleanCell.false svg { fill: #ff4444; }

#pageNotificationSettings .currencyCell {
	text-align: right;
}

#pageNotificationSettings .currencyCell .unit {
	font-size: 14px;
	color: #aaa;
}
#pageNotificationSettings .iconCell {
	text-align: center;
}

#pageNotificationSettings .iconCell svg {
	width: 16px;
	height: 16px;
}

#pageNotificationSettings .saveBar {
	padding: 1em;
	background: #fff;
	border-top: 3px solid #fff;
}

#pageNotificationSettings .ApStickyBar.sticky .saveBar {
	border-top-color: #33b5e5;
}

.actions {
	float: right;
	margin: 3px 0 0 0;
}