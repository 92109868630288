#deliverySummary .editButton {
    border: 1px solid;
    border-radius: 0;
    border-color: #33b5e5;
    float: right;
}

#deliverySummary .summaryBox {
    background: #f3fbfe;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid #33b5e5;
}
 
#deliverySummary .type-icon {
    width: 16px;
    height: 16px;
}

#deliverySummary .related-icon {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
    margin-left: 10px;
    cursor: pointer;
}
