#pageTimetrackingEntry .WeekDay {
  margin: 0 0 0.5em;
}

#pageTimetrackingEntry .WeekDay .status {
  border-color: #eee;
  color: #999;
}
#pageTimetrackingEntry .WeekDay .status.submitted {
  border-color: #33b5e5;
  color: #33b5e5;
}
#pageTimetrackingEntry .WeekDay .status.rejected {
  border-color: #ff4444;
  color: #ff4444;
}
#pageTimetrackingEntry .WeekDay .status.approved,
#pageTimetrackingEntry .WeekDay .status.confirmed,
#pageTimetrackingEntry .WeekDay .status.payrolled,
#pageTimetrackingEntry .WeekDay .status.paid {
  border-color: #4caf50;
  color: #4caf50;
}

#pageTimetrackingEntry .WeekDay.loading {
  opacity: 0.5;
  pointer-events: none;
}

#pageTimetrackingEntry .WeekDay .header {
  margin: 0;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  border-left: 3px solid #ccc;
  -webkit-transition: border 0.4s ease;
  -moz-transition: border 0.4s ease;
  -ms-transition: border 0.4s ease;
  -o-transition: border 0.4s ease;
  transition: border 0.4s ease;

  line-height: 1.2em;
  position: relative;
}

#pageTimetrackingEntry .WeekDay .header:hover {
  border-left-color: #aaa;
}
#pageTimetrackingEntry .WeekDay.detailsOpen .header {
  border-left-color: #33b5e5;
}
#pageTimetrackingEntry .WeekDay.error .header {
  border-left-color: #ff4444;
}
#pageTimetrackingEntry .WeekDay.warning .header {
  border-left-color: #ff8800;
}

#pageTimetrackingEntry .WeekDay .header .indicator {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 20px;
  height: 20px;
  margin: -10px 0 0;

  -webkit-transition: transform 0.4s ease;
  -moz-transition: transform 0.4s ease;
  -ms-transition: transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
}

#pageTimetrackingEntry .WeekDay.detailsOpen .header .indicator {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

#pageTimetrackingEntry .WeekDay .header .clickarea {
  padding: 0.5em 0 0.5em 40px;
  margin-right: 100px;
  position: relative;
  cursor: pointer;
}

#pageTimetrackingEntry .WeekDay .header .clickarea .date .weekday {
  display: block;
}

#pageTimetrackingEntry .WeekDay.today .header .clickarea .date .weekday {
  font-weight: bold;
  color: #0099cc;
}

#pageTimetrackingEntry .WeekDay.error .header .clickarea .date {
  color: #cc0000;
  font-weight: bold;
}

/*#pageTimetrackingEntry .WeekDay .header .clickarea .stats,*/
#pageTimetrackingEntry .WeekDay .header .overview,
#pageTimetrackingEntry .WeekDay .header .apDropdown,
#pageTimetrackingEntry .WeekDay .header .status {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

#pageTimetrackingEntry .WeekDay.error .header .status svg {
  fill: #ff4444;
}
#pageTimetrackingEntry .WeekDay.warning .header .status svg {
  fill: #ff8800;
}
#pageTimetrackingEntry .WeekDay.success .header .status svg {
  fill: #4caf50;
}

#pageTimetrackingEntry .WeekDay .header .clickarea .overview {
  left: 200px;
  right: 0;
}

#pageTimetrackingEntry .WeekDay .header .clickarea .overview .hours {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 140px);
  height: 20px;
  margin-right: 10px;
  position: relative;
}

#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .apTooltipper {
  width: 100%;
  height: 100%;
}

#pageTimetrackingEntry .WeekDay .header .clickarea .overview .hours .bars {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #eee;
  -webkit-border-radius: 999px;
  border-radius: 999px;

  overflow: hidden;
  -webkit-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .bars.withFlexi {
  width: calc(100% - 55px);
  z-index: 1;
}

#pageTimetrackingEntry .WeekDay .header .clickarea .overview .hours .flexi {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 20px;
  overflow: hidden;
  background: #eee;
  -webkit-border-radius: 999px;
  border-radius: 999px;

  text-align: center;
  font-size: 12px;
  line-height: 20px;
}
#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .flexi.minus {
  background: #ffcdd2;
  color: #cc0000;
}
#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .flexi.plus {
  background: #c8e6c9;
  color: #007e33;
}

#pageTimetrackingEntry .WeekDay .header .clickarea .overview .hours .bar {
  float: left;
  width: 0;
  height: 100%;
  -webkit-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.3);

  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  font-family: "Roboto", Arial, sans-serif;
}

#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .bar.regular {
  background: #33b5e5;
}
#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .bar.overtime {
  background: #4285f4;
}
#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .bar.absence {
  background: #ff8800;
}
#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .bar.flexiUsed {
  background: #ff4444;
  color: transparent;
  background-image: linear-gradient(
    45deg,
    #ff4444 25%,
    #ef9a9a 25%,
    #ef9a9a 50%,
    #ff4444 50%,
    #ff4444 75%,
    #ef9a9a 75%,
    #ef9a9a 100%
  );
  background-size: 20px 20px;
}
#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  .hours
  .bar.flexiGain {
  background: transparent;
  color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(
    45deg,
    #00c851 25%,
    transparent 25%,
    transparent 50%,
    #00c851 50%,
    #00c851 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 20px 20px;
  opacity: 0.7;
}

#pageTimetrackingEntry
  .WeekDay
  .header
  .clickarea
  .overview
  > .apTooltipper
  > svg,
#pageTimetrackingEntry .WeekDay .header .clickarea .overview > svg {
  width: 20px;
  height: 20px;
  margin: 0 10px;

  -webkit-transition: opacity 0.4s ease;
  -moz-transition: opacity 0.4s ease;
  -ms-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

#pageTimetrackingEntry .WeekDay .header .clickarea .overview > svg.empty {
  opacity: 0.2;
}

#pageTimetrackingEntry .WeekDay .header .apDropdown {
  z-index: 8;
  right: 50px;
}
#pageTimetrackingEntry .WeekDay .header .apDropdown.open {
  z-index: 9;
}

#pageTimetrackingEntry .WeekDay .header .status {
  right: 10px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 999px;
  border-radius: 999px;
  border-width: 3px;
  border-style: solid;
  text-align: center;
}

#pageTimetrackingEntry .WeekDay .header .status svg {
  margin: 3px 0 0;
  width: 16px;
  height: 16px;
}

#pageTimetrackingEntry .WeekDay .details {
  background: #fff;
  margin: 0.2em 0 1em 1em;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

#pageTimetrackingEntry
  .WeekDay
  .details
  .addBar
  .apTooltip.position-topright:after {
  right: 25px;
}

#pageTimetrackingEntry .WeekDay .details .dailyEntries {
  margin: 0 0 1em;
}

#pageTimetrackingEntry .WeekDay .details .project {
  margin: 0 0 1em;
}

#pageTimetrackingEntry .WeekDay .details .project > .title {
  padding: 0.5em;
  padding-left: 45px;
  padding-right: 180px;
  line-height: 1.2em;
  position: relative;
  background: #f9f9f9;
  border-left: 3px solid #ccc;
}

/*
#pageTimetrackingEntry .WeekDay .details .project:hover > .title strong { color: #0099CC; }
#pageTimetrackingEntry .WeekDay .details .project:hover > .title > svg { fill: #0099CC; }
*/
#pageTimetrackingEntry .WeekDay .details .project.empty > .title {
  border-left-color: #ff8800;
  padding-right: 360px;
}

@media all and (max-width: 900px) {
  #pageTimetrackingEntry .WeekDay .details .project.empty > .title {
    padding-right: 180px;
  }
}

#pageTimetrackingEntry .WeekDay .details .project > .title > svg,
#pageTimetrackingEntry .WeekDay .details .project > .title .apButton.addHours,
#pageTimetrackingEntry .WeekDay .details .project > .title .apDropdown,
#pageTimetrackingEntry .WeekDay .details .project > .title .apButton.remove,
#pageTimetrackingEntry .WeekDay .details .entry .status,
#pageTimetrackingEntry .WeekDay .details .entry .apDropdown,
#pageTimetrackingEntry .WeekDay .details .entry .description svg.icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

#pageTimetrackingEntry .WeekDay .details .project > .title > svg {
  width: 24px;
  height: 24px;
  left: 10px;
}
#pageTimetrackingEntry .WeekDay .details .project > .title .apButton.addHours {
  right: 170px;
}

#pageTimetrackingEntry .WeekDay .details .project > .title .apButton.remove {
  right: 10px;
  margin-left: 5px;
}
#pageTimetrackingEntry .WeekDay .details .project > .title .apDropdown {
  right: 90px;
  margin: 0;
  z-index: 7;
}
#pageTimetrackingEntry .WeekDay .details .project > .title .apDropdown.open {
  z-index: 8;
}
#pageTimetrackingEntry
  .WeekDay
  .details
  .project
  > .title
  .apDropdown
  .apButton {
  margin: 0;
}
#pageTimetrackingEntry
  .WeekDay
  .details
  .project
  > .title
  .apDropdown
  .apDropdownMenu:after {
  right: 26px;
}

#pageTimetrackingEntry .WeekDay .details .project .entries {
  margin-left: 1em;
}

#pageTimetrackingEntry .WeekDay .details .entry {
  border: 1px solid #eee;
  border-top: 0;
  border-left: 3px solid #eee;
  padding: 0.5em 0;
  padding-right: 110px; /* little space for dropdown actions and status ball */
  line-height: 1.2em;
  font-size: 15px;
  position: relative;
}
#pageTimetrackingEntry .WeekDay .details .entry:first-child {
  border-top: 1px solid #eee;
}
#pageTimetrackingEntry .WeekDay .details .entry:hover {
  border-left-color: #ccc;
}

#pageTimetrackingEntry .WeekDay .details .entry:after {
  content: "";
  clear: both;
  display: block;
}

#pageTimetrackingEntry .WeekDay .details .entry.submitted {
  border-left-color: #33b5e5;
}
#pageTimetrackingEntry .WeekDay .details .entry.rejected {
  border-left-color: #ff4444;
  background: #ffebee;
  color: #cc0000;
}
#pageTimetrackingEntry .WeekDay .details .entry.approved {
  border-left-color: #4caf50;
}

#pageTimetrackingEntry .WeekDay .details .entry.misentry .description,
#pageTimetrackingEntry .WeekDay .details .entry.misentry .amount,
#pageTimetrackingEntry .WeekDay .details .entry.misentry .amount .name {
  text-decoration: line-through;
}

#pageTimetrackingEntry .WeekDay .details .entry .description {
  float: left;
  max-width: calc(100% - 250px);
  min-width: 180px;
  padding-left: 40px;
  position: relative;
  cursor: pointer;
}

#pageTimetrackingEntry .WeekDay .details .entry .description:hover {
  color: #0099cc;
}

#pageTimetrackingEntry .WeekDay .details .entry .description svg.icon {
  left: 12px;
  width: 16px;
  height: 16px;
}
#pageTimetrackingEntry .WeekDay .details .entry .description .longDescIcon {
  width: 16px;
  height: 16px;
  margin: -3px 3px 0;
  fill: #aaa;
}

#pageTimetrackingEntry .WeekDay .details .entry .amount {
  float: right;
  width: 150px;
}

#pageTimetrackingEntry .WeekDay .details .entry .amount .name {
  display: inline-block;
  width: 60%;
  margin-right: 5px;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 20px;
}

#pageTimetrackingEntry .WeekDay .details .entry .amount .value {
  display: inline-block;
  width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 20px;
}

#pageTimetrackingEntry .WeekDay .details .entry .apDropdown {
  right: 50px;
  z-index: 2;
}
#pageTimetrackingEntry .WeekDay .details .entry .apDropdown.open {
  z-index: 100;
}

#pageTimetrackingEntry .WeekDay .details .entry .status {
  z-index: 4;
  right: 10px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-width: 3px;
  border-style: solid;
  -webkit-border-radius: 999px;
  border-radius: 999px;

  text-align: center;
}

#pageTimetrackingEntry .WeekDay .details .entry .status .apTooltipper {
  width: 100%;
  height: 100%;
}

#pageTimetrackingEntry .WeekDay .details .entry .status svg {
  width: 12px;
  height: 12px;
  margin-top: -2px;
}

#pageTimetrackingEntry .WeekDay .details .apAddon {
  text-align: center;
}
#pageTimetrackingEntry .WeekDay .details .apAddon svg {
  width: 20px;
  height: 20px;
}

#pageTimetrackingEntry .WeekDay .details .apAddon.selected {
  color: #00695c;
  border-bottom-color: #2bbbad;
}

#pageTimetrackingEntry .WeekDay .details .allowance .apInputBlock,
#pageTimetrackingEntry .WeekDay .details .allowance .apInputBlock .apSelect {
  margin: 0;
}

.entries .description .title {
  word-break: break-word;
}
.entries .description .desc {
  word-break: break-word;
}

@media all and (max-width: 800px) {
  #pageTimetrackingEntry
    .WeekDay.today
    .header
    .clickarea
    .date
    .weekday:after {
    display: none;
  }
  #pageTimetrackingEntry .WeekDay .header .clickarea .overview {
    left: 150px;
  }

  #pageTimetrackingEntry .WeekDay .header .clickarea .overview .hours {
    width: calc(100% - 1em);
  }

  #pageTimetrackingEntry .WeekDay .details .project > .title {
    padding-right: 0;
  }
  #pageTimetrackingEntry .WeekDay .details .project.empty > .title {
    padding-right: 0;
  }

  #pageTimetrackingEntry .WeekDay .details .project.empty > .title {
    padding-right: 0;
  }

  #pageTimetrackingEntry .WeekDay .details .project > .title .apButton.addHours,
  #pageTimetrackingEntry .WeekDay .details .project > .title .apDropdown,
  #pageTimetrackingEntry .WeekDay .details .project > .title .apButton.remove {
    position: relative;
  }

  #pageTimetrackingEntry .WeekDay .details .project > .title .apDropdown {
    left: 0;
  }

  #pageTimetrackingEntry .WeekDay .details .project > .title .apButton.remove {
    margin-left: 5px;
    margin-bottom: 0;
    left: 0;
  }

  #pageTimetrackingEntry
    .WeekDay
    .details
    .project
    > .title
    .apButton.addHours {
    margin-right: 5px;
    margin-bottom: 0;
    right: 0;
  }

  #pageTimetrackingEntry .WeekDay .details .project > .title {
    padding-left: 5px;
  }
  #pageTimetrackingEntry .WeekDay .details .project > .title .apDropdownMenu {
    left: 0;
  }

  #pageTimetrackingEntry .WeekDay .details .project .addEntryButtons {
    width: 100%;
    height: 50px;
  }

  #pageTimetrackingEntry
    .WeekDay
    .details
    .project
    > .title
    .apDropdown
    .apDropdownMenu:after {
    left: 35px;
  }
}

@media all and (max-width: 600px) {
  #pageTimetrackingEntry .WeekDay .details .entry .amount {
    float: none;
  }
}

@media all and (max-width: 500px) {
  #pageTimetrackingEntry .WeekDay .details .project > .title .apDropdownMenu {
    left: 0;
  }

  #pageTimetrackingEntry
    .WeekDay
    .details
    .project
    > .title
    .apDropdown
    .apDropdownMenu:after {
    left: 21px;
  }
}

@media all and (max-width: 400px) {
  #pageTimetrackingEntry .WeekDay .details .entry .description {
    padding-left: 3px;
    min-width: 90px;
  }
  #pageTimetrackingEntry .WeekDay .details .entry .amount .name {
    width: 60px;
  }
}
