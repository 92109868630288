
#pageTimetrackingConfirm .weekBrowser {
	margin: 1em;
}

#pageTimetrackingConfirm .weekBrowser .weeks {
	border: 1px solid #ccc;
	padding: 1em;
}

#pageTimetrackingConfirm .weekBrowser .weeks .week {
	display: inline-block;
	margin: 0 1em 0.5em 0;
	border-bottom: 3px solid #ccc;
}




#pageTimetrackingConfirm .entries {
	position: relative;
	min-height: 100px;
	margin-left: 5px;
	overflow: auto;
}

#pageTimetrackingConfirm .entries.loading {
	opacity: 0.5;
	pointer-events: none;
}


#pageTimetrackingConfirm .entries .week {
	margin: 0 0 2em;
	position: relative;
}

#pageTimetrackingConfirm .entries .week .weekName {
	font-size: 28px;
	color: #aaa;
	line-height: 50px;
	position: relative;
}

#pageTimetrackingConfirm .entries .week .weekName .selectWeek {
	font-size: 14px;
	display: inline-block;
}
#pageTimetrackingConfirm .entries .week .weekName .selectWeek input {
	margin: 0 0.5em;
}
#pageTimetrackingConfirm .entries .week .weekName .selectDay {
	font-size: 14px;
	display: inline-block;
}

#pageTimetrackingConfirm .entries .week .weekName .selectDay input {
	margin: 0 0.5em;
}

#pageTimetrackingConfirm .entries .week .day {
	border-top: 1px solid #ccc;
	padding: 5px 0 0 100px;
	position: relative;
}

#pageTimetrackingConfirm .entries.hideReadOnly .week .day.empty { border-top: 3px dotted #ddd; }

#pageTimetrackingConfirm .entries .week .day .dayName,
#pageTimetrackingConfirm .entries .week .day .dayName .dailySum{
	position: absolute;
	top: 5px;
	left: 5px;
	width: 90px;
	text-align: right;
	line-height: 1.2em;
	bottom: 0;
	overflow: hidden;
}

#pageTimetrackingConfirm .entries .week .day .dayName {
	z-index: 1;
}

#pageTimetrackingConfirm .entries .week .day .salaryExtra {
	display: inline-block;
	border: 1px solid #ccc;
	border-bottom: 2px solid #ccc;
	background: #eee;
	padding: 0.5em;
	margin: 0 0.5em 0.5em 0;
	font-size: 14px;
	line-height: 1em;
}

#pageTimetrackingConfirm .entries .week .day .salaryExtra.noRule { 
	background: #e8f5e9; 
	border-color: #a5d6a7; 
	color: #007E33;
}

#pageTimetrackingConfirm .entries .week .day .entry {
	background: #fff;
	margin: 0 0 0.5em;
	padding: 0.5em 0;
	border-left: 3px solid #ccc;
	position: relative;
}

#pageTimetrackingConfirm .entries .week .day .entry.readOnly { background: #eee; }
#pageTimetrackingConfirm .entries.hideReadOnly .week .day .entry.readOnly { display: none; }
#pageTimetrackingConfirm .entries.hideReadOnly .week .day .readOnlyPlaceholder { 
	height: 10px; 
	border-top: 3px dotted #ddd;
}
#pageTimetrackingConfirm .entries.hideReadOnly .week .day.empty:hover,
#pageTimetrackingConfirm .entries.hideReadOnly .week .day .readOnlyPlaceholder:hover { border-color: #ccc; }

#pageTimetrackingConfirm .entries .week .day .entry.confirmed { border-left-color: #4caf50; }
#pageTimetrackingConfirm .entries .week .day .entry.rejected { border-left-color: #ff4444; }
#pageTimetrackingConfirm .entries .week .day .entry.selected { border-left-color: #33b5e5; }

#pageTimetrackingConfirm .entries .week .day .entry.confirmed svg.icon { fill: #007E33; }
#pageTimetrackingConfirm .entries .week .day .entry.rejected svg.icon { fill: #CC0000; }
#pageTimetrackingConfirm .entries .week .day .entry.selected svg.icon { fill: #0099CC; }

#pageTimetrackingConfirm .entries .week .day .entry.confirmed .description { color: #007E33; }
#pageTimetrackingConfirm .entries .week .day .entry.rejected .description { color: #CC0000; }
#pageTimetrackingConfirm .entries .week .day .entry.selected .description { color: #0099CC; }

#pageTimetrackingConfirm .entries .week .day .entry.readOnly svg.icon { fill: #999; }
#pageTimetrackingConfirm .entries .week .day .entry.readOnly .description { color: #999; }

#pageTimetrackingConfirm .entries .week .day .entry svg.icon {
	position: absolute;
	top: 50%;
	left: 28px;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	pointer-events: none;
	opacity: 1;
}

#pageTimetrackingConfirm .entries .week .day .entry input {
	position: absolute;
	top: 50%;
	left: 7px;
	margin: 0;
	pointer-events: none;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageTimetrackingConfirm .entries .week .day .entry .description {
	padding: 0.2em 0;
	padding-left: 50px;
	padding-right: 720px;
	font-size: 14px;
	line-height: 1.2em;
}

#pageTimetrackingConfirm .entries .week .day .entry.hasProject .description { padding-right: 870px; }

#pageTimetrackingConfirm .entries .week .day .entry .project {
	position: absolute;
	top: 50%;
	right: 710px;
	width: 150px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageTimetrackingConfirm .entries .week .day .entry .project svg {
	position: absolute;
	top: 50%;
	left: 4px;
	width: 16px;
	height: 16px;
	margin-top: -8px;
}
#pageTimetrackingConfirm .entries .week .day .entry .project span.code {
	display: block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px 0 24px;
	font-size: 13px;
}

#pageTimetrackingConfirm .entries .week .day .entry .project .apTooltip { text-align: left; }


#pageTimetrackingConfirm .entries .week .day .entry .status {
	position: absolute;
	top: 50%;
	right: 40px;
	width: 100px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	background: #eee;
	color: #666;
	font-size: 12px;
	line-height: 30px;
	text-align: center;

	cursor: pointer;

    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;       
}

#pageTimetrackingConfirm .entries .week .day .entry .status:hover { border: 1px solid #ccc; }

#pageTimetrackingConfirm .entries .week .day .entry .status.confirmed {
	background: #c8e6c9;
	color: #007E33;
}
#pageTimetrackingConfirm .entries .week .day .entry .status.rejected {
	background: #ffcdd2;
	color: #CC0000;
}

#pageTimetrackingConfirm .entries .week .day .entry .status svg {
	width: 12px;
	height: 12px;
	margin: -2px 5px 0 0;
}

#pageTimetrackingConfirm .entries .week .day .entry.readOnly .status { 
	background: transparent; 
	border: 1px dashed #ccc; 
	cursor: not-allowed;
	color: #999;
}
#pageTimetrackingConfirm .entries .week .day .entry.readOnly .status svg { display: none; } 


#pageTimetrackingConfirm .entries .week .day .entry .comment {
	position: absolute;
	top: 50%;
	right: 0;
	padding: 10px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageTimetrackingConfirm .entries .week .day .entry .comment svg {
	width: 20px;
	height: 20px;
	opacity: 0;
}
#pageTimetrackingConfirm .entries .week .day .entry.rejected .comment { cursor: pointer; }
#pageTimetrackingConfirm .entries .week .day .entry.rejected .comment svg { opacity: 1; }
#pageTimetrackingConfirm .entries .week .day .entry.rejected.comment-missing .comment svg,
#pageTimetrackingConfirm .entries .week .day .entry.readOnly .comment svg  { opacity: 0.5; }

#pageTimetrackingConfirm .entries .overview {
	position: absolute;
	top: 50%;
	right: 150px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	font-size: 13px;
	line-height: 1em;
	color: #666;
}

#pageTimetrackingConfirm .entries .overview.dailySum {
	background: rgb(226, 248, 252);
	position: relative;
	justify-content: space-between;
	top: 20px;
	display: flex;
	padding-left: 160px;
	align-items: center;

}


#pageTimetrackingConfirm .entries .overview.daily { background: #fff; }
#pageTimetrackingConfirm .entries .week .day .entry.readOnly .overview.daily { background: #eee; }

#pageTimetrackingConfirm .entries .overview .block {
	display: inline-block;
	margin: 0;
	width: 80px;
	text-align: center;
	padding: 5px;
}



#pageTimetrackingConfirm .entries .overview.daily .block { border-left: 1px solid #eee; }
#pageTimetrackingConfirm .entries .overview.daily .block.storage { border-right: 1px solid #eee; }

#pageTimetrackingConfirm .entries .overview .block.empty { color: #ccc; }

#pageTimetrackingConfirm .entries .overview.weekly .block span.text { font-weight: bold; }
#pageTimetrackingConfirm .entries .overview .block span.text {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

#pageTimetrackingConfirm .entries .overview .block .apTooltipper .apTooltip { text-align: left; }

#pageTimetrackingConfirm .entries .week .day .entry.misentry,
#pageTimetrackingConfirm .entries .week .day .entry.misentry .project span.code,
#pageTimetrackingConfirm .entries .week .day .entry.misentry .overview .block span.text { text-decoration: line-through; }

#pageTimetrackingConfirm .entries .overview .block svg {
	width: 20px;
	height: 20px;
	margin: 0 0 5px;
}

#pageTimetrackingConfirm .customFooter {
	padding: 1em;
	/* margin: 1em 0 0; */
	border-top: 2px solid #eee;
	background: #fff;
	position: relative;
	text-align: right;
}

#pageTimetrackingConfirm .ApStickyBar.sticky .customFooter { border-top-color: #33b5e5; }

#pageTimetrackingConfirm .customFooter .action .totals {
	display: inline-block;
	margin-right: 0.5em;
}

#pageTimetrackingConfirm .customFooter .action .totals .total {
	display: inline-block;
	background: #eee;
	border: 1px solid #ccc;
	border-radius: 10px;
	margin: 0 0.5em 0 0;
	padding: 0;
	width: 60px;
	padding: 0 0.2em;
	font-size: 14px;
	text-align: left;
}

#pageTimetrackingConfirm .customFooter .action .totals .total.confirm { background: #c8e6c9; color: #007E33; border-color: #4caf50; }
#pageTimetrackingConfirm .customFooter .action .totals .total.reject { background: #ffcdd2; color: #CC0000; border-color: #ff4444; }
#pageTimetrackingConfirm .customFooter .action .totals .total svg {
	width: 16px;
	height: 16px;
	margin: -3px 3px 0 3px;
}

#pageTimetrackingConfirm .customFooter .action .apButton { margin: 0; }

#pageTimetrackingConfirm .customFooter .tools {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	text-align: left;
}

#pageTimetrackingConfirm .customFooter .tools .selection,
#pageTimetrackingConfirm .customFooter .tools .filters {
	display: inline-block;
}

#pageTimetrackingConfirm .customFooter .tools .apDropdownMenu.openTop:after { left: 20px; }

#pageTimetrackingConfirm .customFooter .tools .filters .apDropdown .apDropdownMenu {
	width: 80vw;
	max-width: 400px;
	border-bottom-color: #444;
}
#pageTimetrackingConfirm .customFooter .tools .filters .apDropdown .apDropdownMenu.openTop:after { border-top-color: #444; }

#pageTimetrackingConfirm .customFooter .tools .filters .filterValue {
	background: #eee;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    padding: 0.2em 0.5em;
    position: relative;
}

#pageTimetrackingConfirm .customFooter .tools .filters .filterOption {
	position: relative;
}

#pageTimetrackingConfirm .customFooter .tools .filters .filterOption .count {
	position: absolute;
	top: 50%;
	right: 0.5em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	font-size: 14px;
	padding: 3px 5px;
    background: #999;
    color: #fff;
    -webkit-border-radius: 999px;
            border-radius: 999px;
}

#pageTimetrackingConfirm .customFooter .tools .filters .apOptionBar .option {
	font-size: 14px;
	position: relative;
}
#pageTimetrackingConfirm .customFooter .tools .filters .apOptionBar .option svg {
	display: block;
	margin: 0.5em auto 0;
}

#pageTimetrackingConfirm .customFooter .tools .filters .apOptionBar .option .apBadge {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
}

#pageTimetrackingConfirm .customFooter .tools .divider {
	display: inline-block;
	width: 1px;
	height: 30px;
	background: #ccc;
	margin: 0 1em;
	vertical-align: middle;
}

#pageTimetrackingConfirm .customFooter .tools .selection .selectAll {
	display: inline-block;
	position: relative;
	padding: 0.5em 1em;
	cursor: pointer;
}

#pageTimetrackingConfirm .customFooter .tools .selection .selectAll input {
	margin: 0;
}

#pageTimetrackingConfirm .customFooter .tools .selection .selectAll .apBadge {
	position: absolute;
	top: 0;
	left: 50%;
}

@media all and (max-width: 550px) {
	#pageTimetrackingConfirm .customFooter .action .totals {
		display: block;
		margin-bottom: 2px;
	}
	#pageTimetrackingConfirm .customFooter .tools .selection .selectAll {
		padding: 0.8em;
	}
}

@media all and (max-width: 500px) {
	.action .apButton {
		margin-right: 20px !important;
	}
}