#pageDeliveryEdit {
    margin-bottom: 16em;
}

#pageDeliveryEdit .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#pageDeliveryEdit .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }

#pageDeliveryEdit .saveBar .left {
    display: inline-block;
    width: 50%;
    text-align: left;
}
#pageDeliveryEdit .saveBar .right {
    display: inline-block;
    width: 50%;
    text-align: right;
}

#pageDeliveryEdit .fileContainer {
    float: right;
    margin-right: 30px;
}

#pageDeliveryEdit .fileContainerText {
    padding-right: 5px;
}

#pageDeliveryEdit .apForm .apFormColumn.fullColumn {
    overflow: visible;
}
