
#AddNotificationRecipientFooter {
	padding: 1em;
	text-align: right;
}

#AddNotificationRecipientFooter .apButton {
	margin: 0;
}

#AddNotificationRecipientFooter .apButton.cancel {
	float: left;
}

#AddNotificationRecipientModal .apModalWrapper .apModal .apModalBody {
	overflow: visible;
}

.recipient-form {
  margin-top: 30px;
}