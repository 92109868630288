
#codeSettings .treeContainer {
    background: #eee;
    padding: 1em 0.5em 0.5em;
    -webkit-box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
}

#codeSettings .splitView:after {
    content: '';
    display: block;
    clear: both;
}

#codeSettings .splitView .left,
#codeSettings .splitView .right {
    float: left;
    width: 300px;
}

#codeSettings .splitView .right {
    width: calc( 100% - 300px );
}

#codeSettings .splitView .details {
    margin-left: 1em;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

#codeSettings .detailPicker .apInputBlock .apSelect {
    background: #fff;
}

#codeSettings .detailPicker .apSimpleButton {
    width: 50px;
    height: 42px;
}

#codeSettings .detailPicker table.apInputStack .apAddon.noRightBorder {
    padding-left: 0;
    padding-right: 0;
    font-family: 'Roboto-Light', Arial, sans-serif;
}

#codeSettings .details .codePath {
    margin: 0;
}

#codeSettings .details .codePath .inner.current {
    position: relative;
    padding-right: 50px;
}

#codeSettings .details .codePath .inner.current .apDropdown {
    position: absolute;
    z-index: 99;
    top: 50%;
    right: 0;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

#codeSettings .componentTable {
    width: 100%;
    font-size: 14px;
    line-height: 1.5em;
}

#codeSettings .componentTable tr {
    border-bottom: 1px solid #ccc;
}

#codeSettings .componentTable th {
    font-size: 14px;
    vertical-align: bottom;
}

#codeSettings .componentTable td {
    padding: 0.5em 0.2em;
}

#codeSettings .componentTable td.name { width: auto; }
#codeSettings .componentTable td.fixed { width: 110px; min-width: 110px; max-width: 110px; }

#codeSettings .componentTable th.fixed,
#codeSettings .componentTable td.fixed {
    text-align: center; 
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}


#codeSettings .componentTable td.fixed input.componentRowInput {
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0 30px 0 0;

    border: 1px solid #ccc;
    border-bottom-width: 3px;

    font-size: 14px;
    line-height: 30px;
} 

#codeSettings .componentTable td.fixed input.componentRowInput.error {
    color: #ff4444;
    border-bottom-color: #ff4444;
}

#codeSettings .componentTable td.fixed input.componentRowInput:focus {
    outline: 0;
    border-color: #33b5e5;
}

#codeSettings .componentTable td.fixed .unit {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 25px;
    line-height: 30px;
    margin-top: -15px;
    text-align: left;
    font-size: 12px;
    color: #aaa;

    overflow: hidden;
    text-overflow: ellipsis;
}

#codeSettings .componentTable td svg {
    width: 20px;
    height: 20px;
}

#codeSettings .componentBadgeContainer {
    position: relative;
}

#codeSettings .componentBadgeContainer .apBadge {
    position: absolute;
    top: 0em;
    right: 0.2em;
}

