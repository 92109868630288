#storageSettings .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#storageSettings .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }

#storageSettings .saveBar .left {
    float: left;
    display: inline-block;
    text-align: left;
}
#storageSettings .saveBar .right {
    display: inline-block;
    text-align: right;
}

#storageSettings .withoutCodeContainer {
    margin-top:2em;
    background: #eee;
    padding: 0.5em 0.5em;
    -webkit-box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    box-shadow: inset 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
    margin-bottom: 2em;
    text-align: left;
    font-size: 1.2em;
    cursor: pointer;
}

#storageSettings .withoutCodeContainer .headerSvg {
    margin-right: 0.5em;
    width: 20px;
    height: 20px;
}

