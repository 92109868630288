
.componentEdit .apModalWrapper .apModal .apModalBody {
    max-height: calc( 90vh - 200px );
    min-height: calc( 90vh - 200px );
    height: calc( 90vh - 200px );
    overflow: auto;
}

.componentEdit .header {
    position: relative;
}
.componentEdit .header svg {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 10px;
}

.componentEdit .header h4 {
    margin-left: 50px;
    padding: 10px;
}

.componentEdit .mainStatus .apStatusBox {
    font-size: 1.2em;
    /* font-weight: 700; */
    padding: 0.2em;
}

.componentEdit .formHeader svg {
    margin-right: 0.2em;
    color: #AAA;
}

.componentEdit table.apInputStack .apAddon.conversionToggle
{
    height: 46px;
    margin-bottom: 10px;
}

.componentEdit .listStack {
    min-width: 600px;
}

.componentEdit .listTable .apSimpleButton,
.componentEdit .listStack .workIcon,
.componentEdit .listStack .apSimpleButton {
    width: 50px;
    height: 42px;
    text-align: center;
}
.componentEdit .listStack table.apInputStack .apAddon {
    padding: 0;
}

.componentEdit .listStack .unitInputContainer {
    position: relative;
}
.componentEdit .listStack .unitInputContainer input {
    padding-right: 40px;
    text-align: right;
}

#componentEdit .extraCalculatable input {
    padding-right: 80px;
}

#componentEdit .extraCalculatable .unit {
    max-width: 70px;
}

.componentEdit .listTable .deliveryTimeContainer .unit{
    position: absolute;
    right: 20px;
    bottom: 2px;
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.componentEdit .listTable .priceContainer .unit,
.componentEdit .listTable td.balanceCol .unit,
.componentEdit .listTable .optionalInputContainer .unit,
.componentEdit .listStack .unit {
    position: absolute;
    right: 10px;
    bottom: 2px;
    max-width: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.componentEdit .addButton {
    display: inline-block;
}

.componentEdit .listStack .apInputBlock input {
    text-overflow: ellipsis;
}

.componentEdit .listStack table.apInputStack .apAddon.titleAddon {
    white-space: nowrap;
    max-width: 200px;
}

.componentEdit .listStack table.apInputStack .isBooleanContainer {
    text-align: right;
    margin-bottom: 10px;
    height: 46px;
}

.componentEdit .listStack table.apInputStack .apAddon .title {
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
}

.componentEdit .apDropdown .action .dropDownUnit svg  {
    left: auto;
    right: 0;
}

.componentEdit .dropDownUnit {
    color: #AAA;
    font-size: 0.8em;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);

}



.componentEdit .listTable {
    width: 100%;
}

.componentEdit .listTable tr.row > td {
    padding: 0 0.5em;
}

.componentEdit .listTable td.setPrimaryCol {
    width: 10%;
}

.componentEdit .listTable td.setPrimaryCol button {
    height: 46px;
    white-space: nowrap;
}

#componentEdit .componentEdit .listTable td.selectSupplierInputCol {
    width: 25%;
}
#componentEdit .componentEdit .listTable td.LVISSupplierInputCol {
    width: 77%;
}

.componentEdit .listTable td input.primary-supplier {
    background-color: #dcedc8;
}

.componentEdit .listTable td.selectLocationInputCol {
    width: 30%;
}
#componentEdit .componentEdit .listTable td.priceCol {
    min-width: 50px;
}
#componentEdit .componentEdit .listTable td.LVISPriceCol {
    min-width: 50px;
}
.componentEdit .listTable td.visibleToSupplier {
    width: 15%;
}

.componentEdit .listTable td.removeCol {
    width: 50px;
}


.componentEdit .listTable td.removeCol button {
    height: 46px;
    padding: 0.5em;
}

/* .componentEdit .listTable .priceCol { */
    /* width: 20%; */
/* } */

.componentEdit .listTable .priceContainer,
.componentEdit .listTable .balanceContainer,
.componentEdit .listTable .optionalInputContainer {
    position: relative;
}

.componentEdit .listTable .priceContainer input,
.componentEdit .listTable .balanceContainer input,
.componentEdit .listTable .optionalInputContainer input {
    padding-right: 40px;
}

.componentEdit .listTable .disabledInputContainer input {
    cursor: pointer;
}

.componentEdit .listTable table.apInputStack .apAddon {
    padding: 0;

}

.componentEdit .apModalWrapper .apModal .apModalFooter {
    overflow: visible;
}

.componentEdit .footer {
    text-align: right;
}

.componentEdit .footer button {
    margin-left: 2px;
}

.componentEdit .footer .footerMenu {
    padding: 0.5em;
}

.componentEditConfirmSave .apStatusBox {
    display: inline;
    padding: 6px 1em;
}
.componentEdit .files .apInputStack {
    margin-bottom: 10px;
}

.componentEdit .billingSwitch {
    margin-bottom: 10px;
}


.componentEdit .headerCode {
    display: inline-block;
    font-size: 0.8em;
    margin-left: 0.5em;

}

.componentEdit .apImageUpload .apDropzone .preview {
    height: 200px;
}

#componentEdit .removeBtn:hover {
    color: #ff4444;
}

#componentEdit h4 .headerCode {
    display: inline;
}