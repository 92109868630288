.receiverEdit .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}

.receiverEdit  .receiver-indent {
    margin-left: 20px;
}

.receiverEdit .col-container {
    width: 100%;
}

.receiverEdit .col-left {
    width: 48%;
    display: inline-block;
    margin-right: 2%;
}
.receiverEdit .col-right {
    width: 48%;
    display: inline-block;
    margin-left: 2%;
}
.receiverEdit .footer {
    text-align: right;
}
.receiverEdit  .footer .apButton {
    margin: 0 0 0 1em;
}
