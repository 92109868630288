#notificationButton {
    position: relative;
}

#notificationButton .count {
    position: absolute;
    bottom: 6px;
    right: 6px;
    font-weight: 700;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 12px;
    line-height: 1em;
    /* padding: 4px 5px; */
    background: #FF8800;
    color: #fff;
    border-radius: 50%;
}
