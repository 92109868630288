
.apTree {
    margin: 0;
}


.apTree ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.apTree ul.subnode { padding-left: 1em; border-left: 1px dashed #ccc; }

.apTree .node {
    border-left: 3px solid #ccc;
    border-right: 3px solid #fff;
    background: #fff;
    padding: 0.5em 1em;
    margin: 3px 0;

    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.2em;
    font-size: 14px;
    position: relative;

    cursor: pointer;

    width: calc( 100% - 10px );

}

.apTree .node.childIndicatorPadding {
    padding-left: 32px;
}

.apTree .node:hover {
    border-right-color: #ccc;
}

.apTree .node.selected {
    border-right-color: #33b5e5;
    color: #0099CC;
}
.apTree .node.selected:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 181, 229, 0);
    border-left-color: #33b5e5;
    border-width: 10px;
    margin-top: -10px;
}


.apTree .node .childIndicator {
	position: absolute;

    height: 100%;
    width: 32px;
    left: 0;
    top: 0;
}

.apTree .node .childIndicator svg {
	position: absolute;
	top: 50%;
	left: 7px;
	width: 16px;
	height: 16px;
	cursor: pointer;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;


}

.apTree .node.open .childIndicator svg {
	-webkit-transform: translateY(-50%) rotate(90deg);
	   -moz-transform: translateY(-50%) rotate(90deg);
	    -ms-transform: translateY(-50%) rotate(90deg);
	     -o-transform: translateY(-50%) rotate(90deg);
	        transform: translateY(-50%) rotate(90deg);
}


.apTree .node small { 
    color: #999; 
    font-size: 12px;
    font-family: 'Roboto-Light', Arial, sans-serif;
}

.apTree .node .value {
    position: absolute;
    top: 50%;
    right: 0;

    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    border-left: 1px solid #fff;
    opacity: 0.5;

    padding: 0.5em ;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);

    -webkit-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
       -moz-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
        -ms-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
         -o-transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
            transition: opacity 0.4s ease, background 0.4s ease, border 0.4s ease;
} 

.apTree .node:hover .value {
    opacity: 1;
    background: #fff;
    border-left-color: #ccc;
}

.apTree .node.invalid {
    border-left: 3px solid #ff4444;
}
