
#UserEditFooter {
	text-align: right;
}

#UserEditFooter .validateMsg {
	width: 70%;
	float: left;
	text-align: left;
	line-height: 1.2em;
	padding-left: 35px;
	position: relative;
}

#UserEditFooter .validateMsg svg {
	position: absolute;
	top: -3px;
	left: 0;
}

#UserEditFooter .apButton {
	margin: 0 0 0 1em;
}
