
#pageComponents .apBoxCorner .apButton {
    margin-left: 6px;
}

#pageComponents .searchOption {
	position: relative;
	padding-left: 35px;

	line-height: 1.2em;
}

#pageComponents .searchOption svg.typeIcon {
	position: absolute;
	top: 50%;
	left: 0;
	width: 24px;
	height: 24px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageComponents .searchOption .components {
	position: absolute;
	top: 50%;
	right: 1em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageComponents .searchOption .components svg { fill: #aaa; }

svg.temporary {
	color: #b3b3b3;
  }

