#pageOrderEdit {
    margin-bottom: 16em;
}
#pageOrderEdit .save-box-button {
    margin: 2px;
}

#pageOrderEdit  .receiver-indent {
    margin-left: 20px;
}

#pageOrderEdit  .company-indent {
    margin-left: 20px;
}

#pageOrderEdit .col-container {
    width: 100%;
}

#pageOrderEdit .col-left {
    width: 48%;
    display: inline-block;
    margin-right: 2%;
}
#pageOrderEdit .col-right {
    width: 48%;
    display: inline-block;
    margin-left: 2%;
}

#pageOrderEdit .saveBar {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff; 
    padding: 0.5em;
    border-top: 3px solid #fff;

    text-align: right;

    -webkit-transition: border-top 0.4s ease;
       -moz-transition: border-top 0.4s ease;
        -ms-transition: border-top 0.4s ease;
         -o-transition: border-top 0.4s ease;
            transition: border-top 0.4s ease;
}
#pageOrderEdit .ApStickyBar.sticky .saveBar { border-top-color: #33b5e5; }


#pageOrderEdit .saveBar .left {
    display: inline-block;
    width: 49%;
    text-align: left;
    vertical-align: bottom;
}
#pageOrderEdit .saveBar .right {
    display: inline-block;
    width: 50%;
    text-align: right;
    vertical-align: bottom;
}

#pageOrderEdit .fieldEdit .apModalWrapper .apModal .apModalBody {
    overflow: visible;
}

#pageOrderEdit .commentFilesContainer {
    position: relative;
    min-height: 120px;
    margin-bottom: 2em;
}

#pageOrderEdit .commentFilesContainer .commentContainer {
    position: absolute;
    width: 64%;
    top: 0;
    bottom: 0;
}

#pageOrderEdit .commentFilesContainer .commentContainer .apInputBlock  { height: 100%; }
#pageOrderEdit .commentFilesContainer .commentContainer .apInputBlock textarea {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
}

#pageOrderEdit .commentFilesContainer .commentContainer .internalCommentSave:hover { background-color: #42a5f5; }
#pageOrderEdit .commentFilesContainer .commentContainer .internalCommentSave {
    z-index: 2;
    position: absolute;
    bottom: 3px;
    right: 0;
    padding: 4px;
    border: 1px solid #ccc;
    border-bottom: 0;
    background-color: #2196f3;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
}

#pageOrderEdit .commentFilesContainer .filesContainer { margin-left: 65%; }
#pageOrderEdit .commentFilesContainer .filesContainer .apButton { margin: 0; }

#pageOrderEdit .commentFilesContainer .filesContainer td:first-child {
    font-size: 1.1em;
    font-weight: 700;
    vertical-align: middle;
    padding-right: 1em;
}
#pageOrderEdit .vatTable {
    margin-top: 2em;
    background-color: #fafafa;
    width: 100%;
    border: 1px solid rgba(0,0,0,0.1)
}
#pageOrderEdit .vatTable tr {
    border-bottom: 1px solid #ccc
}
#pageOrderEdit .vatTable tr th,
#pageOrderEdit .vatTable tr td {
    height: 40px;
    text-align: right;
    padding-right: 1em;
}

@media all and (max-width: 700px) {
    #pageOrderEdit .commentFilesContainer .commentContainer {
        width: 100%;
    }
}
