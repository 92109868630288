
#WorkhourWeekBrowser {
	position: relative;
	height: 60px;
}

#WorkhourWeekBrowser .arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 100%;
}

#WorkhourWeekBrowser .arrow.next { left: auto; right: 0; }

#WorkhourWeekBrowser .currentWeek {
	padding: 10px 70px;
	margin: 0 auto;
	max-width: 500px;
}


@media all and (max-width: 500px) {

	#WorkhourWeekBrowser {
		height: 50px;
	}

	#WorkhourWeekBrowser .arrow {
		width: 32px;
	}

	#WorkhourWeekBrowser .currentWeek {
		padding: 5px 45px;
	}
}

@media all and (max-width: 375px) {

	#WorkhourWeekBrowser .apInputBlock.headermode .apSelect select { 
		font-size: 14px; 
		padding: 0 5px;
	}
}