.ComponentRelations .a {
    position: relative;
}
.ComponentRelations .b {
    padding-bottom: 0.5em;
}
.ComponentRelations .c {
    color: #AAA;
    position: absolute;
    font-size: 0.8em;
    top: 1.1em;
    padding-left: 8px;
}
