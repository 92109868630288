
#pageUserGroups .tableHeader {
	position: relative;
}

#pageUserGroups .tableHeader .toggleSwitch {
	position: absolute;
	top: 50%;
	right: 0.5em; 
	line-height: 25px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageUserGroups .tableHeader .toggleSwitch .switchLabel {
	padding: 0 50px 0 1em;
	font-size: 14px;
	margin: 0;
	cursor: pointer;
}

#pageUserGroups .tableHeader .toggleSwitch .apSwitchWrapper {
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#pageUserGroups .moduleCell .modules {
	width: 100%;
	white-space: normal;
}

#pageUserGroups .moduleCell .modules .module {
	display: inline-block;
	background: #f9f9f9;
	border: 1px solid #ccc;
	color: #666;
	margin: 0 0.2em 0.2em 0;
	padding: 0 0.2em;
	font-size: 12px;
	line-height: 1.5em;
}


#pageUserGroups #UserGroupModalHeader,
#pageUserGroups #UserGroupModalFooter {
	padding: 1em;
}

#pageUserGroups #UserGroupModalFooter {
	text-align: right;
}

#pageUserGroups #UserGroupModalFooter .apButton {
	margin: 0 0 0 1em;
}
