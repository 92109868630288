#apCalendar .eventTypeFilters {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 10px;
	font-size: 13px;
}

#apCalendar .eventTypeFilters .eventType {
	cursor: pointer;
	display: flex;
	align-items: center;
}
#apCalendar .eventTypeFilters .eventType.disabled {
	text-decoration: line-through;
	color: #666;
}

#apCalendar .eventTypeFilters .apBadge {
	display: inline-block;
	height: 16px;
	width: 16px;
}

#apCalendar .rbc-date-cell .justify-space-between div {
	padding: 5px;
}

#apCalendar .rbc-event {
	flex-flow: column;
}

#apCalendar .eventContainer {
	overflow: hidden;
	text-overflow: ellipsis;
}

#apCalendar .eventDescription {
	font-size: 13px;
	margin-top: 0.5em;
}

#apCalendar .rbc-event-label {
	font-size: 12px;
	line-height: 1.3em
}

#apCalendarModal .switchContainer {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
}

#apCalendarModal .switchContainer table {
	width: max-content;
	text-align: right;
}

#apCalendarModal .apModalFooter {
	overflow: visible; /* Show overflow so dropdown menu is visible */
}

#apCalendarModal .colorsText {
	margin-bottom: 2px;
}

#apCalendarModal .usedColors {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 8px;
}

#apCalendarModal .usedColors .color {
	width: 30px;
	height: 30px;
	border-radius: 5px;
	border: 2px solid rgb(209, 209, 209)
}
#apCalendarModal .usedColors .color:hover {
	cursor: pointer;
	border-color: rgb(168, 168, 168);
}

#apCalendarModal .removeRow {
	display: block;
    margin: 10px auto 0;
    width: 24px;
    height: 24px;
    opacity: 0.8;
}
#apCalendarModal .removeRow:hover {
	opacity: 1;
}