.ApStickyBar.sticky .stickyContent {
	position: fixed;
}

@media all and (max-width: 700px) {
	.ApStickyBar.sticky .stickyContent {
		left: 0 !important;
		width: 100vw !important;
		z-index: 400 !important;
	}
	.noSticky {
		/* position: fixed !important; */
		left: 0 !important;
		width: 100vw !important;
		z-index: 400 !important;
	}

	.noSticky.width100 {
		width: 100% !important;
	}
}