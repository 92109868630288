#pageNotifiations .item {
    position: relative;
    border: 1px solid #eee;
    border-left: 5px solid #eee;
    padding: 1em;
    margin: 0 0 1em;
    box-shadow: 0 5px 10px rgba(0,0,0,0.05);
    -webkit-transition: border-color 0.4s ease;
    transition: border-color 0.4s ease;
}
#pageNotifiations .item.unread {
    border-left-color: #FF8800;
}
#pageNotifiations .item.checked {
    border-left-color: var(--clr-blue-main);
}

#pageNotifiations .item .title {
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    margin-bottom: 4px
}

#pageNotifiations .item .checkItemInput {
    margin-right: 0.5em;
}

#pageNotifiations .item .remove {
    position: absolute;
    top: 1em;
    right: 1.5em;
    cursor: pointer;
}

#pageNotifiations .item .time {
    position: absolute;
    bottom: 1em;
    right: 1.5em;
    cursor: pointer;
    font-size: 0.8em;
}
#pageNotifiations .item .remove svg {
    width: 18px;
    height: 18px;
}

#pageNotifiations .item .description {
    margin: 0.5em;
}
#pageNotifiations .item .linkContainer {
    margin-top: 1em;
}
#pageNotifiations .item .linkContainer .link {
    cursor: pointer;
    color: #337ab7;
    margin-left: 0.5em;
}
